import React, { useState } from 'react'
import ListOfEmailsActivity from '.'
import UserService from '../../../../services/user-service';
import { AppRequesterController } from '../../../../services/appRequester/appRequesterController';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { TokenInterfaceControlPanel } from '../../../../services/requestsInterfacesModel';
import { getToken } from '../../../../store/control_panel';

const ListOfEmailsActivityController = ({
    updateListfunction,
    hasMoreInformation,
    data
}) => {

    const values: TokenInterfaceControlPanel = {
        token: {
            value: useSelector(getToken)
        }
    }

    const [showModal, setShowModal] = useState(false);
    const [emailInfo, setEmailInfo] = useState("");

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleOpenModal = () => {
        setShowModal(true);
    };

    const AppRequesterConst = new AppRequesterController()
    const [aux, setAux] = useState([]);
    const [emailHist, setEmailHist] = useState([])

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);

    const getEmailInfo = async (email, e) => {
        setEmailInfo(e.currentTarget.id.split("*EMAIL*")[1]);
        
        const headers = UserService.getHeaders();
        const controller = new AbortController()
        const signal = controller.signal;
        const params = {email: email}

        await AppRequesterConst.Get(
        'panel/sendgrid/activity', { params, headers: headers, signal},
        (response: Object) => {
            return response;
        },
        (data) => {
            if (data.status === 200 && data) {
                setAux((state) => {
                    let aux = state.concat(data?.data.data);
                    setEmailHist(aux)
                    return state;
                });
                handleOpenModal();
            }
        },
        (error: Object) => {
        }, navigate, dispatch, setIsLoading, { values: values }
        );
    }

    const convertDate = (dataString) => {
        const date = new Date(dataString);

        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();

        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');

        return `${day}/${month}/${year} - ${hours}:${minutes}:${seconds}`;
    }

    const convertStatus = (status) => {
        if(status === "delivered") {
            return "Entregue";
        }
        else if(status === "not_delivered"){
            return "Não entregue";
        }
        else if(status === "processing"){
            return "Processando"
        }
    }

    return (
        <ListOfEmailsActivity
            showModal={showModal}
            handleOpenModal={handleOpenModal}
            handleCloseModal={handleCloseModal}
            updateListfunction={updateListfunction}
            hasMoreInformation={hasMoreInformation}
            data={data}
            isLoading={isLoading}
            convertDate={convertDate}
            convertStatus={convertStatus}
            emailInfo={emailInfo}
            getEmailInfo={getEmailInfo}
            emailHist={emailHist}
        />
    )
}

export default ListOfEmailsActivityController