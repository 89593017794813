import React, { FC } from 'react';
import { Modal } from 'react-bootstrap';
import { IModalCreateTemplateMessage } from './indexModal';
import allStyles from './styles';
import FormOfModalsTemplateMessageController from '../formOfModalsTemplateMessage/indexController';
import { useSelector } from 'react-redux';
import { getShowTemplateTagModal } from '../../../../../../store/internal';
import CreateTagController from '../formOfModalsTemplateMessage/createTagModal/indexController';
import Loading from '../../../../../../components/loading';

const ModalCreateTemplateMessage: FC<IModalCreateTemplateMessage> = (props) => {
    return (
        <>
            {/* <Loading isLoading={props.isLoading} /> */}
            <Modal show={props.showModal} onHide={() => props.hide()} aria-labelledby="" size="lg" centered={true} >
                {useSelector(getShowTemplateTagModal)?
                    <CreateTagController
                        tags={props.tags} 
                        setTags={props.setTags} 
                        hideTagModal={props.hide}
                        createInCompany={props.createInCompany} 
                        examples={props.examples} 
                        setExamples={props.setExamples} 
                    />:
                    <></>
                }
                <>
                    <Modal.Header hidden={useSelector(getShowTemplateTagModal)} closeButton style={{ marginLeft: "6%", marginTop: "1%", marginBottom: "-1%" }}>
                        <Modal.Title style={allStyles.textBlack}>
                            {props.t('home_departments.template_message_tab.create_template')}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body hidden={useSelector(getShowTemplateTagModal)} style={{ marginLeft: "4%", marginRight: "4%", marginBottom: "2%" }}>
                        <FormOfModalsTemplateMessageController onSubmit={props.onSubmit} hide={props.hide} initialValues={{ title: '', message: '', phone_number: '', image_id: "" }} currentCompany={props.currentCompany} tags={props.tags} setTags={props.setTags} examples={props.examples} setExamples={props.setExamples} createInCompany={props.createInCompany}/>
                    </Modal.Body>
                </>
            </Modal>
        </>
    );
}

export default ModalCreateTemplateMessage;