import { useDispatch, useSelector } from "react-redux";
import ModalActiveBase from ".";
import { getShowModalActiveBase, setShowAlertFeedback, setShowModalActiveBase } from "../../../../../store/internal";
import { useTranslation } from "react-i18next";
import { FC, useEffect, useState } from "react";
import { AppRequesterControlPanelController } from "../../../../../services/appRequesterControlPanel/appRequesterControlPanelController";
import { TokenInterfaceControlPanel } from "../../../../../services/requestsInterfacesModel";
import { getToken } from "../../../../../store/control_panel";
import { useNavigate } from "react-router-dom";
import { IModalActiveBaseController } from "./indexModal";

const ModalActiveBaseController: FC<IModalActiveBaseController> = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState();

  const [phone, setPhone] = useState("");
  const [phoneBorder, setPhoneBorder] = useState('');
  const [showFeedbackPhone, setShowFeedbackPhone] = useState("");

  const [files, setFiles] = useState([]);

  const [gfids, setGfids] = useState([]);

  const [phoneList, setPhoneList] = useState([]);

  const AppRequesterConst = new AppRequesterControlPanelController();

  const values: TokenInterfaceControlPanel = {
    token: {
      value: useSelector(getToken)
    }
  }

  useEffect(() => {
    const controller = new AbortController();
  
    if (props.botId) {
      getWhatsappList(controller);
    } else {
      onHide();
    }

    return () => {
      controller.abort();
    }
  }, [props.botId]);

  const onHide = () => {
    dispatch(setShowModalActiveBase(false));
    props.onCloseActiveBase();
    setPhone("+55");
    setPhoneList([]);
  }

  const getWhatsappList = async (controller: AbortController) => {
    const headers = {
      "Content-Type": "application/json; charset=utf-8",
      "Authorization": "Bearer " + values.token.value
    }
    const signal = controller.signal;

    await AppRequesterConst.Get(
        `/panel/active-customer-base?bot_id=${props.botId}`, { headers, signal },
        (response: Object) => { },
        (data: any) => {
          if (data.status === 200 && data?.data?.data?.length > 0) {
            setPhoneList(data.data.data);
          }
        },
        (error: { response: { status: number } }) => {
          if (error.response.status === 404) {
            dispatch(setShowAlertFeedback({ message: "Bot não encontrado", visibility: true, signalIcon: false }));
          } else {
            dispatch(setShowAlertFeedback({ message: 'Ocorreu um erro inesperado', visibility: true, signalIcon: false }))
          }

          onHide();
        }, navigate, dispatch, setIsLoading
    )
  }

  const onSubmit = async () => {
    // se a lista de gfsids for diferente é porque houve um problema no upload
    if (gfids.length === files.length) {
      let JsonSend = {
        bot_id: props.botId,
      }

      if (phoneList?.length > 0) 
        JsonSend['additional_numbers'] = phoneList.map((item) => item.user_id.replaceAll("+", "").replaceAll(" ", ""));

      if (gfids.length > 0)
        JsonSend['gfsId'] = gfids[0];

      const headers = {
        "Content-Type": "application/json; charset=utf-8",
        "Authorization": "Bearer " + values.token.value
      }
      const config = { headers }

      await AppRequesterConst.Post(
          "/panel/send-activity-base", JsonSend, config,
          (response: Object) => { },
          (data: { status: number }) => {
              if (data.status === 200) {
                dispatch(setShowAlertFeedback({ message: "Base atualizada com sucesso", visibility: true, signalIcon: true }));
                onHide();
              }
          },
          (error: { response: { status: number; data: { status: number; code_cxpress: number, message: { context: { label: string } } } } }) => {
            dispatch(setShowAlertFeedback({ message: "Ocorreu um erro inesperado, tente novamente mais tarde.", visibility: true, signalIcon: false }))
          }, navigate, dispatch, setIsLoading
      )
    } else {
      dispatch(setShowAlertFeedback({ message: "Verifique os arquivos", visibility: true, signalIcon: false }))
    }
  }

  const validate = (values) => {
    let errors = {};

    /* setPhoneBorder('1px solid red')
    setShowfeedbackPhone(true) */

    /* setNameAccount(values.nameAccount)
    setPhoneNumber(values.phoneNumber)
    setTokenAuth(values.tokenAuth)
    setSid(values.sid)
    setBotNlp(values.botNlp)
    setApiUrl(values.apiUrl)
    setUrlBot(values.urlBot)
    setPostBackUrl(values.postBackUrl)

    if (!values.nameAccount) {
        errors['nameAccount'] = 'Preencha o nome da conta corretamente'
        setShowFeedbackNameAccount(true)
    } else {
        setShowFeedbackNameAccount(false)
    } */

    return errors
  }

  const addPhone = () => {
    if (!showFeedbackPhone && phone?.length > 0) {
      if (phoneList.find((number) => number.user_id === phone))
        dispatch(setShowAlertFeedback({ message: "Número já inserido", visibility: true, signalIcon: false }))
      else {
        setPhoneList([{ user_id: phone }, ...phoneList]);
        // resetando numero
        setPhone("+55");
      }
    }
  }

  const deleteNumber = (number: string) => {
    setPhoneList((phones) => {
      phones = phones.filter((phone) => phone.user_id !== number);
      return phones;
    });
  }
  
  return <ModalActiveBase
    visibility={useSelector(getShowModalActiveBase)}
    onHide={onHide}
    t={t}
    phone={phone}
    setPhone={setPhone}
    showFeedbackPhone={showFeedbackPhone}
    phoneBorder={phoneBorder}
    onSubmit={onSubmit}
    validate={validate}
    files={files}
    phoneList={phoneList}
    addPhone={addPhone}
    deleteNumber={deleteNumber} 
    setGfids={setGfids}  
    setFiles={setFiles}
  />
}

export default ModalActiveBaseController;