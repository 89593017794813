import React, { useEffect, useState } from 'react'
import ViewBlockedEmails from '.';
import { AppRequesterController } from '../../../services/appRequester/appRequesterController';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { TokenInterfaceControlPanel } from '../../../services/requestsInterfacesModel';
import BackendConstants from '../../../core/constants/backend-constants';
import UserService from '../../../services/user-service';
import { getToken } from '../../../store/control_panel';

const ViewBlockedEmailsController = () => {

    const values: TokenInterfaceControlPanel = {
        token: {
            value: useSelector(getToken)
        }
    }

    const [currentFilterSearch, setCurrentFilterSearch] = useState(null);
    const [currentParams, setCurrentParams] = useState({});
    const [aux, setAux] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentState, setCurrentState] = useState("")

    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const [currentLimit] = useState(BackendConstants.messagesTriggersLimit.typesLimit);
    const [HasMoreInformation, setHasMoreInformation] = useState(false);

    const AppRequesterConst = new AppRequesterController()

    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        const controller = new AbortController()

        setCurrentParams((params) => {
            setCurrentState((state) => {
                getEmails(Object.assign(currentParams/* , { limit: currentLimit, page: currentPage } */), controller);
                return state;
            });
            return params;
        });
    }, [currentPage, currentState])

    const getEmails = async (params/* : { limit: number; page: number } */, controller: AbortController) => {
        
        const headers = UserService.getHeaders();
        const signal = controller.signal;

        await AppRequesterConst.Get(
        'panel/email/unsubscribes', { headers: headers, signal, params: params },
        (response: Object) => { },
        (data) => {
            if (data.status === 200) {
                // setHasMoreInformation(true);
                // setAux((state) => {
                //     let aux = state.concat(data.data.emails)
                //     setAux(aux)
                //     setData(aux)
                //     return state
                // });
                setAux((state) => { 
                    let aux = state.concat(data.data.emails);
                    const uniqueEmails = Array.from(new Set(aux.map(email => email.email))).map(email => aux.find(item => item.email === email));
                    setData(uniqueEmails);
                    return uniqueEmails;
                });
            } else if (aux.length === 0) {
                setData(undefined)
            }
        },
        (error: Object) => {
        }, navigate, dispatch, setIsLoading, { values: values }
        );
    }

    const filteringEmails = (e: { currentTarget: { name: string; }; target: { name: string; }; }, auxObj: Object, action: string) => {
        setCurrentPage(1)
        setAux([])
        if (document.getElementById("ListOfBlockEmails") !== null) {
            document.getElementById("ListOfBlockEmails").scrollTop = 0
        }

        let name = ""
        if (e.currentTarget) {
            name = e.currentTarget.name
        } else if (e.target) {
            name = e.target.name
        }

        if (name === "cleanSearchButtonName" || action === "clean") {
            setCurrentFilterSearch("")
            auxObj = {}
            setCurrentState("searching")
            if (currentState === "searching") {
                setCurrentState("")
            }
        } else if (name === "searchButtonName" || name === "searchButtonName") {
            auxObj = {}

            setCurrentState("searching")
            if (currentState === "searching") {
                setCurrentState("")
            }

            if (currentFilterSearch !== null && currentFilterSearch !== "") {
                auxObj["search"] = currentFilterSearch
            }
        } else {
            setCurrentState("filtering")

            if (currentState === "filtering") {
                setCurrentState("")
            }
        }

        setCurrentParams(auxObj)
    }

    const cleanList = () => {
        setCurrentParams({});
        setCurrentPage(1);
        setAux([]);

        setCurrentState("filtering");

        if (currentState === "filtering") {
            setCurrentState("");
        }
    }

    const updateListfunction = () => {
        setCurrentPage(currentPage + 1);
    };

    const updateListAfterDelete = () => {
        setCurrentParams({});
        setCurrentPage(1);
        setAux([]);

        setCurrentState("cleanSearchButtonName");

        if (currentState === "cleanSearchButtonName") {
            setCurrentState("");
        }
    };

    return (
        <ViewBlockedEmails
            setCurrentFilterSearch={setCurrentFilterSearch}
            currentFilterSearch={currentFilterSearch}
            setCurrentState={setCurrentState}
            currentState={currentState}
            setCurrentPage={setCurrentPage}
            setAux={setAux}
            setCurrentParams={setCurrentParams}
            filteringTickets={filteringEmails}
            cleanList={cleanList}
            currentParams={currentParams}
            currentPage={currentPage}
            aux={aux}
            data={data}
            isLoading={isLoading}
            updateListfunction={updateListfunction}
            hasMoreInformation={HasMoreInformation}
            updateListAfterDelete={updateListAfterDelete}
        />
    )
}

export default ViewBlockedEmailsController;