import React, { useContext, useEffect, useState } from 'react';
import { TicketFilterType } from '../../../../core/enums/ticket-filter-type';
import { AppRequesterController } from '../../../../services/appRequester/appRequesterController';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { TicketStatus } from '../../../../core/enums/ticket-status';
import { Channels, ChannelsMultiselectOptionsTicket } from '../../../../core/enums/channels';
import { EmailContext } from '../../../../core/context/cc-cco-context';
import BackendConstants from '../../../../core/constants/backend-constants';
import { AgentInterface, ConsumerInterface, DataSectorIdInterface, GetAllTicketsInterface, SectorActiveInterface, TicketTotalsInterface, TokenInterface, UserInterface } from '../../../../services/requestsInterfacesModel';
import { getToken } from '../../../../store/token';
import { getIdCompany } from '../../../../store/company';
import { getIdEmployee } from '../../../../store/employee';
import { getIdUser, getNameUser, getProfileId, getUserLastName } from '../../../../store/user';
import { useAppContext } from '../../../../providers';
import UserService from '../../../../services/user-service';
import constsApi from '../../../../services/constsApi';
import { getQuickMessageId, getShowEditConsumersModal, getShowModalConsumersSimilarData, getShowRegisterCustomersModal, setQuickMessageId, setRegisterCustomersModal, setShowAlertFeedback, setShowConfirmationModal, setShowEditConsumersModal, setShowModalConsumersSimilarData } from '../../../../store/internal';
import constsRouters from '../../../../routes/constsRouter';
import { verifyCode } from '../../../../services/codeCxpressInterface';
import Utils from '../../../../core/shared/utils';
import HomeCreateTicket from '.';
import { setConfirmChangePage } from '../../../../store/app_sidebar';
import { TicketContext } from '../../../../core/context/ticket-context';
import { optionSelect } from '../../components/selectComponent/indexModel';
import { BlockTicketContext } from '../../../../core/context/block-ticket-context';

const HomeCreateTicketController = () => {
    const appRequesterController = new AppRequesterController();
    // const socket = useContext(SocketContext);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const abortController = new AbortController();
    const { t } = useTranslation();
    const location = useLocation();
    const ticket = useContext(TicketContext)

    const [isLoading, setIsLoading] = useState(false);

    const [isClosingCreateTicket, setIsClosingCreateTicket] = useState(false);
    const [filledCreatingTicket, setFilledCreatingTicket] = useState(false);
    const [closeCreateTicketButton, setCloseCreateTicketButton] = useState(null);

    const [/*currentState*/, setCurrentState] = useState("");
    const [currentFilterSearch, setCurrentFilterSearch] = useState(null);
    const [currentConsumersData, setCurrentConsumersData] = useState(undefined);

    const [numberOfAllTickets, setNumberOfAllTickets] = useState(null);
    const [numberOfYoursTickets, setNumberOfYoursTickets] = useState(null);
    const [numberOfNewTickets, setNumberOfNewTickets] = useState(null);
    const [numberOfUnreadTickets, setNumberOfUnreadTickets] = useState(null);

    const [date] = useState(new Date(Date.now()));
    const [tags, setTags] = useState([]);
    const [followers, setFollowers] = useState([]);

    const [currentStatus, setCurrentStatus] = useState(TicketStatus.New);
    const [currentChannel, setCurrentChannel] = useState(Channels.Email);//useState(Channels.NotAssigned);
    const [currentRequester_user, setCurrentRequester_user] = useState(null);
    const [currentAssigned_employee_user_id, setCurrentAssigned_employee_user_id] = useState("");
    const [currentFollowers, setCurrentFollowers] = useState([]);
    const [currentSubject, setCurrentSubject] = useState(/* JSON.parse(localStorage.getItem('create_ticket_data')).currentSubject? JSON.parse(localStorage.getItem('create_ticket_data')).currentSubject:  */"");
    // const [currentIsBot, setCurrentIsBot] = useState(false);
    const [currentTags, setCurrentTags] = useState([]);

    // id usado no useEffect sempre que é atualizado o setor selecionado
    const [selectedDepartmentId, setSelectedDepartmentId] = useState("");
    const [departmentsOptions, setDepartmentsOptions] = useState([]);
    const [selectedDepartments, setSelectedDepartments] = useState([]);
    const [userId, setUserId] = useState("")

    const [consumersOptions, setConsumersOptions] = useState([]);
    const [selectedConsumers, setSelectedConsumers] = useState([]);

    const [currentConsumer, setCurrentConsumer] = useState(null);
    const [similarConsumers, setSimilarConsumers] = useState(undefined);

    const [agentsOptions, setAgentsOptions] = useState([]);
    const [selectedAgents, setSelectedAgents] = useState([]);
    const [agentNotItem] = useState({ label: t("home_tickets.home_create_ticket.null_option"), value: "NA", key: "NA", hide: false, sector: { id: "" }, user_id: "" });

    const TicketStatusMultiselectOptions = [
        { label: t('ticket_status.new'), value: TicketStatus.New }, 
        { label: t('ticket_status.open'), value: TicketStatus.Open },
        { label: t('ticket_status.pending'), value: TicketStatus.Pending },
        { label: t('ticket_status.on_hold'), value: TicketStatus.OnHold },
        { label: t('ticket_status.resolved'), value: TicketStatus.Resolved },
    ];

    const [statusOptions] = useState(TicketStatusMultiselectOptions);
    const [selectedStatus, setSelectedStatus] = useState([{ label: t("home_tickets.home_create_ticket.select_options.status_new"), value: TicketStatus.New }]);

    
    const [channelOptions] = useState(ChannelsMultiselectOptionsTicket);
    const [selectedChannels, setSelectedChannels] = useState([{ label: t("home_tickets.home_create_ticket.select_options.email"), value: Channels.Email }]);
    const [currentChannelId, setCurrentChannelId] = useState(null);

    // const [isBotOptions] = useState([{ label: t("home_tickets.home_create_ticket.select_options.is_bot.no"), value: '0' }, { label: t("home_tickets.home_create_ticket.select_options.is_bot.yes"), value: '1' }]);
    // const [selectedBot, setSelectedBot] = useState([{ label: t("home_tickets.home_create_ticket.select_options.is_bot.no"), value: '0' }]);

    const [showSubjectError, setShowSubjectError] = useState(false);

    const [/*goBackToTicketList*/, setGoBackToTicketList] = useState(false);

    // const [messages, setMessages] = useState([]);

    const [ticketIdAfterCreated] = useState(null);

    // const [openModalPhone, setOpenModalPhone] = useState(undefined);
    // const [phoneNumber, setPhoneNumber] = useState("");

    const [selected_ccEmails, setSelected_ccEmails] = useState(null);
    const [selected_ccoEmails, setSelected_ccoEmails] = useState(null);

    const email = useContext(EmailContext)
    const block_ticket = useContext(BlockTicketContext)

    const [customFields, setCustomFields] = useState([])
    const [customFieldIdErrors, setCustomFieldIdErrors] = useState<any>({});
    const entityId = '250204ce-44eb-4cdc-865a-f54eed9c6496'

    const [currentLimit] = useState(BackendConstants.limit);
    const [currentPage, SetCurrentPage] = useState(1);

    const [textareaStates, setTextareaStates] = useState({});
    const [customFieldsIds, setCustomFieldsIds] = useState([])
    const [filesIds, setFilesIds] = useState([])
    const [channelSelected, setChannelSelected] = useState([])

    const values: TokenInterface = {
        token: {
            value: useSelector(getToken)
        },
        company: {
            id: useSelector(getIdCompany)
        },
        employee: {
            id: useSelector(getIdEmployee)
        },
        user: {
            id: useSelector(getIdUser),
            name: useSelector(getNameUser),
            profile_id: useSelector(getProfileId),
            lastname: useSelector(getUserLastName),
        },
        internal: {
            getShowEditConsumersModal: useSelector(getShowEditConsumersModal),
            getShowCreateConsumersModal: useSelector(getShowRegisterCustomersModal),
            getShowModalConsumersSimilarData: useSelector(getShowModalConsumersSimilarData),
        }
    }

    const [showContent, setShowContent] = useState(false);

    const { htmlMessageContent, /* currentMessageChannel, isMessageInternalResponse, attachmentFiles */ templateMessageId } = useAppContext();
    const quickMessageId = useSelector(getQuickMessageId)

    // atualizando o id do setor
    useEffect(() => {
         // atualizando o selectedDepartmentId
         if (selectedDepartments.length > 0) {
            setSelectedDepartmentId(selectedDepartments[0].value);
        } else {
            setSelectedDepartmentId("");
        } 
    }, [selectedDepartments]);

    useEffect(() => {
        const controllerDepartment = new AbortController();
        const controllerAgents = new AbortController();

        setSelectedDepartmentId((departmentId) => {
            if (departmentId) {
                const signalDepartment = controllerDepartment.signal;
                // ocultando agentes que não são do setor
                const headers = UserService.getHeaders();

                appRequesterController.Get(
                    `/sector/${departmentId}`, { headers, params: { companyid: values.company.id }, signal: signalDepartment },
                    (response: Object) => { },
                    (data: DataSectorIdInterface) => {
                        // atualizando setor com mais informações não presentes na rota de listagem de setores
                        if (data?.data?.sectors.length > 0) {
                            setSelectedDepartments([ Object.assign(data.data.sectors[0], { label: data.data.sectors[0].sector_name, value: data.data.sectors[0].sector_id }) ]);
                        }

                        // agentes pertencentes ao setor selecionado
                        if (data?.data?.sectors?.length > 0 && data?.data?.sectors[0]?.agents?.length > 0) { 
                            let agents = [];
                            agents.push(agentNotItem);

                            // agente selecionado vazio para verificar se existe o agente selecionado na listagem do setor
                            let agentSelect = [];

                            data.data.sectors[0].agents.map((agent) => {
                                agent = Object.assign(agent, { label: agent.name, value: agent.user_id });
                                agents.push(agent);

                                if (selectedAgents?.length > 0 && selectedAgents[0].value === agent.user_id) {
                                    agentSelect.push(agent);
                                }
                            });

                            setAgentsOptions(agents);
                            setSelectedAgents(agentSelect);
                            setCurrentAssigned_employee_user_id(agentSelect.length > 0? agentSelect[0].user_id: "");
                        } else { // o setor não tem agentes
                            // Usado para resetar o selectedAgents ao mudar de setor
                            // getAgents(controllerAgents);
                            setAgentsOptions([]);
                            setSelectedAgents([]);
                            setCurrentAssigned_employee_user_id("");

                            // dispatch(setShowAlertFeedback({ message: t("home_tickets.view_ticket.alert_feedback.department_without_agents"), visibility: true, signalIcon: false }));
                        }
                    }, (error: Object) => { }, navigate, dispatch, setIsLoading, { values: values }
                );
            } else { // caso não haja setor selecionado a listagem de agentes deve ser resetada para o default
                getAgents(controllerAgents);
            }

            return departmentId;
        });

        return () => {
            controllerDepartment.abort();
            controllerAgents.abort();
        }
    }, [selectedDepartmentId]);

    useEffect(() => {
        if (values.user.id) {
            setUserId(values.user.id)
        }
    }, [values])

    // Vai ser usado na tela de criação de ticket
    useEffect(() => {
        setChannelSelected(selectedChannels)
    }, [selectedChannels])

    // quando o componente é montado os modais devem estar com visibilidade desabilitada
    useEffect(() => {
        if (values.internal.getShowCreateConsumersModal) {
            dispatch(setRegisterCustomersModal(false));
        }

        if (values.internal.getShowEditConsumersModal) {
            dispatch(setShowEditConsumersModal(false));
        }

        if (values.internal.getShowModalConsumersSimilarData) {
            dispatch(setShowModalConsumersSimilarData(false));
        }
    }, []);

    useEffect(() => {
        ticket.setTicketCreate(true)
    }, [])
    
    useEffect(() => {
        let controller = new AbortController();

        if (localStorage?.getItem('create_ticket_data')?.length > 0 && location?.state?.originRedirectPage === "unify_consumers" &&
            currentStatus === TicketStatus.New && currentChannel === Channels.Email && currentRequester_user === null && currentAssigned_employee_user_id === "" && currentFollowers?.length === 0 && currentSubject === "" && currentTags?.length === 0 && selectedDepartments?.length === 0 && selectedConsumers?.length === 0 && selectedAgents?.length === 0 && (selectedStatus?.length === 1 && selectedStatus[0].value === TicketStatus.New) && (selectedChannels?.length === 1 && selectedChannels[0].value === Channels.Email) && showSubjectError === false && selected_ccEmails === null && selected_ccoEmails === null) {
            getInformations(controller).finally(() => {
                let ticketData = JSON.parse(localStorage.getItem('create_ticket_data'));
                if (ticketData) {
                    setCurrentStatus(ticketData.currentStatus);
                    setCurrentChannel(ticketData.currentChannel);
                    setCurrentAssigned_employee_user_id(ticketData.currentAssigned_employee_user_id);
                    setCurrentFollowers(ticketData.currentFollowers);
                    setCurrentSubject(ticketData.currentSubject);
                    // setCurrentIsBot(ticketData.currentIsBot);
                    setCurrentTags(ticketData.currentTags);
                    setSelectedDepartments(ticketData.selectedDepartments);

                    onSelectRequester([ticketData.currentRequester_user], true);
                    setSelectedAgents(ticketData.selectedAgents);
                    setSelectedStatus(ticketData.selectedStatus);
                    setSelectedChannels(ticketData.selectedChannels);
                    // setSelectedBot(ticketData.selectedBot);
                    setShowSubjectError(ticketData.showSubjectError);
                    setSelected_ccEmails(ticketData.selected_ccEmails);
                    setSelected_ccoEmails(ticketData.selected_ccoEmails);
                }
                localStorage.setItem('create_ticket_data', null);
            });
        } else {
            let ticketData = {
                currentStatus: currentStatus,
                currentChannel: currentChannel,
                currentRequester_user: currentRequester_user,
                currentAssigned_employee_user_id: currentAssigned_employee_user_id,
                currentFollowers: currentFollowers,
                currentSubject: currentSubject,
                // currentIsBot: currentIsBot,
                currentTags: currentTags,
                selectedDepartments: selectedDepartments,
                selectedConsumers: selectedConsumers,
                selectedAgents: selectedAgents,
                selectedStatus: selectedStatus,
                selectedChannels: selectedChannels,
                // selectedBot: selectedBot,
                showSubjectError: showSubjectError,
                selected_ccEmails: selected_ccEmails,
                selected_ccoEmails: selected_ccoEmails
            }
            localStorage.setItem('create_ticket_data', JSON.stringify(ticketData));
        }

        return () => {
            controller.abort();
        }
    }, [currentStatus, currentChannel, currentRequester_user, currentAssigned_employee_user_id, currentFollowers, currentSubject, currentTags, selectedDepartments, selectedConsumers, selectedAgents, selectedStatus, selectedChannels, showSubjectError, selected_ccEmails, selected_ccoEmails]);

    /** from create ticket */
    useEffect(() => {
        const controller = new AbortController();

        setIsLoading(true);
        setCurrentState((state) => {
            getInformations(controller).then(() => {
                setIsLoading(false);
            });
            return state;
        });
        /* getInformations().then(() => {
            setIsLoading(false);
        }); */

        // atualizado com a alteração de adriano
        // send message after create ticket
        // if (ticketIdAfterCreated && htmlMessageContent) {
        //     let newMessage = createMessageBody(htmlMessageContent, ticketIdAfterCreated);
        //     createSocketInstance(ticketIdAfterCreated, newMessage as CreateMessageRequest);
        // }

        return () => {
            controller.abort();
        }
    }, [/* props.visibility, */ ticketIdAfterCreated]);

    useEffect(() => {
        const beforeUnloadListener = (event: { preventDefault: () => void; returnValue: string; }) => {
            event.preventDefault();
            return event.returnValue = t("home_tickets.want_exit");//"Are you sure you want to exit?";
        };

        function checkChange() {
            if (filledCreatingTicket === true) {
                window.addEventListener("beforeunload", beforeUnloadListener, { capture: true });
            } else {
                window.removeEventListener("beforeunload", beforeUnloadListener, { capture: true });
            }
        }

        checkChange();

    }, [filledCreatingTicket]);

    // handle close in create ticket
    useEffect(() => {
        if (isClosingCreateTicket) {
            if (closeCreateTicketButton === "filterAllTickets" || closeCreateTicketButton === "buttonCloseWithoutSaving") {
                filtersClick(TicketFilterType.All);
            } else if (closeCreateTicketButton === "filterYourTickets") {
                filtersClick(TicketFilterType.Your);
            } else if (closeCreateTicketButton === "filterNewTickets") {
                filtersClick(TicketFilterType.New);
            } else if (closeCreateTicketButton === "filterUnreadTickets") {
                filtersClick(TicketFilterType.Unread);
            } else {
                filtersClick(TicketFilterType.All);
            }
        }
    }, [isClosingCreateTicket, closeCreateTicketButton]);

    useEffect(() => {
        setCurrentState((state) => {
            updateCurrentNumberOfTickets();
            return state;
        });
    }, []);

    useEffect(() => {
        const controller = new AbortController()
        getAllCustomFields({ limit: currentLimit, page: currentPage }, controller)
    }, [])

    useEffect(() => {
        if (customFields)
            initializeTextareaStates(customFields);
    }, [customFields])

    /**
     * Chama a função de verificação do canal durante a criação do 
     * ticket
     */
    useEffect(() => {
        showEditComponent()
        blockMessageForWhatsapp()
    }, [currentChannel])

    /**
     * Vai bloquear que seja possível enviar mensagem para o 
     * canal Whatsapp
     */
    const blockMessageForWhatsapp = () => {
        if (currentChannel === Channels.WhatsApp) {
            block_ticket.setIsAllowedMessage(false)
            block_ticket.setReadOnlyEditorComponent(true)
        } else {
            block_ticket.setIsAllowedMessage(true)
            block_ticket.setReadOnlyEditorComponent(false)
        }
    }

    /**
     * Vai exibir o componente de chat no caso da criação de 
     * tickets
     */
    const showEditComponent = () => {
        block_ticket.setEditTicket(true);
    }



    // const createMessageBody = (messageContent: string, ticketId: string): CreateMessageRequest => {
    //     return {
    //         ticket_id: ticketId,
    //         user_id: values.user.id,
    //         channel_id: currentMessageChannel,
    //         content: messageContent,
    //         is_internal_response: isMessageInternalResponse,
    //         is_agent: true,
    //         // vazio para teste
    //         temp_bucket_ids: undefined, //tempAttachmentIds.length > 0 ? tempAttachmentIds : undefined,
    //     };
    // }


    // const onConnect = () => {
    //     setIsLoading(true);
    // }
    // const createSocketInstance = (ticket_id: string, message: CreateMessageRequest) => {
    //     /*const onReceiveMessage = (sentMessage: Message) => {
    //         setPendingMessage(sentMessage);
    //     }*/

    //     const onListMessages = (newMessages: Message[]) => {
    //         let auxArray = [];
    //         let auxDate = "";

    //         for (let i = 0; i < newMessages.length; i++) {
    //             const element = newMessages[i];

    //             if (auxDate !== element.createdAt.split("T")[0]) {

    //                 auxDate = element.createdAt.split("T")[0];
    //                 const date = new Date(auxDate);

    //                 const day = date.getDate() + 1;
    //                 const month = date.getMonth() + 1;
    //                 const year = date.getFullYear();
    //                 auxArray.push({ date: `${day}/${month}/${year}` });
    //             }

    //             auxArray.push(element);
    //         }

    //         setMessages(auxArray);

    //         //let hasMoreMessages = newMessages.length == BackendConstants.limit;
    //         //setRequestMoreMessages(hasMoreMessages);

    //         //scrollToBottom();
    //         setIsLoading(false);
    //     }

    //     const messageSocket = new MessageSocketClient(
    //         socket,
    //         ticket_id,
    //         messages,
    //         () => { },//onReceiveMessage,
    //         onListMessages,
    //         () => { },//onGetOlderMessages,
    //         () => { },//onError,
    //         onConnect,
    //     );

    //     messageSocket.createMessage(message, attachmentFiles);
    //     messageSocket.leaveRoom(ticket_id);
    //     //setMessageSocketCreateTicket(messageSocket);
    // }

    const createTicketChanged = (value: boolean) => {
        setFilledCreatingTicket(value);
        dispatch(setConfirmChangePage(value));
    }

    const multiselectFilterUsers = async (options: optionSelect[], filter: Object) => {
        const headers = UserService.getHeaders()

        const params = {
            "search": filter,
        }

        if (!filter) {
            return options;
        } else {
            let users = [];
            await appRequesterController.Get(
                '/consumer', { headers, params: params },
                (response: Object) => { },
                (data: UserInterface) => {
                    if (data.status === 200 && data?.data?.consumers) {
                        data.data.consumers.map((item) => {
                            users.push({ ...item, label: item.name, value: item.user_id });
                        });
                    }
                },
                (error: Object) => { }, navigate, dispatch, () => { }, { values: values }
            );

            return users;
        }
    }

    const multiselectFilterAgents = async (options: optionSelect[], filter: Object) => {
        const headers = UserService.getHeaders()

        const params = {
            "search": filter,
        }

        if (!filter) {
            return options;
        } else {
            let agents = [];// [agentNotItem];
            await appRequesterController.Get(
                '/agent', { headers, params },
                (response: Object) => { },
                (data: UserInterface) => {
                    // user ids dos agentes do setor selecionado
                    let agentsIdsInDepartment = selectedDepartments.length > 0 && selectedDepartments[0].agents.length > 0? selectedDepartments[0].agents.map((agent) => { return agent.user_id }): [];

                    if ((((data.status === 200) && (values.user.profile_id === constsApi.profile[0].id)) || (values.user.profile_id === constsApi.profile[2].id) || (values.user.profile_id === constsApi.profile[3].id))) {
                        if (data?.data?.employees) {
                            data.data.employees.map((item) => {
                                if (agentsIdsInDepartment.includes(item.user_id) || selectedDepartments.length === 0)
                                    agents.push({ ...item, label: item.name, value: item.user_id });
                            });
                        }
                    } else {
                        if (data?.data?.employees) {
                            data.data.employees.filter(elem => elem.user_id === values.user.id).map((item) => {
                                if (agentsIdsInDepartment.includes(item.user_id) || selectedDepartments.length === 0)
                                    agents.push({ ...item, label: item.name, value: item.user_id });
                            });
                        }
                    }
                },
                (error: Object) => { }, navigate, dispatch, () => { }, { values: values }
            );

            return agents;
        }
    }

    const multiselectFilterDepartments = async (options: optionSelect[], filter: Object) => {
        const headers = UserService.getHeaders()

        const params = {
            "search": filter,
            "companyid": values.company.id
        }

        if (!filter) {
            return options;
        } else {
            let departments = [];
            await appRequesterController.Get(
                '/sector/actives/', { headers, params: params },
                (response: Object) => { },
                (data: SectorActiveInterface) => {
                    if (data.status === 200 && data?.data?.sectors) {
                        data.data.sectors.map((item) => {
                            departments.push({ ...item, label: item.name, value: item.id });
                        });
                    }
                }, (error: Object) => { }, navigate, dispatch, () => { }, { values: values }
            );
            return departments;
        }
    }

    const getAgents = async (controller: AbortController) => {
        const signal = controller.signal;
        const headers = UserService.getHeaders()

        await appRequesterController.Get(
            '/agent', { headers, signal },
            (response: Object) => { },
            (data: UserInterface) => {
                let agentsIdsInDepartment = selectedDepartments.length > 0 && selectedDepartments[0].agents.length > 0? selectedDepartments[0].agents.map((agent) => { return agent.user_id }): [];

                if (((data.status === 200 && (values.user.profile_id === constsApi.profile[0].id)) || (values.user.profile_id === constsApi.profile[2].id) || (values.user.profile_id === constsApi.profile[3].id))) {
                    if (data?.data?.employees) {
                        let users = [];// [agentNotItem];
                        data.data.employees.map((item) => {
                            if (agentsIdsInDepartment.includes(item.user_id) || selectedDepartments.length === 0) {
                                users.push({ ...item, label: item.name, value: item.user_id });
                            }
                        });
                        // setAgentsOptions(users);
                        setAgentsOptions(users);
                    }
                } else {
                    if (data?.data?.employees) {
                        let users = [];// [agentNotItem];
                        data.data.employees.filter(elem => elem.user_id === values.user.id).map((item) => {
                            if (agentsIdsInDepartment.includes(item.user_id) || selectedDepartments.length === 0) {
                                users.push({ ...item, label: item.name, value: item.user_id });
                            }
                        });
                        // setAgentsOptions(users);
                        setAgentsOptions(users);
                    }
                }

                if (data?.data?.employees) {
                    let auxObj2 = [];
                    data.data.employees.map((element) => {
                        auxObj2.push({ name: element.name + (element.lastname ? (" " + element.lastname) : ""), id: element.user_id });
                    });
                    setFollowers(auxObj2);
                }
            },
            (error: Object) => { }, navigate, dispatch, setIsLoading, { values: values }
        );
    }

    const getInformations = async (controller: AbortController) => {
        const headers = UserService.getHeaders(), signal = controller?.signal;

        await appRequesterController.Get(
            '/sector/actives/', { headers, signal, params: { companyid: values.company.id } },
            (response: Object) => { },
            (data: SectorActiveInterface) => {
                if (data.status === 200 && data?.data?.sectors) {
                    let departments = [];
                    data.data.sectors.map((item) => {
                        departments.push({ ...item, label: item.name, value: item.id });
                    });
                    setDepartmentsOptions(departments);
                }
            }, (error: Object) => { }, navigate, dispatch, setIsLoading, { values: values }
        );

        getAgents(controller);

        await appRequesterController.Get(
            '/consumer', { headers, signal },
            (response: Object) => { },
            (data: UserInterface) => {
                if (data.status === 200 && data?.data?.consumers) {
                    let users = [];
                    data.data.consumers.forEach((item) => {
                        // users.push({ ...item, label: item.name, value: item.user_id });
                        users.push({ ...item, label: item.name, value: item.user_id, key: item.user_id });
                    });
                    // setAgentsOptions((agents) => {
                    //     setConsumersOptions([...users, ...agents]);
                    //     return agents;
                    // });
                    setConsumersOptions(users);
                }
            },
            // '/consumer', { headers, signal },
            // (response: Object) => { },
            // (data: ConsumerInterface) => {
            //     if (data.status === 200 && data.data.consumers) {
            //         let users = [];
            //         data.data.consumers.forEach((item) => {
            //             users.push({ ...item, label: item.name, value: item.user_id, key: item.user_id });
            //         });
            //         setAgentsOptions((agents) => {
            //             setConsumersOptions([...users, ...agents]);
            //             return agents;
            //         });
            //     }
            // },
            (error: Object) => { }, navigate, dispatch, setIsLoading, { values: values }
        );

        await appRequesterController.Get(
            '/tag?active=true&limit=1000', { headers, signal },
            (response: Object) => { },
            (data: { data: { tags: { name: string, id: string }[] }, status: number }) => {
                let auxObj = [];
                if (data.status === 200 && data?.data?.tags) {
                    data.data.tags.map((element) => {
                        auxObj.push({ name: element.name, id: element.id });
                    });
                }

                setTags(auxObj);
            },
            (error: Object) => { }, navigate, dispatch, setIsLoading, { values: values }
        );
    }

    const showEditConsumerButton = async (requesterId) => {
        setIsLoading(true);
        // const headers = {
        //     "Content-Type": "application/json; charset=utf-8",
        //     "Authorization": "Bearer " + values.token.value
        // };

        const headers = UserService.getHeaders()

        await appRequesterController.Get(
            '/consumer/' + requesterId, { headers },
            (response: Object) => { },
            (data: ConsumerInterface) => {
                if (data.status === 200 && data?.data?.consumers) {
                    setCurrentConsumersData(data.data.consumers[0]);
                } else {
                    setCurrentConsumersData(null);
                }
                dispatch(setShowEditConsumersModal(true));
            },
            (error: Object) => { }, navigate, dispatch, setIsLoading, { values: values }
        );
    }

    const getConsumerByID = async (requesterId) => {
        setIsLoading(true);

        const headers = UserService.getHeaders()

        await appRequesterController.Get(
            '/consumer/' + requesterId, { headers },
            (response: Object) => { },
            (data: UserInterface) => {
                if (data?.status === 200 && data?.data?.consumers) {

                    let users = [];
                    data.data.consumers.forEach((item) => {
                        users.push({ ...item, label: item.name, value: item.user_id, key: item.user_id });
                    });
                    selectRequester([users[0]])

                    setConsumersOptions((consumers) => {
                        if (!consumers.find((item) => item.user_id === users[0].user_id)) {
                            consumers.splice(0, 0, users[0]);
                        } else {
                        }

                        return consumers;
                    });
                }
            },
            (error: Object) => { }, navigate, dispatch, setIsLoading, { values: values }
        );
    }

    const postTickets = async () => {

        const emailCC = email.getEmailCC()
        const emailCCO = email.getEmailCCO()

        // verificando se existe algum valor com true no object de errors
        const indexHasError = Object.values(customFieldIdErrors).indexOf(true);

        if (!currentSubject) {
            dispatch(setShowAlertFeedback({ visibility: true, message: t("home_tickets.home_create_ticket.alert_feedback.ticket_without_subject"), signalIcon: false }));
        } else if (indexHasError > -1) {
            dispatch(setShowAlertFeedback({ visibility: true, message: t("home_tickets.custom_fields_for_forms.save_ticket_without_required_field"), signalIcon: false }));
        } else {
            setShowSubjectError(true);
            const JsonSend = {
                "subject": currentSubject,
                // "is_bot": currentIsBot,
                "cc": emailCC ? emailCC : [],
                "cco": emailCCO ? emailCCO : []
            }

            if (currentRequester_user !== '' && currentRequester_user !== undefined && currentRequester_user !== null && currentRequester_user.user_id === undefined && currentRequester_user.email) {
                JsonSend['consumer_name'] = currentRequester_user.name;
                JsonSend['consumer_email'] = currentRequester_user.email;
            } else if (currentRequester_user !== '' && currentRequester_user !== undefined && currentRequester_user !== null) {
                JsonSend['requester_user_id'] = currentRequester_user.user_id;
            } else {
                JsonSend['requester_user_id'] = values.user.id;
            }

            if (currentAssigned_employee_user_id !== 'NA' && currentAssigned_employee_user_id !== '' && currentAssigned_employee_user_id !== undefined && currentAssigned_employee_user_id !== null) {
                JsonSend['assigned_employee_user_id'] = currentAssigned_employee_user_id;
            }

            if (currentFollowers.length !== 0) {
                if (currentFollowers[0] !== null) {
                    let auxArray = [];

                    currentFollowers.map((element) => {
                        auxArray.push(element.id);
                    });

                    JsonSend['followers_id'] = auxArray;
                }
            }

            if (currentChannel && currentChannel !== Channels.NotAssigned) {
                JsonSend['channel_id'] = currentChannel;
            }

            if (currentStatus) {
                JsonSend['status_id'] = currentStatus;
            }


            if (currentTags.length !== 0) {
                if (currentTags[0] !== undefined) {
                    let auxArray = [];
                    currentTags.map((elem) => {
                        auxArray.push(elem.id);
                    })
                    JsonSend['tags'] = auxArray;
                }
            }

            if (selectedDepartments.length > 0) {
                JsonSend['sector_id'] = selectedDepartments[0].value;
            }

            if (htmlMessageContent) {
                JsonSend['initial_message'] = htmlMessageContent;
            }

            if (templateMessageId) {
                JsonSend['template_message_id'] = templateMessageId;
            }

            if (customFieldsIds.length !== 0) {
                JsonSend["custom_fields"] = []
                customFieldsIds.map((customFieldId) => { /* passando o nome do campo */
                    JsonSend["custom_fields"].push({ custom_field_id: customFieldId.id, value: [textareaStates[customFieldId.id]], field_name: customFieldId.name })
                })
            }

            if (filesIds?.length > 0) {
                JsonSend["attachment_ids"] = filesIds
            }

            if (quickMessageId !== "") {
                JsonSend["quick_message_id"] = quickMessageId
            }

            const headers = UserService.getHeaders()

            await appRequesterController.Post(
                '/ticket', JsonSend, { headers },
                (response: Object) => { },
                (data: GetAllTicketsInterface) => {
                    // atualizado com a alteração de adriano
                    // setTicketIdAfterCreated(data.data.ticket_id);
                    dispatch(setShowAlertFeedback({ message: t("home_tickets.home_create_ticket.alert_feedback.success_create_ticket", { ticket_reference_id: data?.data?.ticket_reference_id })/* `Ticket #${data.data.ticket_reference_id} criado com sucesso!` */, signalIcon: true, visibility: true }));
                    setGoBackToTicketList(true);
                    createTicketChanged(false);
                    setSelected_ccEmails([])
                    setSelected_ccoEmails([])
                    setTextareaStates({})
                    setCustomFieldsIds([])
                    dispatch(setQuickMessageId(""))
                    // email.setEmailCC([])
                    // email.setEmailCCO([])
                    navigate(constsRouters.routers.tickets.path);
                },
                (error: Object) => {
                }, navigate, dispatch, setIsLoading, () => { },
                {
                    error400: (error: { response: { data: { Message: string; code_cxpress: number }; }; }) => {
                        dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }));
                        setShowSubjectError(false);
                    }
                }
            );
        }
    }


    const onSelectDepartment = () => {
        createTicketChanged(true);
    }

    const onSelectRequester = async (value: /* string |  */any[], editOrCreate?: boolean) => {
        if (value.length > 0) {
            // requisição para pegar o consumidor solicitante
            setIsLoading(true);
            // const headers = {
            //     "Content-Type": "application/json; charset=utf-8",
            //     "Authorization": "Bearer " + values.token.value
            // };

            const headers = UserService.getHeaders()

            await appRequesterController.Get(
                '/user/' + value[value.length - 1]?.user_id, { headers },
                (response: Object) => { },
                (data: UserInterface) => {
                    if (data.status === 200 && data?.data?.users) {
                        let user = Object.assign(value[value.length - 1], { channels: data.data.users[0]?.channels?.length > 0? data.data.users[0].channels: [] });
                        setCurrentRequester_user(user);
                        if (editOrCreate)
                            setSelectedConsumers([user]);
                    } else {
                        setCurrentRequester_user(null);
                        if (editOrCreate)
                            setSelectedConsumers([]);
                    }
                },
                (error: Object) => { }, navigate, dispatch, setIsLoading, { values: values }
            );

            // setCurrentRequester_user(value[value.length - 1]);
            // if (editOrCreate)
            //     setSelectedConsumers([value[value.length - 1]]);
            //addItemNotInOptions(value[value.length - 1], consumersOptions, setConsumersOptions);

            if (value[value.length - 1].profile_id === null || value[value.length - 1].profile_id === undefined) {
                Utils.changeStyleElementById('editConsumerButtonId', 'display', 'inline');
            } else {
                Utils.changeStyleElementById('editConsumerButtonId', 'display', 'none');
            }
        } else {
            setCurrentRequester_user(null);
            // setSelectedConsumersTest(value);
        }

        createTicketChanged(true);
    }

    const onSelectStatus = (value: string | any[]) => {
        if (value.length > 0) {
            // setSelectedStatus([value[value.length - 1]]);
            setCurrentStatus(value[value.length - 1].value);
        } else {
            // setSelectedStatus([]);
            setCurrentStatus(TicketStatus.New);
        }

        createTicketChanged(true);
    }

    const onSelectChannel = (value: string | any[]) => {
        if (value.length > 0) {
            // setSelectedChannels([value[value.length - 1]]);
            setCurrentChannel(value[value.length - 1].value);
        } else {
            // setSelectedChannels([]);
            // setCurrentChannel(Channels.NotAssigned);
            setCurrentChannel(Channels.Email);
        }

        createTicketChanged(true);
    }

    // const onSelectBot = (value: string | any[]) => {
    //     if (value.length > 0) {
    //         // setSelectedBot([value[value.length - 1]]);
    //         setCurrentIsBot(value[value.length - 1] === '0' ? false : true);
    //     } else {
    //         // setSelectedBot([]);
    //         setCurrentIsBot(false);
    //     }

    //     createTicketChanged(true);
    // }

    const updateCurrentNumberOfTickets = async () => {
        // const headers = {
        //     "Content-Type": "application/json; charset=utf-8",
        //     "Authorization": "Bearer " + values.token.value
        // };

        const headers = UserService.getHeaders()

        await appRequesterController.Get(
            '/ticket/totals', { headers },
            (response: Object) => { },
            (data: TicketTotalsInterface) => {
                if (data.status === 200 && data?.data) {
                    setValuesOfNumbersTickets(data.data.total, data.data.total_my_tickets, data.data.total_new_tickets, data.data.total_not_read_tickets);
                }
            },
            (error: Object) => { }, navigate, dispatch, setIsLoading, { values: values }
        );

        // dispatch(setUpdateButtonNumberOfTickets(false));
    }

    const selectFollowers = (value) => {
        setCurrentFollowers(value);
    }

    const selectTags = (value) => {
        setCurrentTags(value);
    }

    const onSearchFollowers = (value: string) => {
        const headers = UserService.getHeaders();
        const signal = abortController.signal;

        let params = { search: value };

        if (value.length === 0)
            params.search = null;

        const getUsers = appRequesterController.Get(
            '/agent', { headers, params: params, signal },
            (response: Object) => { },
            (data: AgentInterface) => {
                let searchedFollowers = [];
                if (data.status === 200 && data?.data?.employees) {
                    data.data.employees.map((item) => {
                        searchedFollowers.push({ name: item.name + (item.lastname ? (" " + item.lastname) : ""), id: item.id, key: item.email });
                    });
                }

                setFollowers(searchedFollowers);
            },
            (error) => { }, navigate, dispatch, setIsLoading, { values: values }
        );
    }

    const getAllCustomFields = async (params: { limit: number; page: number }, controller: AbortController) => {
        const headers = UserService.getHeaders()
        const signal = controller.signal

        await appRequesterController.Get(
            `/custom-fields`, { headers: headers, signal, params: params },
            (response: Object) => { },
            (data: any) => {
                if (data?.status === 200 && data) {
                    let array = [];
                    let errors = {};
                    for (let index = 0; index < data?.data?.custom_fields?.length; index++) {
                        if (data?.data?.custom_fields[index]?.entity_type_id === entityId) {
                            array.push({ name: data.data.custom_fields[index]?.name, dataType: data.data.custom_fields[index]?.custom_field_datatype_id, min: data.data.custom_fields[index].filed_size_min, max: data.data.custom_fields[index].filed_size_max, type: data.data.custom_fields[index]?.custom_field_type_id, id: data.data.custom_fields[index]?.id, placeholder: data.data.custom_fields[index]?.placeholder, required: data.data.custom_fields[index]?.required });
                            errors[data?.data?.custom_fields[index].id] = false;
                        }
                    }
                    setCustomFields(array)
                    // array com os booleanos para erro
                    setCustomFieldIdErrors(errors);
                }
            },
            (error: { response: { status: number } }) => {
                if (error?.response?.status === 400) {

                }
                if (error?.response?.status === 401) {

                }
            }, navigate, dispatch, setIsLoading, { values: values }
        )
    }

    const toggleContent = () => {
        setShowContent(prevState => !prevState);
        /* const controller = new AbortController()
        getAllCustomFields({ limit: currentLimit, page: currentPage }, controller) */
        // initializeTextareaStates(customFields);
    };

    const initializeTextareaStates = (customFields) => {
        const initialState = {};
        customFields.forEach((customField) => {
            if (customField.type === "2982a738-4de3-440a-8ed1-cc1fdb51bc9b" || customField.type === "d31ebd9e-af9f-4559-b04a-729439218a6f") {
                initialState[customField.id] = "";

                // verificando se os campos obrigatórios estão preenchidos inicialmente
                setCustomFieldIdErrors((errors) => {
                    errors[customField.id] = customField.required;
                    return errors;
                });
            }
        });
        setTextareaStates(initialState);
    };

    const changeCustomField = (e, customField) => {
        if (customField.type === "2982a738-4de3-440a-8ed1-cc1fdb51bc9b" || customField.type === "d31ebd9e-af9f-4559-b04a-729439218a6f") {
            setTextareaStates((prevState) => ({
                ...prevState,
                [customField.id]: e.target.value,
            }));
            if (!customFieldsIds.some(field => field.id === customField.id)) {
                setCustomFieldsIds((prevCustomFieldId) => [...prevCustomFieldId, { id: customField.id, name: customField.name }]);
            }
        }

        // validação de erros para campos customizados
        setCustomFieldIdErrors((errors) => {
            if (e.target.value?.length > 0)
                errors[customField.id] = false;
            else if (customField.required)
                errors[customField.id] = true;
            else 
                errors[customField.id] = false;
            return errors;
        });
    }
    
    const filteringTicketsToTicketsPage = () => {
        navigate(constsRouters.routers.tickets.path, { state: { search: currentFilterSearch } });
    }

    const callbackModalCustomers = (userIdCustomer: string) => {
        let controller = new AbortController();

        setIsLoading(true);
        getInformations(controller).then(() => {
            //getconsumerById pois a listagem dos user é limitada a 100 por default
            getConsumerByID(userIdCustomer).then(() => {
                setIsLoading(false);
                setConsumersOptions((state) => {
                    state.map((element) => {
                        if (element.value === userIdCustomer) {
                            selectRequester([element]);
                        }
                    });

                    return state;
                });
            });
        });

        createTicketChanged(true);
    }

    const onClickButtonEdit = () => {
        showEditConsumerButton(currentRequester_user.user_id);
    }

    const selectRequester = (value: string | any[]) => {
        if (value.length > 0) {
            setSelectedConsumers([value[value.length - 1]]);
            setCurrentRequester_user(value[value.length - 1]);
            
            /* if (document.getElementById("editConsumerButtonId")?.style) {
                if (value[value.length - 1].profile_id === null || value[value.length - 1].profile_id === undefined) {
                    document.getElementById("editConsumerButtonId").style.display = "inline";
                } else {
                    document.getElementById("editConsumerButtonId").style.display = "none";
                }
            } */
        } else {
            setSelectedConsumers([]);
            setCurrentRequester_user(null);
        }

        createTicketChanged(true);
    }

     // select create option click
    const customCreateRequesterButtonClick = () => {
        dispatch(setRegisterCustomersModal(true));
        createTicketChanged(true);
    }

    const onSelectAssigned = (value: string | any[]) => {
        if (value.length > 0) {
            if (value[value.length - 1].value === "NA") {
                setCurrentAssigned_employee_user_id("");
            } else {
                setCurrentAssigned_employee_user_id(value[value.length - 1].value);
            }
        } else {
            setCurrentAssigned_employee_user_id("");
        }

        createTicketChanged(true);
    }

    /* const onClickButtonAccept = (values) => {
        setAgentsOptions((state) => {
            state.map((element) => {
                if (element.value === values.user.id) {

                    // setSelectedAgents([element]);
                    setSelectedAgents([Object.assign(element, { force_select: true })]);
                    setCurrentAssigned_employee_user_id(element.value);
                }
            });
            return state;
        });

        createTicketChanged(true);
    } */

    const onClickButtonAccept = () => {
        // procurando agente na lista de agentes do setor
        let agentInDepartment = selectedDepartments?.length > 0 && selectedDepartments[0]?.agents?.length > 0? selectedDepartments[0].agents.find((agent) => agent.user_id === userId): null;

        // se o agente aceito não estiver no setor, então o setor é removido
        if (!agentInDepartment) {
            setSelectedDepartments([]);
        }

        setSelectedDepartments((dep) => {
            setAgentsOptions((agents) => {
                let findedAgent = agents.find((agent) => agent.value === userId);

                if (findedAgent) {
                    setSelectedAgents([Object.assign(findedAgent, { force_select: true })]);
                    setCurrentAssigned_employee_user_id(findedAgent.value);
                } else { // listagem de agentes estava sem o agente usuário
                    let requestedAgent = null;

                    const headers = UserService.getHeaders()
    
                    const agent = appRequesterController.Get(
                        '/agent/' + values.user.id, { headers },
                        (response: Object) => { },
                        (data: AgentInterface) => {
                            if (data.status === 200) {
                                requestedAgent = data.data.employees[0];
                                if (requestedAgent) {
                                    setSelectedAgents([Object.assign(requestedAgent, { force_select: true, value: requestedAgent.user_id, label: requestedAgent.name + (requestedAgent.lastname ? (" " + requestedAgent.lastname) : "") })]);
                                    setCurrentAssigned_employee_user_id(requestedAgent.user_id);
                                }
                            }
                        },
                        (error: Object) => { }, navigate, dispatch, setIsLoading, { values }
                    )
                }
    
                return agents;
            }); 

            return dep;
        });

        setFilledCreatingTicket(true);
    }

    const onClickButtonFollow = (values) => {
        followers.map((element) => {
            if (element.id === values.user.id) {
                let aux = currentFollowers.concat([element]);
                if (currentFollowers.includes(element) === false) {
                    setCurrentFollowers(aux);
                }
            }
        });
        createTicketChanged(true);
    }

    const limiterChangeTextArea = (value: string) => {
        let limit = 200;
        if (value.length > limit) {
            document.getElementById("textAreaAssunto").innerHTML = value.substring(0, limit - 1);
            setCurrentSubject(value.substring(0, limit - 1));
            dispatch(setShowAlertFeedback({ visibility: true, message: t("home_tickets.alert_feedback.limit_chars.body"), signalIcon: false }));
        } else {
            setCurrentSubject(value);
        }
        createTicketChanged(true);
    }

    const formatedDate = (date) => {
        const day = Utils.addLeftZeros(date.getDate());
        const month = Utils.addLeftZeros(date.getMonth() + 1);
        const year = date.getFullYear();

        return day + "/" + month + "/" + year;
    }

    const filtersClick = (filter: TicketFilterType) => {
        localStorage.setItem("viewFilterState", filter);
        navigate(constsRouters.routers.tickets.path);
    }

    const resetPreviewClickedButton = () => {
        setCloseCreateTicketButton("");
    }

    const closeSavingTicket = () => {
        setFilledCreatingTicket((state) => {
            if (state) {
                setIsClosingCreateTicket(true);
            }

            return false;
        });
    }

    const setValuesOfNumbersTickets = (totalAllTickets: number, totalYoursTickets: number, totalNewTickets: number, totalUnreadTickets: number) => {
        setNumberOfAllTickets(totalAllTickets);
        setNumberOfYoursTickets(totalYoursTickets);
        setNumberOfNewTickets(totalNewTickets);
        setNumberOfUnreadTickets(totalUnreadTickets)
    }

    const closeWithoutSaving = (e: { currentTarget: { id: string; }; }) => {
        setCloseCreateTicketButton(e.currentTarget.id);

        if (filledCreatingTicket) {
            dispatch(setShowConfirmationModal({ visibility: true, text: { "title": t("home_tickets.alert_feedback.without_save.title"), "body": t("home_tickets.alert_feedback.without_save.body"), 'id': '', 
            buttonConfirmationText: t("home_tickets.alert_feedback.without_save.confirm"), buttonReturnText: t("home_tickets.alert_feedback.without_save.cancel") }, functionConfirmation:() => closeSavingTicket(), functionfunctionCancellation: resetPreviewClickedButton() }));
        } else {
            // fields not filled
            if (e.currentTarget.id === "filterAllTickets" || e.currentTarget.id === "buttonCloseWithoutSaving") {
                filtersClick(TicketFilterType.All);
            } else if (e.currentTarget.id === "filterYourTickets") {
                filtersClick(TicketFilterType.Your);
            } else if (e.currentTarget.id === "filterNewTickets") {
                filtersClick(TicketFilterType.New);
            } else if (e.currentTarget.id === "filterUnreadTickets") {
                filtersClick(TicketFilterType.Unread);
            } else {
                closeSavingTicket();
            }
        }

        return true;
    }

    return <HomeCreateTicket 
        t={t}
        isLoading={isLoading}
        values={values}
        callbackModalCustomers={callbackModalCustomers}
        setCurrentConsumer={setCurrentConsumer}
        setSimilarConsumers={setSimilarConsumers}
        currentConsumersData={currentConsumersData}
        similarConsumers={similarConsumers}
        currentConsumer={currentConsumer}
        closeWithoutSaving={closeWithoutSaving}
        numberOfAllTickets={numberOfAllTickets}
        numberOfYoursTickets={numberOfYoursTickets}
        numberOfNewTickets={numberOfNewTickets}
        numberOfUnreadTickets={numberOfUnreadTickets}
        isClosingCreateTicket={isClosingCreateTicket}
        setIsClosingCreateTicket={setIsClosingCreateTicket}
        filledCreatingTicket={filledCreatingTicket}
        filteringTicketsToTicketsPage={filteringTicketsToTicketsPage}
        setCurrentFilterSearch={setCurrentFilterSearch}
        currentFilterSearch={currentFilterSearch}
        setCurrentState={setCurrentState}
        selectedChannels={selectedChannels}
        currentChannel={currentChannel}
        setCurrentChannel={setCurrentChannel}
        onClickButtonEdit={onClickButtonEdit}
        currentRequester_user={currentRequester_user}
        setCurrentRequester_user={setCurrentRequester_user}
        currentSubject={currentSubject}
        showSubjectError={showSubjectError}
        postTickets={postTickets}
        consumersOptions={consumersOptions}
        setConsumersOptions={setConsumersOptions}
        selectedConsumers={selectedConsumers}
        setSelectedConsumers={setSelectedConsumers}
        multiselectFilterUsers={multiselectFilterUsers}
        customCreateRequesterButtonClick={customCreateRequesterButtonClick}
        onSelectRequester={onSelectRequester}
        departmentsOptions={departmentsOptions}
        setDepartmentsOptions={setDepartmentsOptions}
        selectedDepartments={selectedDepartments}
        setSelectedDepartments={setSelectedDepartments}
        multiselectFilterDepartments={multiselectFilterDepartments}
        onSelectDepartment={onSelectDepartment}
        onClickButtonAccept={onClickButtonAccept}
        agentsOptions={agentsOptions}
        setAgentsOptions={setAgentsOptions}
        selectedAgents={selectedAgents}
        setSelectedAgents={setSelectedAgents}
        multiselectFilterAgents={multiselectFilterAgents}
        onSelectAssigned={onSelectAssigned}
        onClickButtonFollow={onClickButtonFollow}
        followers={followers}
        onSearchFollowers={onSearchFollowers}
        abortController={abortController}
        setFilledCreatingTicket={setFilledCreatingTicket}
        currentFollowers={currentFollowers}
        selectFollowers={selectFollowers}
        statusOptions={statusOptions}
        selectedStatus={selectedStatus}
        setSelectedStatus={setSelectedStatus}
        onSelectStatus={onSelectStatus}
        channelOptions={channelOptions}
        setSelectedChannels={setSelectedChannels}
        onSelectChannel={onSelectChannel}
        // isBotOptions={isBotOptions} 
        // selectedBot={selectedBot} 
        // setSelectedBot={setSelectedBot} 
        // onSelectBot={onSelectBot} 
        tags={tags}
        currentTags={currentTags}
        selectTags={selectTags}
        limiterChangeTextArea={limiterChangeTextArea}
        toggleContent={toggleContent}
        showContent={showContent}
        customFields={customFields}
        textareaStates={textareaStates}
        changeCustomField={changeCustomField}
        formatedDate={formatedDate}
        date={date}
        closeCreateTicketButton={closeCreateTicketButton}
        setFilesIds={setFilesIds}
        customFieldIdErrors={customFieldIdErrors}
        channelSelected={channelSelected}
        isAllowedMessage={block_ticket.isAllowedMessage}    
    />
}

export default HomeCreateTicketController;