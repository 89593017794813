import { useDispatch, useSelector } from "react-redux";
import { TokenInterface } from "../../../../../services/requestsInterfacesModel";
import { useTranslation } from "react-i18next";
import { FC, useEffect, useState } from "react";
import { getProfileId } from "../../../../../store/user";
import { getToken } from "../../../../../store/token";
import TemplateMessageTab from ".";
import { AppRequesterController } from "../../../../../services/appRequester/appRequesterController";
import { useNavigate } from "react-router-dom";
import { getIdCompany } from "../../../../../store/company";
import { setShowCreateTemplateMessage } from "../../../../../store/internal";
import { Brokers } from "../../../../../core/enums/brokers";

const AppRequesterConst = new AppRequesterController();

const TemplateMessageController: FC<any> = (props) => {
  const values: TokenInterface = {
    company: {
      id: useSelector(getIdCompany)
    },
    user: {
      profile_id: useSelector(getProfileId)
    },
    token: {
      value: useSelector(getToken)
    },
  };

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [showTemplateMessage, setShowTemplateMessage] = useState(false);
  const [refreshTemplate, setRefreshTemplate] = useState(false);

  const [currentTemplate, setCurrentTemplate] = useState(null);

  const [whatsappKeys, setWhatsappKeys] = useState([]);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const controller = new AbortController();
    getCompanyWhatsappsSmarters(controller, values.company.id);
    getCategoriesSmarters(controller, values.company.id);
  }, []);

  const getCompanyWhatsappsSmarters = async (controller: AbortController, companyId: string) => {
    const signal = controller.signal;

    const headers = {
      "Content-Type": "application/json; charset=utf-8",
      "Authorization": "Bearer " + values.token.value
    }

    await AppRequesterConst.Get(
      `/channel-whatsapp/whatsapp-numbers?broker_id=${Brokers.Smarters}`, { headers, signal },
      (response: Object) => { },
      (data: any) => {
        if (data.status === 200) {
          setWhatsappKeys((numbers) => {
            numbers = data.data.whatsapp_numbers.map((item) => {
              return { label: item.phone_number, value: item.id }
            });
            return numbers;
          });
        }
      },
      (error: { response: { status: number } }) => {
        console.log(error);
      }, navigate, dispatch, setIsLoading, {}
    )
  }

  const getCategoriesSmarters = async (controller: AbortController, companyId: string) => {
    const signal = controller.signal;

    const headers = {
      "Content-Type": "application/json; charset=utf-8",
      "Authorization": "Bearer " + values.token.value
    }

    await AppRequesterConst.Get(
      `/template-message/categories/${Brokers.Smarters}`, { headers, signal },
      (response: Object) => { },
      (data: any) => {
        if (data.status === 200) {
          setCategories((categoriesReq) => {
            categoriesReq = data.data.categories.map((item) => {
              return { label: item.category, value: item.id }
            });
            return categoriesReq;
          });
        }
      },
      (error: { response: { status: number } }) => {
        console.log(error);
      }, navigate, dispatch, setIsLoading, {}
    )
  }
  
  const handleOpenCloseVisualizeTemplateMessage = () => {
    setShowTemplateMessage(!showTemplateMessage);
  }

  const showCreateTemplateMessage = () => {
    dispatch(setShowCreateTemplateMessage(true));
  }

  return (
    <TemplateMessageTab 
      values={values}
      t={t}
      isLoading={props.isLoading}
      setIsLoading={props.setIsLoading}
      company_id={values.company.id}
      showTemplateMessage={showTemplateMessage}
      handleOpenCloseVisualizeTemplateMessage={handleOpenCloseVisualizeTemplateMessage}
      showCreateTemplateMessage={showCreateTemplateMessage}
      refreshTemplate={refreshTemplate}
      setRefreshTemplate={setRefreshTemplate}
      setCurrentTemplate={setCurrentTemplate}
      currentTemplate={currentTemplate}
      whatsappKeys={whatsappKeys}
      categories={categories}
    />
  )
}

export default TemplateMessageController