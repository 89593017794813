import { useContext, useEffect, useState } from "react"
import { SocketContext } from "../core/context/socket-context"

const useTicketSectorChange = () => {

    const socket = useContext(SocketContext)

    const [sectorChange, setSectorChange] = useState<any>();

    useEffect(() => {

        socket.socket?.on('ticket-sector-changed', (data) => setSectorChange(data.ticket ? data.ticket : data))

        return () => {
            socket.socket?.off('ticket-sector-changed', setSectorChange)
        }

    }, [socket.socket?.active])

    return { sectorChange }

}

export default useTicketSectorChange