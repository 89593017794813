import React, { FC } from 'react';
import { Col, Container, Row, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { DashboardButton, IDataDashboard } from './indexModel';
import ModalListConsumersController from '../modalListConsumers/indexController';
import ModalListTicketsController from '../modalListTickets/indexController';
import './styles.scss';

const DataDashboard: FC<IDataDashboard> = (props) => {
    return (
        <Container className="nopadding">
            <ModalListConsumersController visibility={props.showModalListConsumers} setVisibility={props.setShowListConsumers} params={props.currentParams} />
            <ModalListTicketsController visibility={props.showModalListTickets} setVisibility={props.setShowListTickets} params={props.currentParams} />
            <Row className="justify-content-center" style={{ margin: '2vh 0.5vw 0px', padding: '0' }}>

                <Col md={2} style={{ width: props.printPDF ? '28vh' : '14vw', height: '12.7vh' }} >
                    <Row className='dashboardButton' style={{ width: props.printPDF ? '26vh' : "13vw" }} onClick={ (e) => props.openModalListTickets(DashboardButton.TotalOfTicket) } >
                        <Row style={{ marginTop: "10px", paddingRight: '0' }}>
                            <Col md={9} className="d-flex justify-content-start">
                                <p className="nopadding" style={{ color: '#0C2461', font: 'var( --px16_19_Bold-font)' }}>
                                    {`${props.t('dashboard.total_tickets')}`}
                                </p>
                            </Col>
                            {props.printPDF ?
                                <></>
                                :
                                <Col className="nopadding d-flex justify-content-end">
                                    <OverlayTrigger
                                        key='top'
                                        placement='top'
                                        overlay={
                                            <Tooltip id='tooltip'>
                                                {`${props.t('dashboard.tooltip_total_tickets')}`}
                                            </Tooltip>
                                        }
                                    >
                                        <svg id="informação" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                            <path id="Caminho_10010" data-name="Caminho 10010" d="M0,0H24V24H0Z" fill="none" />
                                            <circle id="Elipse_1556" data-name="Elipse 1556" cx="9" cy="9" r="9" transform="translate(3 3)" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                            <line id="Linha_604" data-name="Linha 604" x2="0.01" transform="translate(12 8)" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                            <path id="Caminho_10011" data-name="Caminho 10011" d="M11,12h1v4h1" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                        </svg>
                                    </OverlayTrigger>
                                </Col>
                            }
                        </Row>
                        <Row >
                            <Col style={{ color: '#4A69BD', font: 'normal normal bold 30px/37px Montserrat', marginBottom: '0.7vh' }} className="d-flex justify-content-start align-self-end">
                                {props.totalTickets != null ?
                                    props.totalTickets.toLocaleString('pt-BR') :
                                    <>0</>
                                }
                            </Col>
                        </Row>
                    </Row>
                </Col>

                <Col md={2} style={{ width: props.printPDF ? '28vh' : '14vw', height: '12.7vh' }} >
                    <Row className='dashboardButton' style={{ width: props.printPDF ? '26vh' : "13vw" }} onClick={ (e) => props.openModalListTickets(DashboardButton.InProgress) } >
                        <Row style={{ marginTop: "10px", paddingRight: '0' }}>
                            <Col md={10} className="d-flex justify-content-start">
                                <p className="nopadding" style={{ color: '#0C2461', font: 'var( --px16_19_Bold-font)' }}>
                                    {`${props.t('dashboard.tickets_andamento')}`}
                                </p>
                            </Col>
                            {props.printPDF ?
                                <></>
                                :
                                <Col className="nopadding d-flex justify-content-end">
                                    <OverlayTrigger
                                        key='top'
                                        placement='top'
                                        overlay={
                                            <Tooltip id='tooltip'>
                                                {`${props.t('dashboard.tooltip_tickets_andamento')}`}
                                            </Tooltip>
                                        }
                                    >
                                        <svg id="informação" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                            <path id="Caminho_10010" data-name="Caminho 10010" d="M0,0H24V24H0Z" fill="none" />
                                            <circle id="Elipse_1556" data-name="Elipse 1556" cx="9" cy="9" r="9" transform="translate(3 3)" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                            <line id="Linha_604" data-name="Linha 604" x2="0.01" transform="translate(12 8)" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                            <path id="Caminho_10011" data-name="Caminho 10011" d="M11,12h1v4h1" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                        </svg>
                                    </OverlayTrigger>
                                </Col>
                            }
                        </Row>
                        <Row>
                            <Col style={{ color: '#4A69BD', font: 'normal normal bold 30px/37px Montserrat', marginBottom: '0.7vh' }} className="d-flex justify-content-start align-self-end">
                                {props.inProgressTickets != null ?
                                    props.inProgressTickets.toLocaleString('pt-BR') :
                                    <>0</>
                                }
                            </Col>
                        </Row>
                    </Row>
                </Col>

                <Col md={2} style={{ width: props.printPDF ? '28vh' : '14vw', height: '12.7vh' }} >
                    <Row className='dashboardButton' style={{ width: props.printPDF ? '26vh' : "13vw" }} onClick={ (e) => props.openModalListTickets(DashboardButton.New) } >
                        <Row style={{ marginTop: "10px", paddingRight: '0' }}>
                            <Col md={10} className="d-flex justify-content-start">
                                <p className="nopadding" style={{ color: '#0C2461', font: 'var( --px16_19_Bold-font)' }}>
                                    {`${props.t('dashboard.tickets_novos')}`}
                                </p>
                            </Col>
                            {props.printPDF ?
                                <></>
                                :
                                <Col className="nopadding d-flex justify-content-end">
                                    <OverlayTrigger
                                        key='top'
                                        placement='top'
                                        overlay={
                                            <Tooltip id='tooltip'>
                                                {`${props.t('dashboard.tooltip_tickets_novos')}`}
                                            </Tooltip>
                                        }
                                    >
                                        <svg id="informação" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                            <path id="Caminho_10010" data-name="Caminho 10010" d="M0,0H24V24H0Z" fill="none" />
                                            <circle id="Elipse_1556" data-name="Elipse 1556" cx="9" cy="9" r="9" transform="translate(3 3)" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                            <line id="Linha_604" data-name="Linha 604" x2="0.01" transform="translate(12 8)" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                            <path id="Caminho_10011" data-name="Caminho 10011" d="M11,12h1v4h1" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                        </svg>
                                    </OverlayTrigger>
                                </Col>
                            }
                        </Row>
                        <Row>
                            <Col style={{ color: '#4A69BD', font: 'normal normal bold 30px/37px Montserrat', marginBottom: '0.7vh' }} className="d-flex justify-content-start align-self-end">
                                {props.newTickets != null ?
                                    props.newTickets.toLocaleString('pt-BR') :
                                    <>0</>
                                }
                            </Col>
                        </Row>
                    </Row>
                </Col>

                <Col md={2} style={{ width: props.printPDF ? '28vh' : '14vw', height: '12.7vh' }} >
                    <Row className='dashboardButton' style={{ width: props.printPDF ? '26vh' : "13vw" }} onClick={props.openModalListConsumers} >
                        <Row style={{ marginTop: "10px", paddingRight: '0' }}>
                            <Col md={10} className="d-flex justify-content-start">
                                <p className="nopadding" style={{ color: '#0C2461', font: 'var( --px16_19_Bold-font)' }}>
                                    {`${props.t('dashboard.total_consumidores')}`}
                                </p>
                            </Col>
                            {props.printPDF ?
                                <></>
                                :
                                <Col className="nopadding d-flex justify-content-end">
                                    <OverlayTrigger
                                        key='top'
                                        placement='top'
                                        overlay={
                                            <Tooltip id='tooltip'>
                                                {`${props.t('dashboard.tooltip_total_consumidores')}`}
                                            </Tooltip>
                                        }
                                    >
                                        <svg id="informação" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                            <path id="Caminho_10010" data-name="Caminho 10010" d="M0,0H24V24H0Z" fill="none" />
                                            <circle id="Elipse_1556" data-name="Elipse 1556" cx="9" cy="9" r="9" transform="translate(3 3)" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                            <line id="Linha_604" data-name="Linha 604" x2="0.01" transform="translate(12 8)" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                            <path id="Caminho_10011" data-name="Caminho 10011" d="M11,12h1v4h1" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                        </svg>
                                    </OverlayTrigger>
                                </Col>
                            }
                        </Row>
                        <Row>
                            <Col style={{ color: '#4A69BD', font: 'normal normal bold 30px/37px Montserrat', marginBottom: '0.7vh' }} className="d-flex justify-content-start align-self-end">
                                {props.totalConsumers != null ?
                                    props.totalConsumers.toLocaleString('pt-BR') :
                                    <>0</>
                                }
                            </Col>
                        </Row>
                    </Row>
                </Col>

                {/* <Col md={2} style={{ width: props.printPDF ? '28vh' : '14vw', height: '12.7vh' }} >
                    <Row style={{ background: "#FFFFFF", borderRadius: "10px", width: props.printPDF ? '26vh' : "13vw", height: "10.6vh" }} >
                        <Row style={{ marginTop: "10px", paddingRight: '0' }}>
                            <Col md={10} className="d-flex justify-content-start">
                                <p className="nopadding" style={{ color: '#0C2461', font: 'var( --px16_19_Bold-font)' }}>
                                    Mensagens trocadas
                                </p>
                            </Col>
                            {props.printPDF ?
                                <></>
                                :
                                <Col className="nopadding d-flex justify-content-end">
                                    <OverlayTrigger
                                        key='top'
                                        placement='top'
                                        overlay={
                                            <Tooltip id='tooltip'>
                                                Total de mensagens enviadas e recebidas pelo atendimento humano
                                            </Tooltip>
                                        }
                                    >
                                        <svg id="informação" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                            <path id="Caminho_10010" data-name="Caminho 10010" d="M0,0H24V24H0Z" fill="none" />
                                            <circle id="Elipse_1556" data-name="Elipse 1556" cx="9" cy="9" r="9" transform="translate(3 3)" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                            <line id="Linha_604" data-name="Linha 604" x2="0.01" transform="translate(12 8)" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                            <path id="Caminho_10011" data-name="Caminho 10011" d="M11,12h1v4h1" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                        </svg>
                                    </OverlayTrigger>
                                </Col>
                            }
                        </Row>
                        <Row>
                            <Col style={{ color: '#4A69BD', font: 'normal normal bold 30px/37px Montserrat', marginBottom: '0.7vh' }} className="d-flex justify-content-start align-self-end">
                                {totalExchangeMessages != null ?
                                    totalExchangeMessages.toLocaleString('pt-BR') :
                                    <>0</>
                                }
                            </Col>
                        </Row>
                    </Row>
                </Col> */}
            </Row>

            <Row className="justify-content-center" style={{ margin: '0vh 0.5vw 0px', padding: '0' }}>

                {/* <Col md={2} style={{ width: props.printPDF ? '28vh' : '14vw', height: '12.7vh' }} >
                    <Row style={{ background: "#FFFFFF", borderRadius: "10px", width: props.printPDF ? '26vh' : "13vw", height: "10.6vh" }} >
                        <Row style={{ marginTop: "10px", paddingRight: '0' }}>
                            <Col md={10} className="d-flex justify-content-start">
                                <p className="nopadding" style={{ color: '#0C2461', font: 'var( --px16_19_Bold-font)' }}>
                                    Mensagens enviadas
                                </p>
                            </Col>
                            {props.printPDF ?
                                <></>
                                :
                                <Col className="nopadding d-flex justify-content-end">
                                    <OverlayTrigger
                                        key='top'
                                        placement='top'
                                        overlay={
                                            <Tooltip id='tooltip'>
                                                Total de mensagens enviadas pelo atendimento humano
                                            </Tooltip>
                                        }
                                    >
                                        <svg id="informação" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                            <path id="Caminho_10010" data-name="Caminho 10010" d="M0,0H24V24H0Z" fill="none" />
                                            <circle id="Elipse_1556" data-name="Elipse 1556" cx="9" cy="9" r="9" transform="translate(3 3)" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                            <line id="Linha_604" data-name="Linha 604" x2="0.01" transform="translate(12 8)" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                            <path id="Caminho_10011" data-name="Caminho 10011" d="M11,12h1v4h1" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                        </svg>
                                    </OverlayTrigger>
                                </Col>
                            }
                        </Row>
                        <Row>
                            <Col style={{ color: '#4A69BD', font: 'normal normal bold 30px/37px Montserrat', marginBottom: '0.7vh' }} className="d-flex justify-content-start align-self-end">
                                {totalSentMessages != null ?
                                    totalSentMessages.toLocaleString('pt-BR') :
                                    <>0</>
                                }
                            </Col>
                        </Row>
                    </Row>
                </Col> */}

                <Col md={2} style={{ width: props.printPDF ? '28vh' : '14vw', height: '12.7vh' }} >
                    <Row style={{ background: "#FFFFFF", borderRadius: "10px", width: props.printPDF ? '26vh' : "13vw", height: "10.6vh" }} >
                        <Row style={{ marginTop: "10px", paddingRight: '0' }}>
                            <Col md={10} className="d-flex justify-content-start">
                                <p className="nopadding" style={{ color: '#0C2461', font: 'var( --px16_19_Bold-font)' }}>
                                    {`${props.t('dashboard.tempo_medio_ticket')}`}
                                </p>
                            </Col>
                            {props.printPDF ?
                                <></>
                                :
                                <Col className="nopadding d-flex justify-content-end">
                                    <OverlayTrigger
                                        key='top'
                                        placement='top'
                                        overlay={
                                            <Tooltip id='tooltip'>
                                                {`${props.t('dashboard.tooltip_tempo_medio_ticket')}`}
                                            </Tooltip>
                                        }
                                    >
                                        <svg id="informação" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                            <path id="Caminho_10010" data-name="Caminho 10010" d="M0,0H24V24H0Z" fill="none" />
                                            <circle id="Elipse_1556" data-name="Elipse 1556" cx="9" cy="9" r="9" transform="translate(3 3)" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                            <line id="Linha_604" data-name="Linha 604" x2="0.01" transform="translate(12 8)" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                            <path id="Caminho_10011" data-name="Caminho 10011" d="M11,12h1v4h1" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                        </svg>
                                    </OverlayTrigger>
                                </Col>
                            }
                        </Row>
                        <Row>
                            <Col style={{ color: '#4A69BD', font: 'normal normal bold 30px/37px Montserrat', marginBottom: '0.7vh' }} className="d-flex justify-content-start align-self-end">
                                {props.averageTicketsTime != null ?
                                    props.convertNumToTime(props.averageTicketsTime) :
                                    <>0h</>
                                }
                            </Col>
                        </Row>
                    </Row>
                </Col>

                <Col md={2} style={{ width: props.printPDF ? '28vh' : '14vw', height: '12.7vh' }} >
                    <Row style={{ background: "#FFFFFF", borderRadius: "10px", width: props.printPDF ? '26vh' : "13vw", height: "10.6vh" }} >
                        <Row style={{ marginTop: "10px", paddingRight: '0' }}>
                            <Col md={10} className="d-flex justify-content-start">
                                <p className="nopadding" style={{ color: '#0C2461', font: 'var( --px16_19_Bold-font)' }}>
                                    {`${props.t('dashboard.tempo_medio_espera')}`}
                                </p>
                            </Col>
                            {props.printPDF ?
                                <></>
                                :
                                <Col className="nopadding d-flex justify-content-end">
                                    <OverlayTrigger
                                        key='top'
                                        placement='top'
                                        overlay={
                                            <Tooltip id='tooltip'>
                                                {`${props.t('dashboard.tooltip_tempo_medio_espera')}`}
                                            </Tooltip>
                                        }
                                    >
                                        <svg id="informação" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                            <path id="Caminho_10010" data-name="Caminho 10010" d="M0,0H24V24H0Z" fill="none" />
                                            <circle id="Elipse_1556" data-name="Elipse 1556" cx="9" cy="9" r="9" transform="translate(3 3)" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                            <line id="Linha_604" data-name="Linha 604" x2="0.01" transform="translate(12 8)" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                            <path id="Caminho_10011" data-name="Caminho 10011" d="M11,12h1v4h1" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                        </svg>
                                    </OverlayTrigger>
                                </Col>
                            }
                        </Row>
                        <Row>
                            <Col style={{ color: '#4A69BD', font: 'normal normal bold 30px/37px Montserrat', marginBottom: '0.7vh' }} className="d-flex justify-content-start align-self-end">
                                {props.averageWaitingTime != null ?
                                    props.convertNumToTime(props.averageWaitingTime) :
                                    <>0h</>
                                }
                            </Col>
                        </Row>
                    </Row>
                </Col>

                <Col md={2} style={{ width: props.printPDF ? '28vh' : '14vw', height: '12.7vh' }} >
                    <Row style={{ background: "#FFFFFF", borderRadius: "10px", width: props.printPDF ? '26vh' : "13vw", height: "10.6vh" }} >
                        <Row style={{ marginTop: "10px", paddingRight: '0' }}>
                            <Col md={10} className="d-flex justify-content-start">
                                <p className="nopadding" style={{ color: '#0C2461', font: 'var( --px16_19_Bold-font)' }}>
                                    {`${props.t('dashboard.taxa_desistencia')}`}
                                </p>
                            </Col>
                            {props.printPDF ?
                                <></>
                                :
                                <Col className="nopadding d-flex justify-content-end">
                                    <OverlayTrigger
                                        key='top'
                                        placement='top'
                                        overlay={
                                            <Tooltip id='tooltip'>
                                                {`${props.t('dashboard.tooltip_taxa_desistencia')}`}
                                            </Tooltip>
                                        }
                                    >
                                        <svg id="informação" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                            <path id="Caminho_10010" data-name="Caminho 10010" d="M0,0H24V24H0Z" fill="none" />
                                            <circle id="Elipse_1556" data-name="Elipse 1556" cx="9" cy="9" r="9" transform="translate(3 3)" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                            <line id="Linha_604" data-name="Linha 604" x2="0.01" transform="translate(12 8)" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                            <path id="Caminho_10011" data-name="Caminho 10011" d="M11,12h1v4h1" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                        </svg>
                                    </OverlayTrigger>
                                </Col>
                            }
                        </Row>
                        <Row>
                            <Col style={{ color: '#4A69BD', font: 'normal normal bold 30px/37px Montserrat', marginBottom: '0.7vh' }} className="d-flex justify-content-start align-self-end">
                                {props.dropoutRate != null ?
                                    props.dropoutRate + '%' :
                                    <>0%</>
                                }
                            </Col>
                        </Row>
                    </Row>
                </Col>

                <Col md={2} style={{ width: props.printPDF ? '28vh' : '14vw', height: '12.7vh' }} >
                    <Row style={{ background: "#FFFFFF", borderRadius: "10px", width: props.printPDF ? '26vh' : "13vw", height: "10.6vh" }} >
                        <Row style={{ marginTop: "10px", paddingRight: '0' }}>
                            <Col md={10} className="d-flex justify-content-start">
                                <p className="nopadding" style={{ color: '#0C2461', font: 'var( --px16_19_Bold-font)' }}>
                                    {`${props.t('dashboard.media_tickets_resolvidos')}`}
                                </p>
                            </Col>
                            {props.printPDF ?
                                <></>
                                :
                                <Col className="nopadding d-flex justify-content-end">
                                    <OverlayTrigger
                                        key='top'
                                        placement='top'
                                        overlay={
                                            <Tooltip id='tooltip'>
                                                {`${props.t('dashboard.tooltip_media_tickets_resolvidos')}`}
                                            </Tooltip>
                                        }
                                    >
                                        <svg id="informação" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                            <path id="Caminho_10010" data-name="Caminho 10010" d="M0,0H24V24H0Z" fill="none" />
                                            <circle id="Elipse_1556" data-name="Elipse 1556" cx="9" cy="9" r="9" transform="translate(3 3)" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                            <line id="Linha_604" data-name="Linha 604" x2="0.01" transform="translate(12 8)" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                            <path id="Caminho_10011" data-name="Caminho 10011" d="M11,12h1v4h1" fill="none" stroke="#1e1e1e" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                        </svg>
                                    </OverlayTrigger>
                                </Col>
                            }
                        </Row>
                        <Row>
                            <Col style={{ color: '#4A69BD', font: 'normal normal bold 30px/37px Montserrat', marginBottom: '0.7vh' }} className="d-flex justify-content-start align-self-end">
                                {props.averageTicketsSolved != null ?
                                    props.averageTicketsSolved.toLocaleString('pt-BR') :
                                    <>0</>
                                }
                            </Col>
                        </Row>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
}

export default DataDashboard;