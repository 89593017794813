import React from 'react';
import { Modal, Button, Form, InputGroup, FormControl, Col, Row, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Formik } from 'formik';
import allStyles from './styles';
import Tags from "@yaireo/tagify/dist/react.tagify"
import { setShowAlertFeedback } from '../../../../store/internal';
import UserService from '../../../../services/user-service';
import SelectComponentController from '../selectComponent/indexController';

const ModalEditCustomFieldTickets = ({
    show,
    clearFields,
    data,
    selectedOptions,
    setName,
    setPlaceholder,
    setShowFeedbackName,
    setShowFeedbackplaceholder,
    placeholder,
    selectedType,
    name,
    labelledOptions,
    selectedsOptions,
    AppRequesterConst,
    dispatch,
    callBackModalCustomField,
    navigate,
    validated,
    setIsLoading,
    showFeedbackName,
    onChangeTags,
    setLabelledOptions,
    setSelectedsOptions,
    showFeedbackplaceholder,
    t,
    setCustomTicketIsRequired,
    customTicketIsRequired,
    selectedType1,
    setShowFeedbackMin,
    setShowFeedbackMax,
    showFeedbackMin,
    showFeedbackMax,
    min,
    max,
}) => {

    return (
        <Modal show={show} onHide={clearFields} centered>
            <Modal.Header closeButton>
                <Modal.Title>{t('configurations_ticket.modal_edit_custom_field.edit_custom_field')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Formik
                    enableReinitialize={true}
                    initialValues={{ name: data?.name, min: data?.field_size_min, max: data?.field_size_max, type: data?.custom_field_datatype_id, placeholder: data?.placeholder /*, optionList: [], defaultValue: [] */ }}
                    validate={values => {
                        let errors = {};
                        setName(values.name);
                        setPlaceholder(values.placeholder)
                        // setSelectedOptions(values.optionList)
                        // setDefaultValue(values.defaultValue)

                        if (!values.name) {
                            errors["name"] = t('configurations_ticket.modal_edit_custom_field.validate.name');
                            setShowFeedbackName(true);
                        } else {
                            setShowFeedbackName(false);
                        }

                        if (!values.placeholder) {
                            errors["placeholder"] = t('configurations_ticket.modal_edit_custom_field.validate.placeholder');
                            setShowFeedbackplaceholder(true);
                        } else {
                            setShowFeedbackplaceholder(false);
                        }

                        return errors;
                    }}
                    onSubmit={async values => {
                        const headers = UserService.getHeaders()
                        let JsonSend = {
                            "id": data?.id,
                            "entity_type_id": data?.entity_type_id,
                            "placeholder": placeholder,
                            "required": customTicketIsRequired,
                        }
                        // if (selectedType[0].value === t('configurations_ticket.modal_edit_custom_field.submit.simple_text')) {
                        //     JsonSend['custom_field_type_id'] = 'd31ebd9e-af9f-4559-b04a-729439218a6f';
                        // }
                        // else if (selectedType[0].value === t('configurations_ticket.modal_edit_custom_field.submit.multiline_text')) {
                        //     JsonSend['custom_field_type_id'] = '2982a738-4de3-440a-8ed1-cc1fdb51bc9b';
                        // }
                        // else if (selectedType[0].value === t('configurations_ticket.modal_edit_custom_field.submit.simple_select')) {
                        //     JsonSend['custom_field_type_id'] = '74bf4b70-3415-4e8a-ac81-7ebe435c1424';
                        // }
                        // else if (selectedType[0].value === t('configurations_ticket.modal_edit_custom_field.submit.multiple_select')) {
                        //     JsonSend['custom_field_type_id'] = '49d495a8-50d4-4d61-9d62-e1cc03227573';
                        // }

                        if(max <= 50) {
                            JsonSend['custom_field_type_id'] = 'd31ebd9e-af9f-4559-b04a-729439218a6f'
                        }else if (max > 50 && max <= 500){
                            JsonSend['custom_field_type_id'] = '2982a738-4de3-440a-8ed1-cc1fdb51bc9b'
                        }

                        if (name !== '') {
                            JsonSend['name'] = name;
                        }
                        if (labelledOptions.length > 0) {
                            let options = []
                            labelledOptions.map((option, index) => {
                                options[index] = option.value
                            })
                            JsonSend['options'] = options
                        }
                        if (selectedsOptions[0].value !== null)
                            JsonSend["default_value"] = selectedsOptions[0].value

                        await AppRequesterConst.Put(
                            '/custom-fields', JsonSend, { headers },
                            (response: Object) => {
                                return response;
                            },
                            (data: { data: { user_id: string; }; }) => {
                                dispatch(setShowAlertFeedback({ message: t('configurations_ticket.modal_edit_custom_field.submit.message'), visibility: true, signalIcon: true }));
                                callBackModalCustomField()
                                clearFields();
                            },
                            (error) => {
                                dispatch(setShowAlertFeedback({ message: t('configurations_ticket.modal_edit_custom_field.submit.error'), visibility: true, signalIcon: false }));
                            }, navigate, dispatch, setIsLoading
                        );

                    }
                    }
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                    }) => (
                        <Form onSubmit={handleSubmit} noValidate validated={validated}>
                            <Row style={allStyles.textFontGlay} className=" d-flex justify-content-center">
                                <Col sm={true}>
                                    <Form.Label htmlFor="name">{t('configurations_ticket.modal_edit_custom_field.form_name.name')}<span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black' }}>*</span></Form.Label>
                                    <InputGroup className="mb-3">
                                        <FormControl className="form-control-Default"
                                            isInvalid={showFeedbackName}
                                            placeholder={t('configurations_ticket.modal_edit_custom_field.form_name.placeholder')}
                                            aria-label={t('configurations_ticket.modal_edit_custom_field.form_name.placeholder')}
                                            aria-describedby={t('configurations_ticket.modal_edit_custom_field.form_name.placeholder')}
                                            id="name"
                                            maxLength={50}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.name}
                                        >
                                        </FormControl>
                                        <Form.Control.Feedback type="invalid" id="feedbackname">
                                            {errors.name}
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row style={allStyles.textFontGlay} className=" d-flex justify-content-center">
                                <Col sm={true}>
                                    <Form.Label htmlFor="basic-url">{t('configurations_ticket.modal_edit_custom_field.form_type.type')}<span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black' }}>*</span></Form.Label>
                                    <InputGroup className="mb-3">
                                        <FormControl className="form-control-Default"
                                            id="tipo"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={selectedType1[0]?.label}
                                            disabled={true}
                                        >
                                        </FormControl>
                                    </InputGroup>
                                </Col>
                            </Row>
                            {(selectedType1[0]?.value.length > 0) &&
                                <>
                                    <Row style={allStyles.textFontGlay} className="d-flex justify-content-center">
                                        <Col sm={true} style={allStyles.textFontGlay}>
                                            <Form.Label htmlFor="basic-url">
                                                {t('configurations_ticket.modal_register_custom_field.form_limit_type.limit_text_Value')}
                                                <span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black' }}>*</span>
                                            </Form.Label>
                                            <Row>
                                                <Col>
                                                    <InputGroup className="mb-3">
                                                        <FormControl className="form-control-Default"
                                                            id="min"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.min}
                                                            disabled={true}
                                                        />
                                                        <Form.Control.Feedback type="invalid" id="feedbackmin">
                                                            {errors.min}
                                                        </Form.Control.Feedback>
                                                    </InputGroup>
                                                </Col>
                                                <Col>
                                                    <InputGroup className="mb-3">
                                                        <FormControl className="form-control-Default"
                                                            id="max"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.max}
                                                            disabled={true}
                                                        />
                                                        <Form.Control.Feedback type="invalid" id="feedbackmax">
                                                            {errors.max}
                                                        </Form.Control.Feedback>
                                                    </InputGroup>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </>
                            }
                            {/* {(selectedType[0]?.label === t('configurations_ticket.modal_edit_custom_field.simple_select') || selectedType[0]?.label === t('configurations_ticket.modal_edit_custom_field.multiple_select')) && (
                                <>
                                    <Row className="d-flex justify-content-center">
                                        <Col sm={true} style={allStyles.textFontGlay} >
                                            <Form.Label htmlFor="basic-url">{t('configurations_ticket.modal_edit_custom_field.form_options_list.options_list')}<span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black' }}>*</span></Form.Label>
                                            <Tags
                                                className="form-control-Default-text-area tag-min-height mb-3"
                                                placeholder={t('configurations_ticket.modal_edit_custom_field.form_options_list.placeholder')}
                                                onChange={onChangeTags}
                                                defaultValue={selectedOptions}
                                            />
                                        </Col>
                                    </Row>
                                    {selectedType[0]?.label === t('configurations_ticket.modal_edit_custom_field.simple_select') && (
                                        <Row style={allStyles.textFontGlay} className=" d-flex justify-content-center">
                                            <Col sm={true}>
                                                <Form.Label htmlFor="basic-url">{t('configurations_ticket.modal_edit_custom_field.form_default_option.default_option')}<span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black' }}>*</span></Form.Label>
                                                <SelectComponentController
                                                    className={"multiselectForm mb-3"}
                                                    options={setLabelledOptions.length > 0 ? labelledOptions : []}
                                                    setOptions={setLabelledOptions}
                                                    selecteds={setSelectedsOptions.length > 0 ? selectedsOptions : []}
                                                    setSelecteds={setSelectedsOptions}
                                                    singleSelect={true}
                                                    title={t('configurations_ticket.modal_edit_custom_field.form_default_option.placeholder')}
                                                    overrideStrings={{
                                                        "allItemsAreSelected": t('configurations_ticket.modal_edit_custom_field.form_default_option.override_strings.allItemsAreSelected'),
                                                        "clearSearch": t('configurations_ticket.modal_edit_custom_field.form_default_option.override_strings.clearSearch'),
                                                        "noOptions": t('configurations_ticket.modal_edit_custom_field.form_default_option.override_strings.noOptions'),
                                                        "search": t('configurations_ticket.modal_edit_custom_field.form_default_option.override_strings.search'),
                                                        "selectSomeItems": t('configurations_ticket.modal_edit_custom_field.form_default_option.override_strings.selectSomeItems'),
                                                        "create": t('configurations_ticket.modal_edit_custom_field.form_default_option.override_strings.create'),
                                                    }}
                                                    hasCreateItem={false}
                                                    hasNullOption={false}
                                                    // filtering={false}
                                                    hasAllOption={false}
                                                    isFormNoFilter={false} />
                                            </Col>
                                        </Row>
                                    )}
                                </>)} */}
                            <Row style={allStyles.textFontGlay} className=" d-flex justify-content-center">
                                <Col sm={true}>
                                    <Form.Label htmlFor="name">{t('configurations_ticket.modal_edit_custom_field.form_placeholder.placeholder')}<span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black' }}>*</span></Form.Label>
                                    <InputGroup className="mb-3">
                                        {selectedType[0]?.value === 'Texto simples' || !selectedType[0]?.value ?
                                            <FormControl className="form-control-Default"
                                                isInvalid={showFeedbackplaceholder}
                                                placeholder={t('configurations_ticket.modal_edit_custom_field.form_placeholder.placeholder_value')}
                                                aria-label={t('configurations_ticket.modal_edit_custom_field.form_placeholder.placeholder_value')}
                                                aria-describedby={t('configurations_ticket.modal_edit_custom_field.form_placeholder.placeholder_value')}
                                                id="placeholder"
                                                maxLength={50}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.placeholder}
                                            />
                                            :
                                            <FormControl className="form-control-Default"
                                                isInvalid={showFeedbackplaceholder}
                                                as="textarea"
                                                placeholder={t('configurations_ticket.modal_edit_custom_field.form_placeholder.placeholder_value')}
                                                aria-label={t('configurations_ticket.modal_edit_custom_field.form_placeholder.placeholder_value')}
                                                aria-describedby={t('configurations_ticket.modal_edit_custom_field.form_placeholder.placeholder_value')}
                                                id="placeholder"
                                                type='textarea'
                                                maxLength={500}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.placeholder}
                                            />
                                        }
                                        <Form.Control.Feedback type="invalid" id="feedbackplaceholder">
                                            {errors.placeholder}
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Col>
                            </Row>
                            <div className="switch-input mt-3" >
                                <span style={allStyles.textFontGlay}>{t("configurations_ticket.modal_edit_custom_field.required")}</span>
                                <OverlayTrigger
                                    key='top'
                                    placement='top'
                                    overlay={
                                    <Tooltip id='tooltip'>
                                        {customTicketIsRequired ?
                                        t("configurations_ticket.modal_edit_custom_field.yes") :
                                        t("configurations_ticket.modal_edit_custom_field.no")
                                        }
                                    </Tooltip>
                                    }
                                >
                                <label className="switch">
                                    <input
                                        disabled={setIsLoading}
                                        type="checkbox"
                                        checked={customTicketIsRequired}
                                        onChange={customTicketIsRequired ? () => setCustomTicketIsRequired(false) : () => setCustomTicketIsRequired(true)}
                                    />
                                    <span className="slider round"></span>
                                </label>
                                </OverlayTrigger>
                            </div>
                            <Row className="d-flex justify-content-center mt-4">
                                <Col xs={6} className="d-flex justify-content-start">
                                    <Button size='lg' className="buttonWhite" style={{ width: '56%' }} onClick={clearFields} >{t('configurations_ticket.modal_edit_custom_field.cancel')}</Button>
                                </Col>
                                <Col xs={6} className="d-flex justify-content-end">
                                    <Button size='lg' type='submit' className="buttonBlue" style={{ width: '56%' }}>{t('configurations_ticket.modal_edit_custom_field.save')}</Button>
                                </Col>
                            </Row>
                        </Form>
                    )}
                </Formik>
            </Modal.Body>
        </Modal>
    );
};

export default ModalEditCustomFieldTickets;