import React, { FC, useContext } from "react";
import { useDispatch, useSelector } from "react-redux"
import { DataTemplateMessageWhatsappCompanyIdInterface, TokenInterface } from "../../../../../../services/requestsInterfacesModel"
import { getIdCompany } from "../../../../../../store/company"
import { getToken } from "../../../../../../store/token"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { AppRequesterController } from "../../../../../../services/appRequester/appRequesterController"
import UserService from "../../../../../../services/user-service"
import { TemplateMessageComponentControllerInterface } from "./indexModel"
import editorStyles from "./styles.module.css";
import TemplateMessageComponent from ".";
import { useTranslation } from "react-i18next";
import { getIdUser } from "../../../../../../store/user";
import { BlockTicketContext } from "../../../../../../core/context/block-ticket-context";

const TemplateMessageController: FC<TemplateMessageComponentControllerInterface> = (props) => {
    const values: TokenInterface = {
        company: {
            id: useSelector(getIdCompany)
        },
        token: {
            value: useSelector(getToken)
        },
        user: {
            id: useSelector(getIdUser)
        }
    }

    const [/*isLoading*/, setIsLoading] = useState(false)
    const [templateMessage, setTemplateMessage] = useState([]);
    const [currentFilterSearch, setCurrentFilterSearch] = useState(null);
    const [classQuickMessages, setClassQuickMessage] = useState(editorStyles['nav-link'] + " " + editorStyles['nav']);
    const [quickMessage, setQuickMessage] = useState([])
    //classWhatsappResponse sempre começa com ativo
    const [classWhatsappResponse, setClassWhatsappResponse] = useState(editorStyles['nav-link'] + " " + editorStyles['nav'] + " " + editorStyles['active']);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const AppRequesterConst = new AppRequesterController();
    const { t } = useTranslation();
    const block_ticket = useContext(BlockTicketContext);

    // const modalTemplateMessegeControllerConst = new modalTemplateMessegeController(setClassQuickMessage, editorStyles, setClassWhatsappResponse);

    useEffect(() => {
        if (currentFilterSearch !== null) {
            filterAllTemplateMessage();
            filterQuickMessage()
        }
    }, [currentFilterSearch]);

    const filterAllTemplateMessage = async () => {
        const headers = UserService.getHeaders();
        const router = props.currentTicket ? `/template-message/whatsapp/${values.company.id}?${props.currentTicket.whatsapp_keys_id ? `whatsAppKeysId=${props.currentTicket.whatsapp_keys_id}&` : ''}search=${currentFilterSearch}&ticketId=${props.currentTicket.id}&employeeUserId=${values.user.id}` : `/template-message/whatsapp/${values.company.id}?search=${currentFilterSearch}`

        await AppRequesterConst.Get(
            router,
            { headers },
            (response: Object) => {
            },
            (data: DataTemplateMessageWhatsappCompanyIdInterface) => {
                if (data.status === 200) {
                    setTemplateMessage(data.data.templateMessages);
                }

            },
            (error: Object) => { }, navigate, dispatch, setIsLoading, { values: values }
        );
    }

    const filterQuickMessage = async () => {
        const headers = UserService.getHeaders()
        const router = props?.currentTicket?.id ? `/template-message/quick-message/?search=${currentFilterSearch}&ticketId=${props.currentTicket.id}` : `/template-message/quick-message/?search=${currentFilterSearch}`

        await AppRequesterConst.Get(
            router,
            { headers },
            (response: Object) => {

            },
            (data: any) => {
                if (data.status === 200) {
                    setQuickMessage(data.data.quickMessages);
                }
            },
            (error: Object) => {}, navigate, dispatch, setIsLoading, { values:values }
        )
    }
    
    const tabActive = (selectedTab: string) => {
        if(selectedTab === "mensagens_rapidas"){
            setClassQuickMessage(editorStyles['nav-link'] + " " + editorStyles['nav'] + " " + editorStyles['active'])
            setClassWhatsappResponse(editorStyles['nav-link'] + " " + editorStyles['nav'])
        } else {
            setClassQuickMessage(editorStyles['nav-link'] + " " + editorStyles['nav'])
            setClassWhatsappResponse(editorStyles['nav-link'] + " " + editorStyles['nav'] + " " + editorStyles['active'])
        }
    }

    return <TemplateMessageComponent 
        showTemplateMessage={props.showTemplateMessage} 
        tabActive={tabActive} 
        currentTicket={props.currentTicket} 
        currentChannelId={props.currentChannelId} 
        classQuickMessages={classQuickMessages} 
        quickMessage={quickMessage} 
        setCurrentFilterSearch={setCurrentFilterSearch} 
        currentFilterSearch={currentFilterSearch} 
        callbackTemplateMessage={props.callbackTemplateMessage} 
        classWhatsappResponse={classWhatsappResponse} 
        templateMessage={templateMessage} 
        t={t}
        block_ticket={block_ticket}
        callbackQuickMessage={props.callbackQuickMessage}
        hasLastMessageFromConsumer={props.currentTicket?.consumer_last_message_content !== null? true: false}    
    />
}

export default TemplateMessageController;