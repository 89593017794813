import { useEffect, useState } from "react";
import { CustomField, Inputs } from "../../../../core/enums/custom-field-type";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppRequesterController } from "../../../../services/appRequester/appRequesterController";
import { TokenInterface } from "../../../../services/requestsInterfacesModel";
import { getIdCompany } from "../../../../store/company";
import { getIdEmployee } from "../../../../store/employee";
import { getIdUser } from "../../../../store/user";
import { getToken } from "../../../../store/token";
import { getShowConfirmationModal, getShowMeetButton } from "../../../../store/internal";
import { getOwner_id } from "../../../../store/callcenter";
import ModalEditCustomFieldsConsumers from ".";
import { useTranslation } from "react-i18next";

const ModalEditCustomFieldsConsumersController = ({ show, handleClose, data, callBackModalCustomField }) => {

    const [validated] = useState(false);
    const [name, setName] = useState('');
    const [min, setMin] = useState<number>();
    const [max, setMax] = useState<number>();
    const [showFeedbackName, setShowFeedbackName] = useState(false);
    const [showFeedbackMin, setShowFeedbackMin] = useState(false);
    const [showFeedbackMax, setShowFeedbackMax] = useState(false);
    const [showFeedbackplaceholder, setShowFeedbackplaceholder] = useState(false);
    const [selectedType, setSelectedType] = useState([])
    const [selectedType1, setSelectedType1] = useState([])
    const [selectedOptions, setSelectedOptions] = useState([])
    const [selectedsOptions, setSelectedsOptions] = useState([])
    const [/*defaultValue*/, setDefaultValue] = useState([])
    const [labelledOptions, setLabelledOptions] = useState([])
    const [/*isLoading*/, setIsLoading] = useState(false)
    const [/*idType*/, setIdType] = useState("")
    const [placeholder, setPlaceholder] = useState("")
    const { t } = useTranslation()
    const [type1, /*setType1*/] = useState([
        { 'label': t('configurations_ticket.modal_register_custom_field.text_type'), 'value': t('configurations_ticket.modal_register_custom_field.text_type') },
        { 'label': t('configurations_ticket.modal_register_custom_field.number_type'), 'value': t('configurations_ticket.modal_register_custom_field.number_type') },
        { 'label': t('configurations_ticket.modal_register_custom_field.alphanumeric_type'), 'value': t('configurations_ticket.modal_register_custom_field.alphanumeric_type') },]);

    const zerarCampos = () => {
        setSelectedType([])
        setSelectedType1([])
        setSelectedsOptions([])
        setSelectedOptions([])
        setShowFeedbackName(false);
        handleClose()
        setShowFeedbackMin(false)
        setShowFeedbackMax(false)
    }

    const onChangeTags = (e: { detail: { tagify: { getCleanValue: Function; }; }; }) => {
        setSelectedOptions(e.detail.tagify.getCleanValue().map((tag) => {
            return tag.value;
        }));
    }
    useEffect(() => {
        if (selectedOptions?.length > 0) {
            setLabelledOptions(selectedOptions ? selectedOptions?.map((option, index) => {
                return ({ label: option, value: option })
            }) : [])
        }
    }, [selectedOptions])

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const AppRequesterConst = new AppRequesterController()

    const values: TokenInterface = {
        company: {
            id: useSelector(getIdCompany)
        },
        employee: {
            id: useSelector(getIdEmployee)
        },
        user: {
            id: useSelector(getIdUser),
        },
        token: {
            value: useSelector(getToken)
        },
        internal: {
            getShowConfirmationModal: useSelector(getShowConfirmationModal),
        },
        callcenter: {
            owner_id: useSelector(getOwner_id),
            buttonShowPhone: useSelector(getShowMeetButton)
        }
    };

    useEffect(() => {
        if (data != null) {
            // if (data.custom_field_type_id === CustomField.Text) {
            //     setSelectedType([{ label: Inputs[0].label, value: Inputs[0].label }])
            //     setIdType(CustomField.Text)
            // } else if (data.custom_field_type_id === CustomField.Textarea) {
            //     setSelectedType([{ label: Inputs[1].label, value: Inputs[1].label }])
            //     setIdType(CustomField.Textarea)
            // }
            // else if (data.custom_field_type_id === CustomField.Dropdown) {
            //     setSelectedType([{ label: Inputs[2].label, value: Inputs[2].label }])
            //     setIdType(CustomField.Dropdown)
            // }
            // else if (data.custom_field_type_id === CustomField.Multiselect) {
            //     setSelectedType([{ label: Inputs[3].label, value: Inputs[3].label }])
            //     setIdType(CustomField.Multiselect)
            // }

            if (data.custom_field_datatype_id === CustomField.Text1) {
                setSelectedType1([type1[0]])
                setIdType(data.custom_field_datatype_id)
            } else if (data.custom_field_type_id === CustomField.Number) {
                setSelectedType1([type1[1]])
                setIdType(data.custom_field_datatype_id)
            }
            else if (data.custom_field_datatype_id === CustomField.Alphanumeric) {
                setSelectedType1([type1[2]])
                setIdType(data.custom_field_datatype_id)
            }

            setSelectedOptions(data?.options)
            setSelectedsOptions([{ label: data?.default_value, value: data?.default_value }])
        }
    }, [data, handleClose])

    return <ModalEditCustomFieldsConsumers
        show={show}
        zerarCampos={zerarCampos}
        data={data}
        selectedOptions={selectedOptions}
        setName={setName}
        setPlaceholder={setPlaceholder}
        setSelectedOptions={setSelectedOptions}
        setDefaultValue={setDefaultValue}
        setShowFeedbackName={setShowFeedbackName}
        setShowFeedbackplaceholder={setShowFeedbackplaceholder}
        placeholder={placeholder}
        selectedsOptions={selectedsOptions}
        selectedType={selectedType}
        name={name}
        labelledOptions={labelledOptions}
        AppRequesterConst={AppRequesterConst}
        dispatch={dispatch}
        callBackModalCustomField={callBackModalCustomField}
        navigate={navigate}
        setIsLoading={setIsLoading}
        validated={validated}
        showFeedbackName={showFeedbackName}
        onChangeTags={onChangeTags}
        setLabelledOptions={setLabelledOptions}
        showFeedbackplaceholder={showFeedbackplaceholder}
        setSelectedsOptions={setSelectedsOptions}
        t={t}
        setShowFeedbackMin={setShowFeedbackMin}
        setShowFeedbackMax={setShowFeedbackMax}
        selectedType1={selectedType1}
        showFeedbackMin={showFeedbackMin}
        showFeedbackMax={showFeedbackMax}
        min={min}
        max={max}
        setMin={setMin}
        setMax={setMax}
    />
}

export default ModalEditCustomFieldsConsumersController;