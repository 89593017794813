import { Button, Col, Container, OverlayTrigger, Row, Tab, Tabs, Tooltip } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import Loading from '../../../../components/loading';
import constsApi from '../../../../services/constsApi';
import { getShowCreateDepartmentModal, getShowAcquirePlatformComponent, getShowCallNotificationBar } from '../../../../store/internal';
import AcquirePlatformNotificationBar from '../../components/acquirePlatformNotificationBar';
import ButtonSearchCleanInput from '../../components/buttonSearchCleanInputComponent';
import CallNotificationBar from '../../components/callNotificationBar';
import ModalRegisterDepartmentsController from '../../components/modalRegisterDepartments/indexController';
import ListOfDepartmentsController from './listOfDepartments/indexController';
import TriageTabController from './triageTab/indexController';
import ScheduleServiceTabController from './scheduleServiceTab/indexController';
import IntegrationTabController from './integrationTab/indexController';
import ButtonStatusAgent from '../../components/buttonStatusAgent';
import ButtonListNotificationController from '../../../../components/buttonListNotification/indexController';
import SignatureTabController from './signatureTab/indexController';
import TemplateMessageController from './templateMessageTab/indexController';

const HomeDepartments = ({
  t,
  isLoading,
  callbackModalDepartment,
  currentParams,
  getAllDepartments,
  search,
  setCurrentFilterSearch,
  currentFilterSearch,
  values,
  showCreateDepartment,
  currentDepartments,
  departmentsKeywords,
  updateListFunction,
  updateListAfterUpdateDepartment,
  currentHasMoreInformation,
  callbackOrderByDepartments,
  setIsLoading,
  eventKey,
  setEventKey,
  setCurrentState,
}) => {
  return (
    <>
      <Container fluid className="nopadding">
        <Loading visibility={isLoading}></Loading>
        <ModalRegisterDepartmentsController visibility={useSelector(getShowCreateDepartmentModal)} callback={() => callbackModalDepartment(currentParams, getAllDepartments)} />
        <div className="wrapper">
          {/* <SidebarController currentPage={SidebarButtonPage.Configurations} subPage={SidebarButtonPage.SubDepartments}></SidebarController> */}
          <div className="content pt-0 ps-0">
            <CallNotificationBar visibility={useSelector(getShowCallNotificationBar)}></CallNotificationBar>
            <AcquirePlatformNotificationBar visibility={useSelector(getShowAcquirePlatformComponent).visibility} limitDays={useSelector(getShowAcquirePlatformComponent).limitDays} />
            <Row className="nopadding mt-3 ps-3">
              <Col md={7} style={{ marginRight: '-8vw', marginBottom: '2vh' }}>
                <Row>
                  <Col className="d-flex justify-content-start align-self-start">
                    <div style={{ font: 'var(--px20_24_Bold-font)' }}>{t('home_departments.general.welcome_main')}</div>
                  </Col>
                </Row>
                <Row className='mt-2'>
                  <div style={{ font: 'var(--px15_19-font)', color: '#707070' }}>{t('home_departments.general.welcome_sub')}</div>
                </Row>
              </Col>
              <Col className="d-flex justify-content-end">
                { eventKey === 'departments' &&
                  <ButtonSearchCleanInput inputPlaceholder={t('home_departments.general.search_department')} filteringTickets={(e, auxObj, action) => search(e, auxObj, action)} setCurrentFilterSearch={setCurrentFilterSearch} currentFilterSearch={currentFilterSearch} setCurrentState={setCurrentState} />
                }
                <ButtonListNotificationController />
                <ButtonStatusAgent/>
              </Col>
            </Row>

            <Row className="mx-4">
              <Tabs defaultActiveKey={"departments"} id="departmentsTabsNavLinkId" style={{ padding: 0, margin: '3vh 0px 0px 0px' }} onSelect={(key) => setEventKey(key)}>
                <Tab eventKey={"departments"} title={t('home_departments.general.company_departments')} >
                  <Row id="buttonCreateDepartment" style={{ marginTop: '4vh', padding: 0 }}>
                    <Col md={3} >
                      <p style={{ color: '#0C2461', font: 'normal normal bold min(max(10px, calc(0.2em + 0.7vw)), 19px) Montserrat' }}>
                        {t('home_departments.general.departments')}
                        {
                          ((values.user.profile_id === constsApi.profile[0].id) || (values.user.profile_id === constsApi.profile[2].id) || (values.user.profile_id === constsApi.profile[3].id)) &&
                          <OverlayTrigger
                            key='top'
                            placement='top'
                            overlay={
                              <Tooltip id='tooltip' className='tooltip-arrow-Top'>
                                {t('home_departments.general.create_department')}
                              </Tooltip>
                            }
                          >

                            <Button className="mx-3 nopadding buttonTicketsSec" onClick={() => showCreateDepartment()}>
                              <svg id="Grupo_11079" data-name="Grupo 11079" xmlns="http://www.w3.org/2000/svg" width="50%" viewBox="0 0 24 24">
                                <path id="Caminho_9572" data-name="Caminho 9572" d="M0,0H24V24H0Z" fill="none" />
                                <line id="Linha_147" data-name="Linha 147" y2="14" transform="translate(12 5)" fill="none" stroke="#0c2461" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                <line id="Linha_148" data-name="Linha 148" x2="14" transform="translate(5 12)" fill="none" stroke="#0c2461" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                              </svg>
                            </Button>

                          </OverlayTrigger>
                        }
                      </p>
                    </Col>
                  </Row>
                  <ListOfDepartmentsController
                    datas={currentDepartments}
                    departmentsKeywords={departmentsKeywords}
                    updateListfunction={() => updateListFunction()}
                    updateListAfterUpdateDepartment={() => updateListAfterUpdateDepartment()}
                    HasMoreInformation={currentHasMoreInformation}
                    showCreateDepartment={() => showCreateDepartment()}
                    callbackOrderByDepartments={(column, order) => callbackOrderByDepartments(column, order)}
                    setIsLoading={setIsLoading}
                    callbackModalDepartment={() => callbackModalDepartment(currentParams, getAllDepartments)}
                  />
                </Tab>
                <Tab eventKey={"schedule"} title={t('home_departments.general.schedule')} >
                  <ScheduleServiceTabController isLoading={isLoading} setIsLoading={setIsLoading} />
                </Tab>
                <Tab eventKey={"template_message"} title={t('home_departments.general.template_message_tab')}>
                  <TemplateMessageController isLoading={isLoading} setIsLoading={setIsLoading} />
                </Tab>
                <Tab eventKey={"triage"} title={t('home_departments.general.triage_tab')}>
                  <TriageTabController />
                </Tab>
                <Tab eventKey={"integration"} title={t('home_departments.general.integration')} >
                  <IntegrationTabController />
                </Tab>
                <Tab eventKey={"signature"} title={t('configurations_ticket.general.signature')}>
                  <SignatureTabController />
                </Tab> 
              </Tabs>
            </Row>
          </div>
        </div>
      </Container >
    </>
  );
}


export default HomeDepartments;
