type CSSProperties = {
    [key: string]: React.CSSProperties;
};

export class StyleSheet {
    static create<Styles extends CSSProperties>(styles: Styles): Styles {
        return styles;
    };
};

const allStyles = StyleSheet.create({
    textFilesZone: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: '1',
        minHeight: '9vh',
    },
    changeEditorDropzone: { 
        width: '100%', 
        display: 'flex', 
        overflowY: 'hidden',
        height: '100%'
    },
    showAllFiles: { 
        overflowY: 'auto', 
        width: '100%', 
        minHeight: '45px',
        maxHeight: '16vh',
    },
    dragDropzone: {
        width: '100%',
        height: '9vh',
        backgroundColor: 'white',
        border: '0.13rem dashed #4A69BD',
        borderRadius: '4px',
        cursor: 'pointer',
        transition: 'height 0.2s ease',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflowY: 'hidden',
        marginLeft: '12px'
    },
});

export default allStyles;