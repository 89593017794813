import React from 'react'
import Loading from '../../../../components/loading';
import { Button, Col, Form, ListGroup, OverlayTrigger, Row } from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroll-component';
import Utils from '../../../../core/shared/utils';
import { ListName } from '../../../../core/enums/order-sort-by';

const ListBlockedEmails = ({
    popover,
    // allCheckboxesClick,
    // checkboxClick,
    data,
    isLoading,
    updateListfunction,
    hasMoreInformation,
    setCurrentEmailInfo,
    convertTimestamp,
}) => {

    return (
        <>
            <Loading customStyle={{height: '50%'}} visibility={isLoading}></Loading>
            <div style={{ marginTop: '2vh' }} className="ps-3">
                <ListGroup variant="flush">
                    <ListGroup.Item className="TicketsRowCollums">
                        <Row name="ListOfAgentsHead">
                            {/* <Col className='nopadding' md={1} style={{ width: '3%' }}>
                                <Form.Check
                                    type='checkbox'
                                    id='Allcheckboxes'
                                    onClick={(e) => { allCheckboxesClick(e) }}
                                />
                            </Col> */}
                            <Col md={5} className='nopadding'>
                                <p>E-mail</p>
                            </Col>
                            <Col md={4} className="nopadding">
                                <p>Data</p>
                            </Col>
                            <Col md={3} className="d-flex justify-content-end" style={{ width: '25%' }}>
                                <p>Ações</p>
                            </Col>
                        </Row>
                    </ListGroup.Item>
                </ListGroup>
                <div id="ListOfBlockEmails" style={{ overflow: 'auto', height: '50vh' }}>
                {
                    data.length > 0 ?
                            <InfiniteScroll
                                dataLength={data.length}
                                next={updateListfunction}
                                hasMore={hasMoreInformation as boolean}
                                loader={<h4> </h4>}
                                scrollableTarget={"ListOfBlockEmails"}
                                onScroll={() => { Utils.hidePopoverOnScroll(ListName.ListOfBlockEmails) }}
                            >
                                {
                                    <ListGroup variant="flush" className="TicketsRow" >
                                        {
                                            data.map((item, index) => (
                                                <ListGroup.Item id={"*EMAIL*" + item.email} key={index}> {/* verificar se passa o email ou o id */}
                                                    <Row className='TicketsRowHeight'>
                                                        {/* <Col className='align-self-center nopadding' md={1} style={{ width: '3%' }}>
                                                            <Form.Check
                                                                type='checkbox'
                                                                id={"checkboxesAll" + index}
                                                                onClick={(e) => checkboxClick(e)}
                                                            />
                                                        </Col> */}
                                                        <Col md={5} className='align-self-center nopadding'>
                                                            <div>
                                                                <span>
                                                                    {item.email}
                                                                </span>
                                                            </div>
                                                        </Col>
                                                        <Col md={4} className=' align-self-center nopadding'>
                                                            <span>
                                                                {convertTimestamp(item.created)}
                                                            </span>
                                                        </Col>
                                                        <Col md={3} className="d-flex justify-content-end" style={{ width: '25%' }}>
                                                            <OverlayTrigger rootClose={true} trigger={['click']} placement="left" overlay={popover}>
                                                                <Button id={"*EMAIL*" + item.email} onClick={(e) => setCurrentEmailInfo(e)} className='nopadding d-flex justify-content-start align-self-center' style={{ marginTop: '', backgroundColor: 'transparent', border: 'none', width: "24px" }}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="90%" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#6A6A6A" fill="#6A6A6A" strokeLinecap="round" strokeLinejoin="round">
                                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                        <circle cx="12" cy="12" r="1" />
                                                                        <circle cx="12" cy="19" r="1" />
                                                                        <circle cx="12" cy="5" r="1" />
                                                                    </svg>
                                                                </Button>
                                                            </OverlayTrigger>
                                                        </Col>
                                                    </Row>
                                                </ListGroup.Item>
                                            ))}
                                    </ListGroup>
                                }
                            </InfiniteScroll>
                        :
                        <>
                        </>

                    }
                </div>

            </div>
        </>
    )
}

export default ListBlockedEmails;