import React from 'react'
import { Col, Container, ListGroup, Row } from 'react-bootstrap';
import FilterOptionComponentController from '../components/filterOptionComponent/indexController';
import ListBlockedEmailsController from './ListBlockedEmails/indexController';
import Loading from '../../../components/loading';

const ViewBlockedEmails = ({
    setCurrentFilterSearch,
    currentFilterSearch,
    setCurrentState,
    currentState,
    setCurrentPage,
    setAux,
    setCurrentParams,
    filteringTickets,
    cleanList,
    currentParams,
    currentPage,
    aux,
    data,
    isLoading,
    updateListfunction,
    hasMoreInformation,
    updateListAfterDelete
}) => {
  return (
    <Container fluid  style={{background:"white", borderRadius: 3}}>
        <Loading customStyle={{height: '80%'}} visibility={isLoading}></Loading>
        <Row>
            <Col style={{
                textAlign: 'left',
                font: 'normal normal bold calc(0.2em + 1vw) Montserrat',
                letterSpacing: '0px',
                color: '#0C2461',
                opacity: '1',
                display: 'flex',
                alignItems: 'center',
            }}
                md={5}
                className="mt-2"
            >
                <div style={{ paddingRight: "20px", color: "black" }}>
                    E-mails bloqueados
                </div>
            </Col>
        </Row>
        
        <div style={{ backgroundColor: '#707070', opacity: '0.5', height: '0.1vh', width: '100%', marginTop: '2px' }}></div>
          
        <Row style={{ marginTop: '15px' }}>
              <Col className='FilterTicketsRow'>
                    <div style={{ backgroundColor: 'rgba(207, 227, 255,0.25)', padding: '0.6%' }}>
                        <FilterOptionComponentController
                            optionNames={["Email", "Data"]}
                            currentState={currentState}
                            setCurrentPage={setCurrentPage}
                            setCurrentState={setCurrentState}
                            setCurrentFilterSearch={setCurrentFilterSearch}
                            setCurrentParams={setCurrentParams}
                            currentFilterSearch={currentFilterSearch}
                            setAux={setAux}
                            filteringTickets={filteringTickets}
                            cleanList={cleanList}
                        />
                    </div>
              </Col>
        </Row>

        <Row>
            <ListBlockedEmailsController data={data} /* isLoading={isLoading} */ updateListfunction={updateListfunction} hasMoreInformation={hasMoreInformation} updateListAfterDelete={updateListAfterDelete} />
        </Row>

    </Container>
  )
}

export default ViewBlockedEmails;