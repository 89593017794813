import {
    Row,
    Col,
    Button,
    Form,
    InputGroup,
    FormControl,
    OverlayTrigger,
    Tooltip,
} from "react-bootstrap"
import allStyles from "./styles"
import { AppRequesterController } from '../../../../services/appRequester/appRequesterController'
import { Formik } from "formik"
import SelectComponent from "../selectComponent"
import { setShowAlertFeedback } from "../../../../store/internal"


const AppRequesterConst = new AppRequesterController()

const FormConfigContract = ({
    setSelectedStatus,
    selectedStatus,
    setOptionsStatus,
    optionsStatus,
    clickEmail,
    setClickEmail,
    clickFacebook,
    setClickFacebook,
    clickInstagram,
    setClickInstagram,
    clickWhatsApp,
    setClickWhatsApp,
    clickTelefonia,
    setClickTelefonia,
    qtdAgentes,
    setQtdAgentes,
    setCustomFildsConsumer,
    customFildsConsumer,
    setCustomFildsTicket,
    customFildsTicket,
    setStatusCompany,
    statusCompany,
    setClickWebchat,
    clickWebchat,
    showfeedbackAgents,
    setShowFeedbackAgents,
    showfeedbackTicket,
    setShowFeedbackTicket,
    setShowFeedbackConsumers,
    showfeedbackConsumers,
    acessToken,
    dispatch,
    navigate,
    setIsLoading,
    id,
    company_id,
    setChannels,
    channels,
    getCompanyById,
    isEditAllowed,
    setStatusIcon,
    showfeedbackLimit,
    setShowFeedbackLimit,
    showSumary,
    setShowSumary,
    limitMessage,
    setLimitMessage
}) => {
    return (
        <>
            <Row className="mt-1 noppadding" style={{ display: "flex", alignItems: "center" }}>
                <Col style={{
                    textAlign: 'left',
                    font: 'normal normal bold calc(0.2em + 1vw) Montserrat',
                    letterSpacing: '0px',
                    color: '#0C2461',
                    opacity: '1',
                    display: 'flex',
                    alignItems: 'center',
                }}
                    md={5}
                    className="mt-2"
                >
                    <div style={{ paddingRight: "20px", color: "black" }}>
                        Configurações do Contrato
                    </div>
                </Col>
            </Row>
            <div style={{ backgroundColor: '#707070', opacity: '0.5', height: '0.1vh', width: '100%', marginTop: '2px' }}></div>
            <Formik
                enableReinitialize={true}
                
                initialValues={{ qtdAgentes: qtdAgentes, customFildsConsumer: customFildsConsumer, customFildsTicket: customFildsTicket, limitMessage: limitMessage }}
                validate={values => {
                    let errors = {};
                    setQtdAgentes(values?.qtdAgentes);
                    setCustomFildsConsumer(values?.customFildsConsumer)
                    setCustomFildsTicket(values?.customFildsTicket)
                    setLimitMessage(values?.limitMessage)
                    if (!values.qtdAgentes) {
                        errors["qtdAgentes"] = 'Preencha este campo';
                        setShowFeedbackAgents(true);
                    } else {
                        setShowFeedbackAgents(false);
                    }

                    if (!values.customFildsTicket) {
                        errors["customFildsTicket"] = 'Preencha este campo';
                        setShowFeedbackTicket(true);
                    } else {
                        setShowFeedbackTicket(false);
                    }

                    if (!values.customFildsConsumer) {
                        errors["customFildsConsumer"] = 'Preencha este campo';
                        setShowFeedbackConsumers(true);
                    } else {
                        setShowFeedbackConsumers(false);
                    }

                    if(values.limitMessage < 500) { /* verifica se ele está abaixo do minimo esperado */
                        setLimitMessage(500);                    
                    }

                    return errors;
                }}
                onSubmit={() => {
                    if (isEditAllowed) {
                        const JsonSend = {
                            "id": id,
                            "company_id": company_id,
                            "quantity_agents": qtdAgentes,
                            "consumer_custom_fields_limit": customFildsConsumer,
                            "ticket_custom_fields_limit": customFildsTicket,
                            "contract_status_id": selectedStatus[0].value,
                            "channels": channels,
                            "summary_volume": limitMessage,
                            "enable_summary": showSumary
                        };
    
                        const headers = {
                            "Content-Type": "application/json; charset=utf-8",
                            "Authorization": "Bearer " + acessToken
                        };
    
                        AppRequesterConst.Post(
                            `/contract`,
                            JsonSend,
                            { headers: headers },
                            (response: Object) => {
                                return response;
                            },
                            (data) => {
                                if (data.data.message === "Requisição atendida com sucesso.") {
                                    setShowFeedbackConsumers(false);
                                    setShowFeedbackTicket(false);
                                    setShowFeedbackAgents(false);
                                    setShowFeedbackLimit(false);
                                    setStatusIcon(selectedStatus[0].value)
                                    dispatch(setShowAlertFeedback({ message: `contrato atualizado com sucesso!`, visibility: true, signalIcon: true }))
                                    getCompanyById()
                                }
    
                            },
                            (error: Object) => {
                                dispatch(setShowAlertFeedback({ message: `Ocorreu um erro inesperado, tente novamente mais tarde.`, visibility: true, signalIcon: false }))
                            },
                            navigate,
                            dispatch,
                            setIsLoading
                        );
                    }
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                }) => (
                    <Form onSubmit={handleSubmit} noValidate validated={false}>
                        <Row>
                            <Col md={6}>
                                <Form.Label htmlFor="name" style={{ marginTop: '1.5rem', color: '#707070' }}>
                                    Status da empresa
                                </Form.Label>
                                <SelectComponent
                                    className={"multiselectForm mb-3"}
                                    options={optionsStatus}
                                    disableSearch={true}
                                    setOptions={setOptionsStatus}
                                    selecteds={selectedStatus}
                                    setSelecteds={setSelectedStatus}
                                    singleSelect={true}
                                    title={"Selecione a Account"}
                                    overrideStrings={
                                        {
                                            "noOptions": "Sem Agentes",
                                            "search": "Pesquisar Agente",
                                            "selectAll": "Selecionar todas os Agente",
                                            "selectAllFiltered": "Selecionar todos os Agentes (Filtrado)",
                                            "selectSomeItems": "Selecione o status da empresa",
                                            "create": "Criar",
                                        }
                                    }
                                    hasCreateItem={false}
                                    hasNullOption={false}
                                    // filtering={false}
                                    hasAllOption={false}
                                    disabled={!isEditAllowed}
                                />
                            </Col>
                            <Col md={6}>
                                <Form.Label htmlFor="qtdAgentes" style={{ marginTop: '1.5rem', color: '#707070' }}>
                                    Quantidade de agentes
                                </Form.Label>
                                <InputGroup>
                                    <FormControl
                                        className="form-control-Default"
                                        isInvalid={showfeedbackAgents}
                                        placeholder="Informe a quantidade de agentes"
                                        aria-label="Informe a quantidade de agentes"
                                        aria-describedby="Informe a quantidade de agentes"
                                        size="lg"
                                        type="number"
                                        id="qtdAgentes"
                                        name="qtdAgentes"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.qtdAgentes}
                                        disabled={!isEditAllowed}
                                    ></FormControl>
                                    <Form.Control.Feedback type="invalid" id="qtdAgentes">
                                        {errors['qtdAgentes']}
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <Form.Label htmlFor="customFildsTicket" style={{ marginTop: '1.5rem', color: '#707070' }}>
                                    Campos customizados de ticket
                                </Form.Label>
                                <InputGroup>
                                    <FormControl
                                        className="form-control-Default"
                                        isInvalid={showfeedbackTicket}
                                        placeholder="Informe a quantidade de campos customizados do ticket"
                                        aria-label="Informe a quantidade de campos customizados do ticket"
                                        aria-describedby="Informe a quantidade de campos customizados do ticket"
                                        size="lg"
                                        type="number"
                                        id="customFildsTicket"
                                        name="customFildsTicket"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values?.customFildsTicket}
                                        disabled={!isEditAllowed}
                                    ></FormControl>
                                    <Form.Control.Feedback type="invalid" id="feedbackticket">
                                        {errors['customFildsTicket']}
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Col>
                            <Col md={6}>
                                <Form.Label htmlFor="customFildsConsumer" style={{ marginTop: '1.5rem', color: '#707070' }}>
                                    Campos customizados de consumidor
                                </Form.Label>
                                <InputGroup>
                                    <FormControl
                                        className="form-control-Default"
                                        isInvalid={showfeedbackConsumers}
                                        placeholder="Informe a quantidade de campos customizados do consumidor"
                                        aria-label="Informe a quantidade de campos customizados do consumidor"
                                        aria-describedby="Informe a quantidade de campos customizados do consumidor"
                                        size="lg"
                                        type="number"
                                        id="customFildsConsumer"
                                        name="customFildsConsumer"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values?.customFildsConsumer}
                                        disabled={!isEditAllowed}
                                    ></FormControl>
                                    <Form.Control.Feedback type="invalid" id="feedbackConsumer">
                                        {errors['customFildsConsumer']}
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row style={{ marginBottom: '2rem' }}>
                            <Col md={6}>
                                <div className="switch-input" style={{ marginTop: '1.5rem' }}>
                                    <Form.Label htmlFor="sumary" style={{ color: '#707070' }}>
                                        Sumarização de tickets
                                    </Form.Label>
                                    <OverlayTrigger
                                        key='top'
                                        placement='top'
                                        overlay={
                                            <Tooltip id='tooltip'>
                                                Permite desativar a sumarização dos tickets caso a empresa tenha ultrapassado um certo valor na OpenAI ou por outra determinação da Elife.
                                            </Tooltip>
                                        }
                                    >
                                        <svg id="informação" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="me-3">
                                            <path id="Caminho_10010" data-name="Caminho 10010" d="M0,0H24V24H0Z" fill="none" />
                                            <circle id="Elipse_1556" data-name="Elipse 1556" cx="9" cy="9" r="9" transform="translate(3 3)" fill="none" stroke="#2e3039" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                            <line id="Linha_604" data-name="Linha 604" x2="0.01" transform="translate(12 8)" fill="none" stroke="#2e3039" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                            <path id="Caminho_10011" data-name="Caminho 10011" d="M11,12h1v4h1" fill="none" stroke="#2e3039" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                        </svg>
                                    </OverlayTrigger>

                                    <OverlayTrigger
                                        key='top'
                                        placement='right'
                                        overlay={
                                            <Tooltip id='tooltip'>
                                                {showSumary ? 
                                                "Ativado" : "Desativado"}
                                            </Tooltip>
                                        }
                                        >
                                        <label className="switch" style={{ marginTop: '0.5rem', display: 'block' }}>
                                            <input
                                                type="checkbox"
                                                checked={showSumary}
                                                onChange={() => setShowSumary(!showSumary)}
                                            />
                                            <span className="slider round"></span>
                                        </label>
                                    </OverlayTrigger>
                                </div>
                            </Col>
                            <Col md={6}>
                                <Form.Label htmlFor="limitMessage" style={{ marginTop: '1.5rem', color: '#707070' }}>
                                    Quantidade mínima de mensagens para a sumarização
                                </Form.Label>
                                <div style={{ width: '100%', marginTop: '0.5rem', position: 'relative' }} className="d-flex">
                                    <label>500</label>
                                    <div style={{width: '100%', margin: '0 15px'}}>
                                        <OverlayTrigger
                                            key='top'
                                            placement='bottom'
                                            overlay={
                                                <Tooltip id='tooltip'>
                                                    {values.limitMessage}
                                                </Tooltip>
                                            }
                                        >
                                            <Form.Range min={500} max={100000} step={500} id="limitMessage" value={values.limitMessage} onChange={handleChange} />
                                        </OverlayTrigger>                                        
                                    </div>
                                    <label>100.000</label>
                                </div>

                            </Col>
                        </Row>
                        <Row className="mt-1 noppadding" style={{ display: "flex", alignItems: "center" }}>
                            <Col style={{
                                textAlign: 'left',
                                font: 'normal normal bold calc(0.2em + 1vw) Montserrat',
                                letterSpacing: '0px',
                                color: '#0C2461',
                                opacity: '1',
                                display: 'flex',
                                alignItems: 'center',
                            }}
                                md={5}
                                className="mt-2"
                            >
                                <div style={{ paddingRight: "20px", color: "black" }}>
                                    Canais
                                </div>
                            </Col>
                        </Row>
                        <div style={{ backgroundColor: '#707070', opacity: '0.5', height: '0.1vh', width: '100%', marginTop: '2px' }}></div>
                        <Row style={{ marginTop: '1rem', marginBottom: '4rem' }}>
                            <Col md={2}>
                                <>
                                    <div onClick={isEditAllowed ? () => setClickEmail(!clickEmail) : undefined} style={{
                                        color: clickEmail ? '#4A69BD' : '#707070',
                                        border: 'solid #E8E8E8 1px',
                                        borderRadius: '5px',
                                        font: 'normal normal calc(0.1em + 0.8vw) Montserrat',
                                        textAlign: 'center',
                                        padding: '0.5rem',
                                        cursor: "pointer",
                                        background: clickEmail ? '#E5ECFF' : '#FFFFFF'
                                    }}>
                                        Email
                                    </div>
                                </>
                            </Col>

                            <Col md={2}>
                                <>
                                    <div onClick={isEditAllowed ? () => setClickFacebook(!clickFacebook) : undefined} style={{
                                        color: clickFacebook ? '#4A69BD' : '#707070',
                                        border: 'solid #E8E8E8 1px',
                                        borderRadius: '5px',
                                        font: 'normal normal calc(0.1em + 0.8vw) Montserrat',
                                        textAlign: 'center',
                                        padding: '0.5rem',
                                        cursor: "pointer",
                                        background: clickFacebook ? '#E5ECFF' : '#FFFFFF'
                                    }}>
                                        Facebook
                                    </div>
                                </>
                            </Col>

                            <Col md={2}>
                                <>
                                    <div onClick={isEditAllowed ? () => setClickInstagram(!clickInstagram) : undefined} style={{
                                        color: clickInstagram ? '#4A69BD' : '#707070',
                                        border: 'solid #E8E8E8 1px',
                                        borderRadius: '5px',
                                        font: 'normal normal calc(0.1em + 0.8vw) Montserrat',
                                        textAlign: 'center',
                                        padding: '0.5rem',
                                        cursor: "pointer",
                                        background: clickInstagram ? '#E5ECFF' : '#FFFFFF'
                                    }}>
                                        Instagram
                                    </div>
                                </>
                            </Col>

                            <Col md={2}>
                                <>
                                    <div onClick={isEditAllowed ? () => setClickWhatsApp(!clickWhatsApp) : undefined} style={{
                                        color: clickWhatsApp ? '#4A69BD' : '#707070',
                                        border: 'solid #E8E8E8 1px',
                                        borderRadius: '5px',
                                        font: 'normal normal calc(0.1em + 0.8vw) Montserrat',
                                        textAlign: 'center',
                                        padding: '0.5rem',
                                        cursor: "pointer",
                                        background: clickWhatsApp ? '#E5ECFF' : '#FFFFFF'
                                    }}>
                                        WhatsApp
                                    </div>
                                </>
                            </Col>

                            <Col md={2}>
                                <>
                                    <div onClick={isEditAllowed ? () => setClickTelefonia(!clickTelefonia) : undefined} style={{
                                        color: clickTelefonia ? '#4A69BD' : '#707070',
                                        border: 'solid #E8E8E8 1px',
                                        borderRadius: '5px',
                                        font: 'normal normal calc(0.1em + 0.8vw) Montserrat',
                                        textAlign: 'center',
                                        padding: '0.5rem',
                                        cursor: "pointer",
                                        background: clickTelefonia ? '#E5ECFF' : '#FFFFFF'
                                    }}>
                                        Telefonia
                                    </div>
                                </>
                            </Col>

                            <Col md={2}>
                                <>
                                    <div onClick={isEditAllowed ? () => setClickWebchat(!clickWebchat) : undefined} style={{
                                        color: clickTelefonia ? '#4A69BD' : '#707070',
                                        border: 'solid #E8E8E8 1px',
                                        borderRadius: '5px',
                                        font: 'normal normal calc(0.1em + 0.8vw) Montserrat',
                                        textAlign: 'center',
                                        padding: '0.5rem',
                                        cursor: "pointer",
                                        background: clickWebchat ? '#E5ECFF' : '#FFFFFF'
                                    }}>
                                        Webchat
                                    </div>
                                </>
                            </Col>
                        </Row>
                        {
                            isEditAllowed &&
                            <Row className="d-flex justify-content-start mt-4">
                                <Col xs={3} className="d-flex justify-content-start">
                                    <Button size='lg' type='submit' className="buttonBlue" style={{ width: '56%' }} disabled={!isEditAllowed}>Salvar</Button>
                                </Col>
                            </Row>
                        }
                    </Form>
                )}
            </Formik >
        </>
    )
}

export default FormConfigContract