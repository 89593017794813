import React from 'react';
import { Button, Col, Container, ListGroup, OverlayTrigger, Row, Tab, Tabs, Tooltip } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import Loading from '../../../components/loading';
import constsApi from '../../../services/constsApi';
import { getShowAcquirePlatformComponent, getShowCallNotificationBar, getShowCreateMessageTriggerModal, getShowCreateTriggerTypeModal } from '../../../store/internal';
import ButtonSearchCleanInput from '../components/buttonSearchCleanInputComponent';
import CallNotificationBar from '../components/callNotificationBar';
import ModalRegisterMessageTrigger from "../components/modalRegisterMessageTrigger";
import ModalRegisterTriggerType from "../components/modalRegisterTriggerType"
import AcquirePlatformNotificationBar from '../components/acquirePlatformNotificationBar';
import MeetCall from '../components/meetCall';
import { CurrentTriggerTabEnum } from './indexModel';
import './styles.css';
import ButtonStatusAgent from '../components/buttonStatusAgent';
import ButtonListNotificationController from '../../../components/buttonListNotification/indexController';
import FilterOptionComponentController from '../components/filterOptionComponent/indexController';
import ListOfTriggerTypesController from './listOfTriggerTypes/indexController';
import ListOfMessagesTriggersController from './listOfMessagesTriggers/indexController';
import { Channels } from '../../../core/enums/channels';

const HomeMessageTrigger = ({
    t,
    values,
    callbackModalMessageTrigger,
    getAllMessageTriggers,
    isLoading,
    getAllMessageTriggerTypesActive,
    getAllMessageTriggerTypes,
    currentActiveTriggerTypes,
    templateMessageList,
    emailList,
    emailPrincipal,
    currentTriggerTab,
    applyselectedTabFilterMethod,
    setCurrentFilterSearch,
    currentFilterSearch,
    phoneNumber,
    openModalPhone,
    setOpenModalPhone,
    handleCurrentTab,
    showCreateMessageTrigger,
    currentState,
    setCurrentState,
    cleanList,
    currentFilteredTriggers,
    updateListfunction,
    updateListAfterEditStatus,
    callbackOrderByMessageTriggers,
    showCreateTriggerType,
    currentFilteredTriggerTypes,
    callbackOrderByTypes,
    departmentOptions
}) => {

    return (
        <>
            <Loading visibility={isLoading}></Loading>
            <ModalRegisterMessageTrigger token={values.token.value} visibility={useSelector(getShowCreateMessageTriggerModal)} callback={() => callbackModalMessageTrigger(getAllMessageTriggers, getAllMessageTriggerTypesActive, getAllMessageTriggerTypes)} dataTriggerType={currentActiveTriggerTypes} templateMessageList={templateMessageList} emailList={emailList} emailPrincipal={emailPrincipal} departmentOptions={departmentOptions}></ModalRegisterMessageTrigger>
            <ModalRegisterTriggerType token={values.token.value} visibility={useSelector(getShowCreateTriggerTypeModal)} callback={() => callbackModalMessageTrigger(getAllMessageTriggers, getAllMessageTriggerTypesActive, getAllMessageTriggerTypes)}></ModalRegisterTriggerType>
            <Container fluid className="nopadding">
                <div className="wrapper">
                    {/* <SidebarController currentPage={SidebarButtonPage.MessageTriggers} className='nopadding'></SidebarController> */}
                    <div className="content py-0 ps-0">
                        <CallNotificationBar visibility={useSelector(getShowCallNotificationBar)}></CallNotificationBar>
                        <AcquirePlatformNotificationBar visibility={useSelector(getShowAcquirePlatformComponent).visibility} limitDays={useSelector(getShowAcquirePlatformComponent).limitDays} />
                        <Row className="nopadding mt-3 ps-4">
                            <Col md={7} style={{ marginBottom: '2vh' }}>
                                <Row>
                                    <Col className="d-flex justify-content-start align-self-start ps-0">
                                        <div style={{ font: 'var(--px20_24_Bold-font)' }}>{t("home_message_trigger.wellcome_message_trigger")}</div>
                                    </Col>
                                </Row>
                                <Row className="mt-2">
                                    <div className='ps-0' style={{ font: 'var(--px15_19-font)', color: '#707070', marginBottom: '0vh' }}>{t("home_message_trigger.wellcome_message_trigger_subtitle")}</div>
                                </Row>
                            </Col>
                            <Col className="d-flex justify-content-end">
                                <ButtonSearchCleanInput inputPlaceholder={currentTriggerTab === CurrentTriggerTabEnum.Trigger ? t("home_message_trigger.list_of_trigger_types.search_trigger") : t("home_message_trigger.list_of_trigger_types.search_trigger_type")} filteringTickets={(e, auxObj, action) => applyselectedTabFilterMethod(e, auxObj, action)} setCurrentFilterSearch={setCurrentFilterSearch} currentFilterSearch={currentFilterSearch} ></ButtonSearchCleanInput>
                                <ButtonListNotificationController/>
                                <ButtonStatusAgent/>
                            </Col>
                        </Row>
                        <Row className="ms-0 me-1 ps-4 ContentTabs">
                            <Tabs mountOnEnter={true} unmountOnExit={true} defaultActiveKey={CurrentTriggerTabEnum.Trigger} id="messagesTriggersTabs" style={{ padding: 0, margin: '3vh 0px 0px 0px' }} onSelect={(eventKey) => handleCurrentTab(eventKey)}>
                                <Tab eventKey={CurrentTriggerTabEnum.Trigger} title={t("home_message_trigger.list_of_messages_triggers.title")} className="ps-0 ms-0">
                                    <Row id="buttonCreateDepartment" style={{ marginTop: '4vh', padding: 0, marginRight: 0 }} >
                                        <Col md={3} /* style={{marginLeft: '1vw'}} */>
                                            <p style={{ color: '#0C2461', font: 'normal normal bold min(max(10px, calc(0.2em + 0.7vw)), 19px) Montserrat' }}>
                                                {t("home_message_trigger.messages_trigger.title")}
                                                {
                                                    ((values.user.profile_id === constsApi.profile[0].id) || (values.user.profile_id === constsApi.profile[2].id) || (values.user.profile_id === constsApi.profile[3].id)) &&
                                                    <OverlayTrigger
                                                        key='top'
                                                        placement='top'
                                                        overlay={
                                                            <Tooltip id='tooltip' className='tooltip-arrow-Top'>
                                                                {t("home_message_trigger.new_message_trigger")}
                                                            </Tooltip>
                                                        }
                                                    >

                                                        <Button className="mx-3 nopadding buttonTicketsSec" onClick={() => showCreateMessageTrigger()}>
                                                            <svg id="Grupo_11079" data-name="Grupo 11079" xmlns="http://www.w3.org/2000/svg" width="50%" viewBox="0 0 24 24">
                                                                <path id="Caminho_9572" data-name="Caminho 9572" d="M0,0H24V24H0Z" fill="none" />
                                                                <line id="Linha_147" data-name="Linha 147" y2="14" transform="translate(12 5)" fill="none" stroke="#0c2461" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                                <line id="Linha_148" data-name="Linha 148" x2="14" transform="translate(5 12)" fill="none" stroke="#0c2461" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                            </svg>
                                                        </Button>

                                                    </OverlayTrigger>
                                                }
                                            </p>
                                        </Col>
                                    </Row>
                                    <div className='FilterTicketsRow' style={{ marginRight: '10px' }}>
                                        <ListGroup variant="flush" >
                                            <ListGroup.Item style={{ backgroundColor: 'rgba(207, 227, 255,0.25)', paddingLeft: '0vw' }}>
                                                <FilterOptionComponentController
                                                    optionNames={["Tipo", "Canal", "Responsável", "TriggerStatus", "Data"]}
                                                    limitedOptionNameChannel={[{ label: t('filter_option.channel_email'), value: Channels.Email }, { label: "WhatsApp", value: Channels.WhatsApp }]}
                                                    currentState={currentState}
                                                    setCurrentState={setCurrentState}
                                                    values_user={values.user}
                                                    values_internal_getShowEditTicketsModal={false}
                                                    values_internal_getShowCreateTicketsModal={false}
                                                    filteringTickets={(e, auxObj, action) => applyselectedTabFilterMethod(e, auxObj, action)}
                                                    cleanList={() => cleanList()}
                                                    values_token_value={values.token.value}
                                                    yoursTickets={false}
                                                    setYoursTickets={() => { }}
                                                    screen={'home_message_trigger'}
                                                />
                                            </ListGroup.Item>
                                        </ListGroup>
                                    </div>
                                    <ListOfMessagesTriggersController
                                        emailPrincipal={emailPrincipal}
                                        emailList={emailList}
                                        datas={currentFilteredTriggers}
                                        updateListfunction={() => updateListfunction()}
                                        showCreateTrigger={() => showCreateMessageTrigger()}
                                        setCurrentTriggerFunction={() => { }}
                                        updateListAfterEditStatus={() => updateListAfterEditStatus()}
                                        actionsActiveColumn={true}
                                        dataTriggerType={currentActiveTriggerTypes}
                                        templateMessageList={templateMessageList}
                                        callback={() => callbackModalMessageTrigger(getAllMessageTriggers, getAllMessageTriggerTypesActive, getAllMessageTriggerTypes)}
                                        callbackOrderByMessageTriggers={(column, order) => callbackOrderByMessageTriggers(column, order)}
                                        departmentOptions={departmentOptions}
                                    />
                                </Tab>

                                <Tab eventKey={CurrentTriggerTabEnum.TriggerType} title={t("home_message_trigger.trigger_type_lower_case")} >
                                    <Row id="buttonCreateDepartment" style={{ marginTop: '4vh', padding: 0, marginRight: 0 }}>
                                        <Col md={3} /* style={{marginLeft: '1vw'}} */>
                                            <p style={{ color: '#0C2461', font: 'normal normal bold min(max(10px, calc(0.2em + 0.7vw)), 19px) Montserrat' }}>
                                                {t('home_message_trigger.trigger_type_upper_case')}
                                                {
                                                    ((values.user.profile_id === constsApi.profile[0].id) || (values.user.profile_id === constsApi.profile[2].id) || (values.user.profile_id === constsApi.profile[3].id)) &&
                                                    <OverlayTrigger
                                                        key='top2'
                                                        placement='top'
                                                        overlay={
                                                            <Tooltip id='tooltip' className='tooltip-arrow-Top'>
                                                                {t("home_message_trigger.new_message_trigger_type")}
                                                            </Tooltip>
                                                        }
                                                    >

                                                        <Button className="mx-3 nopadding buttonTicketsSec" onClick={() => showCreateTriggerType()}>
                                                            <svg id="Grupo_11079" data-name="Grupo 11079" xmlns="http://www.w3.org/2000/svg" width="50%" viewBox="0 0 24 24">
                                                                <path id="Caminho_9572" data-name="Caminho 9572" d="M0,0H24V24H0Z" fill="none" />
                                                                <line id="Linha_147" data-name="Linha 147" y2="14" transform="translate(12 5)" fill="none" stroke="#0c2461" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                                <line id="Linha_148" data-name="Linha 148" x2="14" transform="translate(5 12)" fill="none" stroke="#0c2461" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                                            </svg>
                                                        </Button>
                                                    </OverlayTrigger>
                                                }
                                            </p>
                                        </Col>
                                    </Row>
                                    <ListOfTriggerTypesController
                                        callback={() => callbackModalMessageTrigger(getAllMessageTriggers, getAllMessageTriggerTypesActive, getAllMessageTriggerTypes)}
                                        datas={currentFilteredTriggerTypes}
                                        updateListfunction={() => updateListfunction()}
                                        callbackOrderBy={(column, order) => callbackOrderByTypes(column, order)}
                                        updateListAfterEditStatus={() => updateListAfterEditStatus()}
                                        showCreateTriggerType={() => showCreateTriggerType()}
                                        setCurrentTriggerTypeFunction={() => { }}
                                        actionsActiveColumn={true}
                                    />
                                </Tab>
                            </Tabs>
                        </Row>
                    </div>
                </div>
            </Container>
        </>
    );
}

export default HomeMessageTrigger;