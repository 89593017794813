import { useCallback, useEffect, useState } from "react"
import { AppRequesterController } from "../services/appRequester/appRequesterController"
import UserService from "../services/user-service"
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { setShowAlertFeedback } from "../store/internal"
import { verifyCode } from "../services/codeCxpressInterface"
import { useTranslation } from "react-i18next"
import { TokenInterface } from "../services/requestsInterfacesModel"
import { getToken } from "../store/token"

const AppRequesterConst = new AppRequesterController()

export interface AgentsInterface {
    company_id: string,
    created_at: string,
    deleted_at: string,
    id: string,
    name: string,
    updated_at: string,
    user_id: string
}

export interface SectorInterface {
    active: boolean,
    agents: Array<AgentsInterface>,
    keyword: string,
    sector_id: string,
    sector_name: string
}

export interface DepartmentInterface {
    code_cxpress: number,
    sectors: Array<SectorInterface>
}

const useDepartment = () => {
    const headers = UserService.getHeaders()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const [loading, setIsLoading] = useState(false)
    const [department, setDepartment] = useState<DepartmentInterface>()

    const values: TokenInterface = {
        token: {
            value: useSelector(getToken)
        }
    }

    const fetchDepartment = useCallback(async () => {
        await AppRequesterConst.Get(
            '/sector', { headers },
            (response: Object) => { },
            (data) => {
                if(data.data != undefined && data.status === 200) {
                    setDepartment(data.data)
                }
            },
            (error: { response: { status: number; data: { code_cxpress: number } } }) => {
                if (error?.response?.status === 401) {
                    dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }))
                } else if (error?.response?.data) {
                    dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }))
                }
            }, navigate, dispatch, setIsLoading, { values }
    
        )
    }, [])

    useEffect(() => {
        fetchDepartment()
    }, [fetchDepartment])

    return { department, loading, refetchDepartment: fetchDepartment }

}

export default useDepartment