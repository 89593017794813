import { Col, Container, Nav, Row, TabContent, TabPane } from "react-bootstrap";
import styles from "./styles"
import ViewBlockedEmailsController from "../viewBlockedEmails/indexController";
import ViewEmailActivityController from "../ViewEmailActivity/indexController";

const HomeControlPanelConfig = ({
    handleTabChange,
    activeTab,
    opcoesMenu
}) => {
    return (
        <Container fluid className="nopadding">
            <div style={{ padding: '0.2vh 1vh 1vh 2vh', margin: '1vw' }}>
                <Row>
                    <Col className="d-flex justify-content-start align-self-start ps-0">
                        <div style={{ font: 'var(--px20_24_Bold-font)' }}>Configurações</div>
                    </Col>
                </Row>
                <Row className='mt-2'>
                    <div className='ps-0' style={{ font: 'var(--px15_19-font)', color: '#707070' }}>Visualize as configurações gerais da aplicação</div>
                </Row>
            </div>
            <div style={{ backgroundColor: '#F9F9F9', padding: '2vh', margin: '1vw', borderRadius: 3 }}>
                <div style={{ marginRight: '-1vw' }}>
                    <Row className="nopadding ps-0">
                        <Col md={2} style={{ marginTop: '0vh', marginLeft: '-0.5vw', minWidth: '200px' }}>
                            <Nav variant="pills" className="d-flex justify-content-between" style={{ backgroundColor: 'white', padding: '0.4vh', borderRadius: 3 }}>
                                {
                                    opcoesMenu.map((item, index) => (
                                        <div onClick={() => handleTabChange(item.tabName)} style={activeTab === item.tabName ? styles.navItemActive : styles.navItem} className="m-1">
                                            {activeTab === item.tabName ? item.iconActive: item.icon}
                                            <span style={activeTab === item.tabName ? styles.spanActive : styles.span} key={index}>{item.title}</span>
                                        </div>
                                    ))
                                }
                            </Nav>
                        </Col>

                        <Col style={{ paddingLeft: 0 }}>
                            <TabContent>
                                <TabPane eventKey={activeTab} className={"show active"}>
                                    {
                                        activeTab === "blocked-emails" &&
                                        <ViewBlockedEmailsController/>
                                    }
                                    {
                                        activeTab === "activity" &&
                                        <ViewEmailActivityController/>
                                    }
                                </TabPane>
                            </TabContent>
                        </Col>
                    </Row>
                </div>
            </div>
        </Container>
    )
}

export default HomeControlPanelConfig;