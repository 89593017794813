import { FC, useEffect, useState } from "react";
import ModalVisualizeTemplateMessage from ".";
import { useDispatch, useSelector } from "react-redux";
import { TokenInterface } from "../../../../../../services/requestsInterfacesModel";
import { getShowCreateTemplateMessage, getShowTemplateTagModal, setShowAlertFeedback, setShowCreateTemplateMessage, setShowTemplateTagModal } from "../../../../../../store/internal";
import { AppRequesterControlPanelController } from "../../../../../../services/appRequesterControlPanel/appRequesterControlPanelController";
import { useNavigate } from "react-router-dom";
import { getToken } from "../../../../../../store/token";
import { FormValues } from "../../../../../homeControlPanel/components/formConfigWhatsapp/formConfigTemplateMessages/formOfModalsTemplateMessage/indexModel";
import { IModalTemplateMessageController } from "./indexModel";
import { useTranslation } from "react-i18next";

const ModalVisualizeTemplateMessageController:FC<IModalTemplateMessageController> = (props) => {
    // const [showModal, setShowModal] = useState(false);

    const dispatch = useDispatch();
    const appRequesterConst = new AppRequesterControlPanelController();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [attachmentIds, setAttachmentIds] = useState([]);

    const [tags, setTags] = useState([]);

    const { t } = useTranslation();

    const values: TokenInterface = {
        // internal: {
        //     getShowCreateTemplateMessage: useSelector(getShowCreateTemplateMessage),
        //     getShowTemplateTagModal: useSelector(getShowTemplateTagModal),
        // },
        token: {
            value: useSelector(getToken)
        }
    }

    const attachmentIdsCallback = (ids: []) => {
      setAttachmentIds(ids);
    }

    // useEffect(() => {
    //   if (values.internal.getShowCreateTemplateMessage)
    //     setShowModal(true);
    //   else {
    //     setShowModal(false);
    //     setTags([]);
    //   }
    // }, [values.internal.getShowCreateTemplateMessage]);

    // const hide = () => {
    //   if (values.internal.getShowTemplateTagModal)
    //     dispatch(setShowTemplateTagModal(false))
    //   else 
    //     dispatch(setShowCreateTemplateMessage(false));
    // }

    const postTemplateMessage = async (formValues: FormValues) => {
        const headers = {
          "Content-Type": "application/json; charset=utf-8",
          "Authorization": "Bearer " + values.token.value
        };

        await appRequesterConst.Post(
          '/template-message/whatsapp', formValues, { headers },
          (response: Object) => {
            return response;
          },
          (data) => {
            dispatch(setShowAlertFeedback({ message: "Template cadastrada com sucesso!", visibility: true, signalIcon: true }));
            setTags([]);
            // hide();
            // props.callback();
          },
          (error: Object) => {
            dispatch(setShowAlertFeedback({ message: "Ocorreu um erro inesperado, tente novamente mais tarde.", visibility: true, signalIcon: false }));
          }, navigate, dispatch, setIsLoading,
          () => { },
          {
            error500: () => {
              dispatch(setShowAlertFeedback({ message: "Arquivo não pôde ser aceito!", visibility: true, signalIcon: false }));
            }
          }
        );
      }

    return <ModalVisualizeTemplateMessage 
        showModal={props.showModal} 
        hide={props.onHide} 
        dispatch={dispatch}
        tags={tags}
        setTags={setTags}
        isLoading={isLoading}
        t={t}
        template={props.template}
        attachmentIdsCallback={attachmentIdsCallback}
        previewFiles={(props.template?.image_id && props.template?.image_url) ? { filename: "Imagem", _id: props.template.image_id, url: props.template.image_url }: null}
    />
}

export default ModalVisualizeTemplateMessageController;