import { useContext, useEffect, useState } from "react"
import { SocketContext } from "../core/context/socket-context"

const useNewAutomaticMessage = () => {

    const socket = useContext(SocketContext);

    const [newAutomaticMessage, setNewAutomaticMessage] = useState()

    useEffect(() =>  {
        socket.socket?.on('new-automatic-message',  (data) => setNewAutomaticMessage(data.ticket ? data.ticket : data))

        return () => {
            socket.socket?.off('new-automatic-message', newAutomaticMessage)
        }

    }, [socket.socket?.active])

    return { newAutomaticMessage }
}

export default useNewAutomaticMessage