import { Button, Popover } from "react-bootstrap";
import constsRouters from "../../../../routes/constsRouter";
import { getShowEditMessageTriggerModal, setShowAlertFeedback, setShowConfirmationModal, setShowEditMessageTriggerModal } from "../../../../store/internal";
import ListOfMessagesTriggers from ".";
import { FC, useEffect, useState } from "react";
import { ListOfMessagesTriggersInterface } from "./indexModel";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { MessageSendingIdInterface, TokenInterface } from "../../../../services/requestsInterfacesModel";
import { getIdCompany } from "../../../../store/company";
import { getIdEmployee } from "../../../../store/employee";
import { getIdUser, getNameUser, getProfileId } from "../../../../store/user";
import { getToken } from "../../../../store/token";
import Utils from "../../../../core/shared/utils";
import UserService from "../../../../services/user-service";
import { AppRequesterController } from "../../../../services/appRequester/appRequesterController";
import { MessageTriggerStatus } from "../../../../core/enums/message-trigger-status";
import { Message_Sending_Interface } from "../indexModel";
import { verifyCode } from "../../../../services/codeCxpressInterface";

const ListOfMessagesTriggersController: FC<ListOfMessagesTriggersInterface> = ({
    datas,
    statusPage,
    updateListAfterEditStatus,
    emailPrincipal,
    emailList,
    updateListfunction,
    showCreateTrigger,
    setCurrentTriggerFunction,
    actionsActiveColumn,
    dataTriggerType,
    templateMessageList,
    callback,
    callbackOrderByMessageTriggers,
    departmentOptions
}) => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    
    const [currentTriggerId, setCurrentTriggerId] = useState('');
    const [/*currentTriggerStatus*/, setCurrentTriggerStatus] = useState('');
    const [currentTriggerStatusId, setCurrentTriggerStatusId] = useState('');
    const [currentTriggerChannelId, setCurrentTriggerChannelId] = useState('');
    // const [currentTriggerResponsibleId, setCurrentTriggerResponsibleId] = useState('');
    const [currentTriggerResponsibleName, setCurrentTriggerResponsibleName] = useState('');
    
    const [isLoading, setIsLoading] = useState(false);
    const [triggers, setTriggers] = useState(undefined);
    const [currentStatusPage, setCurrentStatusPage] = useState("");
    const [viewTriggerModal, setViewTriggerModal] = useState(true);
    // const [templateMessageList, setTemplateMessageList] = useState(null);
    const [currentData, setCurrentData] = useState(null);
    const [currentTemplate, setCurrentTemplate] = useState(null);

    const [showReportModal, setShowReportModal] = useState(false);

    const navigate = useNavigate();

    const values: TokenInterface = {
        company: {
            id: useSelector(getIdCompany)
        },
        employee: {
            id: useSelector(getIdEmployee)
        },
        user: {
            id: useSelector(getIdUser),
            name: useSelector(getNameUser),
            profile_id: useSelector(getProfileId)
        },
        token: {
            value: useSelector(getToken)
        }
    };

    const showEditTriggerModal = useSelector(getShowEditMessageTriggerModal);

    const AppRequesterConst = new AppRequesterController();

    useEffect(() => {
        if (datas !== undefined) {
            if (statusPage !== currentStatusPage) {
                setCurrentStatusPage(statusPage);
            }
            setTriggers(datas);
        } else {
            setTriggers(undefined);
        }

        Utils.changeActiveScrollBar("listOfTriggers");
    }, [datas, triggers]);

    const getAllMessageTriggers = async () => {

        const headers = UserService.getHeaders()

        await AppRequesterConst.Get(
            "/message-sending/" + currentTriggerId,
            { headers: headers },
            (response:Object) => { },
            (data:MessageSendingIdInterface) => {
                if(data.status === 200) {
                    setCurrentData(data.data.message_sending);
                    setCurrentTemplate(data.data.template_message);
                    dispatch(setShowEditMessageTriggerModal(true));
                }
            },
            (error:{ response: { status: number; data: { message: any[], code_cxpress: number } } }) => { 
                if (error.response.status === 400) {
                    dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }))
                } else {
                    dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }))
                }
            },
            navigate, dispatch, setIsLoading, { values: values }
        );
    }


    const confirmCancelTrigger = async () => {

        const headers = UserService.getHeaders()

        const JsonSend = {
            "id": currentTriggerId,
            "message_sending_status_id": MessageTriggerStatus.Canceled,
        };

        await AppRequesterConst.Put(
            '/message-sending', JsonSend, { headers },
            (response:Object) => { },
            (data:Message_Sending_Interface) => {
                dispatch(setShowAlertFeedback({ message: t("home_message_trigger.list_of_messages_triggers.cancel_sucess"), visibility: true, signalIcon: true }));
            },
            (error: { response: { status: number; data:{code_cxpress: number} }; }) => {
                if (error.response !== undefined) {
                    //if (error.response.status === 401) {

                    //}
                    //if (error.response.status === 403) {

                    //}
                    //if (error.response.status === 422) {

                    /*} else*/ if (error.response.status === 500) {
                        dispatch(setShowAlertFeedback({ message: t("home_message_trigger.list_of_messages_triggers.not_edit"), visibility: true, signalIcon: true }));
                    }
                }
                dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }));
            },
            navigate, dispatch, setIsLoading
        );

        updateListAfterEditStatus();
    }

    const setCurrentTriggerInfo = (e: { currentTarget: { id: string; }; }) => {
        setCurrentTriggerId(e.currentTarget.id.split('*ID*')[0]);
        setCurrentTriggerStatus(e.currentTarget.id.split('*ID*')[1]);
        setCurrentTriggerStatusId(e.currentTarget.id.split('*ID*')[2]);
        setCurrentTriggerChannelId(e.currentTarget.id.split('*ID*')[3]);
        setCurrentTriggerResponsibleName(e.currentTarget.id.split('*ID*')[4]);
    }

    const showEditMessageTrigger = (status: { currentTarget: { id: string; }; }, getAllMessageTriggers: Function) => {
        getAllMessageTriggers();
        setViewTriggerModal(status.currentTarget.id === "Programado" ? false : true)
    }

    const viewTicketsPage = (e: any) => {
        if (e.target && e.target.name === "ButtonViewRelatedTickets") {
            navigate(constsRouters.routers.ticketsMessageTriggerView.path, {state: {id: currentTriggerId, status_id: currentTriggerStatusId, channel_id: currentTriggerChannelId, responsible_name: currentTriggerResponsibleName}});
        } /* else { 
            navigate(constsRouters.routers.configurationTagsView.path, {state: {id: e.currentTarget.id.split("*ID*")[1], name: e.currentTarget.id.split("*ID*")[0], active: e.currentTarget.id.split("*ID*")[2]}});
        } */
    }

    const messageTriggerClick = (e: { currentTarget: { id: string } }) => {
        let trigger = e.currentTarget.id.split("*message_status*");
        navigate(constsRouters.routers.ticketsMessageTriggerView.path, {state: {id: trigger[1], status_id: trigger[0], channel_id: trigger[2], responsible_name: trigger[3]}});
    }

    const messageTriggerReportClick = (e: any) => {
        setShowReportModal(true)
        // if (e.target && e.target.name === "ButtonMessageTriggerReport") {
        //     window.open(constsRouters.routers.ticketsMessageTriggerReport.path + "?id=" + currentTriggerId, '_blank');
        //     //navigate(constsRouters.routers.ticketsMessageTriggerReport.path, {state: {id: currentTriggerId, status_id: currentTriggerStatusId, channel_id: currentTriggerChannelId, responsible_name: currentTriggerResponsibleName}});
        // }
    }

    const cancelTrigger = (confirmCancelTrigger: Function) => {
        dispatch(setShowConfirmationModal({ visibility: true, text: { "title": t("home_message_trigger.list_of_messages_triggers.cancel_trigger"), "body": t("home_message_trigger.list_of_messages_triggers.body_modal_cancel"), "id": " Disparo ativo?", "buttonConfirmationText": t("home_message_trigger.list_of_messages_triggers.cancel_trigger"), "buttonReturnText": "Cancelar" }, functionConfirmation: confirmCancelTrigger }));
    }

    const statusTranslate = (status: string) => {
        if (status === "Programado") {
            return t("home_message_trigger.list_of_messages_triggers.progammad")
        }
        else if (status === "Disparado") {
            return t("home_message_trigger.list_of_messages_triggers.fired")
        }
        else if (status === "Cancelado") {
            return t("home_message_trigger.list_of_messages_triggers.cancel")
        }
    }

    const popover = (status: string, getAllMessageTriggers: Function, confirmCancelTrigger: Function) => {
        return (
            <Popover id={currentTriggerId} style={{ zIndex: '500', backgroundColor: '#e6e6e6', border: 'none', color: '#40484E', font: 'var(--px15_19-font-Roboto)' }}>
                <Popover.Body className="nopadding">
                    {status === "Disparado" ?
                        <div>
                            <Button id={status} onClick={(status) => showEditMessageTrigger(status, getAllMessageTriggers)} className="m-1" style={{ backgroundColor: 'transparent', border: 'none', color: 'black', fontSize: 'inherit' }}>{t("home_message_trigger.list_of_messages_triggers.view_information")}</Button>
                            <div style={{ backgroundColor: '#707070', opacity: '0.5', height: '1px', width: '100%' }}></div>
                            <Button onClick={(e) => viewTicketsPage(e)} className="m-1" name="ButtonViewRelatedTickets" style={{ backgroundColor: 'transparent', border: 'none', color: 'black', fontSize: 'inherit' }}>{t('home_message_trigger.list_of_messages_triggers.see_associated_tickets')}</Button>
                            <div style={{ backgroundColor: '#707070', opacity: '0.5', height: '1px', width: '100%' }}></div>
                            <Button onClick={(e) => messageTriggerReportClick(e)} className="m-1" name="ButtonMessageTriggerReport" style={{ backgroundColor: 'transparent', border: 'none', color: 'black', fontSize: 'inherit' }}>{t('home_message_trigger.list_of_messages_triggers.report')}</Button>
                        </div> :
                        status === "Programado" ?
                            <div>
                                <Button id={status} onClick={(status) => showEditMessageTrigger(status, getAllMessageTriggers)} className="m-1" style={{ backgroundColor: 'transparent', border: 'none', color: 'black', fontSize: 'inherit' }}>{t('home_message_trigger.list_of_messages_triggers.edit_trigger')}</Button>
                                <div style={{ backgroundColor: '#707070', opacity: '0.5', height: '1px', width: '100%' }}></div>
                                <Button onClick={() => cancelTrigger(confirmCancelTrigger)} className="m-1" style={{ backgroundColor: 'transparent', border: 'none', color: 'black', fontSize: 'inherit' }}>{t('home_message_trigger.list_of_messages_triggers.cancel_trigger')}</Button>
                            </div> :
                            <div>
                                <Button id={status} onClick={(status) => showEditMessageTrigger(status, getAllMessageTriggers)} className="m-1" style={{ backgroundColor: 'transparent', border: 'none', color: 'black', fontSize: 'inherit' }}>{t('home_message_trigger.list_of_messages_triggers.view_information')}</Button>
                            </div>
                    }
                </Popover.Body>
            </Popover>
        );
    }

    const handleCloseModalReport = () => setShowReportModal(false)

    return (
        <ListOfMessagesTriggers
            isLoading={isLoading}
            values={values}
            callback={callback}
            viewTriggerModal={viewTriggerModal}
            dataTriggerType={dataTriggerType}
            templateMessageList={templateMessageList}
            currentData={currentData}
            currentTemplate={currentTemplate}
            emailList={emailList}
            emailPrincipal={emailPrincipal}
            triggers={triggers}
            callbackOrderByMessageTriggers={callbackOrderByMessageTriggers}
            actionsActiveColumn={actionsActiveColumn}
            updateListfunction={updateListfunction}
            messageTriggerClick={messageTriggerClick}
            popover={popover}
            getAllMessageTriggers={getAllMessageTriggers}
            confirmCancelTrigger={confirmCancelTrigger}
            setCurrentTriggerInfo={setCurrentTriggerInfo}
            showCreateTrigger={showCreateTrigger}
            showEditTriggerModal={showEditTriggerModal}
            t={t}
            statusTranslate={statusTranslate}
            showReportModal={showReportModal}
            handleCloseModalReport={handleCloseModalReport}
            currentTriggerId={currentTriggerId}
            departmentOptions={departmentOptions}
        />
    )
}

export default ListOfMessagesTriggersController