import React from 'react';
import { Modal, Button, Form, InputGroup, FormControl, Col, Row } from 'react-bootstrap';
import { Formik } from 'formik';
import allStyles from './styles';
import Tags from "@yaireo/tagify/dist/react.tagify"
import { AppRequesterController } from '../../../../services/appRequester/appRequesterController';
import { setShowAlertFeedback } from '../../../../store/internal';
import UserService from '../../../../services/user-service';
import { t } from 'i18next';
import SelectComponentController from '../selectComponent/indexController';
import { CustomField } from '../../../../core/enums/custom-field-type';

const AppRequesterConst = new AppRequesterController();

const ModalRegisterCustomFields = ({
    show,
    clearFields,
    type,
    setName,
    setType,
    setSelectedOptions,
    setDefaultValue,
    setPlaceholder,
    setShowFeedbackName,
    setShowFeedbackPlaceholder,
    placeholder,
    entityId,
    selectedType,
    labelledOptions,
    selectedsOptions,
    dispatch,
    callback,
    navigate,
    isLoading,
    setIsLoading,
    showFeedbackName,
    validated,
    setSelectedType,
    onChangeTags,
    selectedOptions,
    showFeedbackOptionList,
    setLabelledOptions,
    setSelectedsOptions,
    showFeedbackPlaceholder,
    name,
    setSelectedType1,
    selectedType1,
    type1,
    setType1,
    setShowFeedbackMin,
    setShowFeedbackMax,
    showFeedbackMin,
    showFeedbackMax,
    min,
    max,
    setMin,
    setMax
}) => {
    return (
        <Modal show={show} onHide={clearFields} centered>
            <Modal.Header closeButton>
                <Modal.Title>{t('configurations_consumers.modal_register_custom_field.create_custom_field')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Formik
                    initialValues={{ name: '', min, max, type: type1, optionList: [], defaultValue: '', placeholder: '' }}
                    validate={values => {
                        let errors = {};
                        setName(values.name);
                        setType1(values.type);
                        setMin(values.min);
                        setMax(values.max);
                        setSelectedOptions(values.optionList);
                        setDefaultValue(values.defaultValue);
                        setPlaceholder(values.placeholder);

                        if (!values.name) {
                            errors["name"] = t('configurations_consumers.modal_register_custom_field.validate.name');
                            setShowFeedbackName(true);
                        } else {
                            setShowFeedbackName(false);
                        }

                        if (!values.placeholder) {
                            errors["placeholder"] = t('configurations_consumers.modal_register_custom_field.validate.placeholder');
                            setShowFeedbackPlaceholder(true);
                        } else {
                            setShowFeedbackPlaceholder(false);
                        }

                        if (values.min < 1) {
                            errors["min"] = t('configurations_ticket.modal_register_custom_field.validate.min_between');
                            setShowFeedbackMin(true);
                        }else if (values.min > 500) {
                            errors["min"] = t('configurations_ticket.modal_register_custom_field.validate.min_between2');
                            setShowFeedbackMin(true);
                        }else if (values.min > values.max) {
                            errors["min"] = t('configurations_ticket.modal_register_custom_field.validate.min_max_between');
                            setShowFeedbackMin(true);
                        }
                        else{
                            setShowFeedbackMin(false);
                        }
                
                        if (values.max > 500) {
                            errors["max"] = t('configurations_ticket.modal_register_custom_field.validate.max_between');
                            setShowFeedbackMax(true);
                        }else{
                            setShowFeedbackMax(false);
                        }

                        return errors;
                    }}
                    onSubmit={async () => {
                        let JsonSend = {
                            name: name,
                            placeholder: placeholder,
                            entity_type_id: entityId,
                            field_size_min: min,
                            field_size_max: max
                        }

                        // if (selectedType.length === 0) {
                        //     dispatch(setShowAlertFeedback({ message: t('configurations_consumers.modal_register_custom_field.submit.message'), visibility: true, signalIcon: false }));
                        // }

                        if (selectedType1.length === 0) {
                            dispatch(setShowAlertFeedback({ message: t('configurations_ticket.modal_register_custom_field.submit.select_type'), visibility: true, signalIcon: false }));
                        }

                        if(max <= 50) {
                            JsonSend['custom_field_type_id'] = 'd31ebd9e-af9f-4559-b04a-729439218a6f'
                        }else if (max > 50 && max <= 500){
                            JsonSend['custom_field_type_id'] = '2982a738-4de3-440a-8ed1-cc1fdb51bc9b'
                        }

                        if(selectedType1[0].value === 'Número'){
                            JsonSend['custom_field_datatype_id'] = CustomField.Number
                        }else if(selectedType1[0].value === 'Alfanumérico'){
                            JsonSend['custom_field_datatype_id'] = CustomField.Alphanumeric
                        }else if(selectedType1[0].value === 'Texto'){
                            JsonSend['custom_field_datatype_id'] = CustomField.Text1
                        }

                        // if (selectedType[0].value === t('configurations_consumers.modal_register_custom_field.submit.simple_text')) {
                        //     JsonSend['custom_field_type_id'] = 'd31ebd9e-af9f-4559-b04a-729439218a6f'
                        // } else if (selectedType[0].value === t('configurations_consumers.modal_register_custom_field.submit.multiline_text')) {
                        //     JsonSend['custom_field_type_id'] = '2982a738-4de3-440a-8ed1-cc1fdb51bc9b'
                        // } else if (selectedType[0].value === t('configurations_consumers.modal_register_custom_field.submit.simple_select')) {
                        //     JsonSend['custom_field_type_id'] = '74bf4b70-3415-4e8a-ac81-7ebe435c1424'
                        // } else if (selectedType[0].value === t('configurations_consumers.modal_register_custom_field.submit.multiple_select')) {
                        //     JsonSend['custom_field_type_id'] = '49d495a8-50d4-4d61-9d62-e1cc03227573'
                        // }

                        if (labelledOptions && labelledOptions.length > 0) {
                            let options = []
                            labelledOptions.map((option, index) => {
                                options[index] = (option.value);
                            })
                            JsonSend["options"] = options
                        }

                        if (selectedsOptions && selectedsOptions.length > 0) {
                            JsonSend["default_value"] = selectedsOptions[0].value
                        }

                        const headers = UserService.getHeaders()


                        await AppRequesterConst.Post('/custom-fields', JsonSend, { headers },
                            (response: Object) => { return response; },
                            (data: Object) => {
                                dispatch(setShowAlertFeedback({ message: `${t('configurations_consumers.modal_register_custom_field.post.message_a')} ${JsonSend.name} ${t('configurations_consumers.modal_register_custom_field.post.message_b')}`, visibility: true, signalIcon: true }));
                                callback();
                                clearFields();
                            },
                            (error: { response: { status: number; }; }) => {
                                if (error.response !== undefined) {
                                    if (error.response.status === 400) {
                                        dispatch(setShowAlertFeedback({ message: t('configurations_consumers.modal_register_custom_field.post.limit_error'), visibility: true, signalIcon: false }));
                                    }
                                    else {
                                        dispatch(setShowAlertFeedback({ message: t('configurations_consumers.modal_register_custom_field.post.unexpected_error'), visibility: true, signalIcon: false }));
                                    }
                                }

                            }, navigate, dispatch, setIsLoading
                        )
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                    }) => (
                        <Form onSubmit={handleSubmit} noValidate validated={validated}>
                            <Row style={allStyles.textFontGlay} className=" d-flex justify-content-center">
                                <Col sm={true}>
                                    <Form.Label htmlFor="name">{t('configurations_consumers.modal_register_custom_field.form_name.name')}<span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black' }}>*</span></Form.Label>
                                    <InputGroup className="mb-3">
                                        <FormControl className="form-control-Default"
                                            isInvalid={showFeedbackName}
                                            placeholder={t('configurations_consumers.modal_register_custom_field.form_name.placeholder')}
                                            aria-label={t('configurations_consumers.modal_register_custom_field.form_name.placeholder')}
                                            aria-describedby={t('configurations_consumers.modal_register_custom_field.form_name.placeholder')}
                                            id="name"
                                            maxLength={50}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.name}
                                        >
                                        </FormControl>
                                        <Form.Control.Feedback type="invalid" id="feedbackname">
                                            {errors.name}
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row style={allStyles.textFontGlay} className=" d-flex justify-content-center">
                                <Col sm={true}>
                                    <Form.Label htmlFor="basic-url">{t('configurations_consumers.modal_register_custom_field.form_type.type')}<span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black' }}>*</span></Form.Label>
                                    <SelectComponentController
                                        className={"multiselectForm mb-3"}
                                        options={type1}
                                        setOptions={setType1}
                                        selecteds={selectedType1}
                                        setSelecteds={setSelectedType1}
                                        singleSelect={true}
                                        title={t('configurations_consumers.modal_register_custom_field.form_type.placeholder')}
                                        overrideStrings={
                                            {
                                                "allItemsAreSelected": t('configurations_consumers.modal_register_custom_field.form_type.overrideStrings.allItemsAreSelected'),
                                                "clearSearch": t('configurations_consumers.modal_register_custom_field.form_type.overrideStrings.clearSearch'),
                                                "noOptions": t('configurations_consumers.modal_register_custom_field.form_type.overrideStrings.noOptions'),
                                                "search": t('configurations_consumers.modal_register_custom_field.form_type.overrideStrings.search'),
                                                "selectSomeItems": t('configurations_consumers.modal_register_custom_field.form_type.overrideStrings.selectSomeItems'),
                                                "create": t('configurations_consumers.modal_register_custom_field.form_type.overrideStrings.create'),
                                            }
                                        }
                                        hasCreateItem={false}
                                        hasNullOption={false}
                                        isFormNoFilter={false}
                                        hasAllOption={false}
                                    />
                                </Col>
                            </Row>
                            {(selectedType1[0]?.value.length > 0) &&
                                <>
                                    <Row style={allStyles.textFontGlay} className="d-flex justify-content-center">
                                        <Col sm={true} style={allStyles.textFontGlay}>
                                            <Form.Label htmlFor="basic-url">
                                                {t('configurations_ticket.modal_register_custom_field.form_limit_type.limit_text_Value')}
                                                <span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black' }}>*</span>
                                            </Form.Label>
                                            <Row>
                                                <Col>
                                                    <InputGroup className="mb-3">
                                                        <FormControl className="form-control-Default"
                                                            isInvalid={showFeedbackMin}
                                                            placeholder={selectedType1[0].value === 'Número' ? t('configurations_ticket.modal_register_custom_field.form_limit_type.limit_min_input_number'): t('configurations_ticket.modal_register_custom_field.form_limit_type.limit_min_input')}
                                                            aria-label={selectedType1[0].value === 'Número' ? t('configurations_ticket.modal_register_custom_field.form_limit_type.limit_min_input_number'): t('configurations_ticket.modal_register_custom_field.form_limit_type.limit_min_input')}
                                                            aria-describedby={selectedType1[0].value === 'Número' ? t('configurations_ticket.modal_register_custom_field.form_limit_type.limit_min_input_number'): t('configurations_ticket.modal_register_custom_field.form_limit_type.limit_min_input')}
                                                            id="min"
                                                            type='number'
                                                            minLength={1}
                                                            maxLength={3}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.min}
                                                        />
                                                        <Form.Control.Feedback type="invalid" id="feedbackmin">
                                                            {errors.min}
                                                        </Form.Control.Feedback>
                                                    </InputGroup>
                                                </Col>
                                                <Col>
                                                    <InputGroup className="mb-3">
                                                        <FormControl className="form-control-Default"
                                                            isInvalid={showFeedbackMax}
                                                            placeholder={selectedType1[0].value === 'Número' ? t('configurations_ticket.modal_register_custom_field.form_limit_type.limit_max_input_number'): t('configurations_ticket.modal_register_custom_field.form_limit_type.limit_max_input')}
                                                            aria-label={selectedType1[0].value === 'Número' ? t('configurations_ticket.modal_register_custom_field.form_limit_type.limit_max_input_number'): t('configurations_ticket.modal_register_custom_field.form_limit_type.limit_max_input')}
                                                            aria-describedby={selectedType1[0].value === 'Número' ? t('configurations_ticket.modal_register_custom_field.form_limit_type.limit_max_input_number'): t('configurations_ticket.modal_register_custom_field.form_limit_type.limit_max_input')}
                                                            id="max"
                                                            type='number'
                                                            minLength={1}
                                                            maxLength={3}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.max}
                                                        />
                                                        <Form.Control.Feedback type="invalid" id="feedbackmax">
                                                            {errors.max}
                                                        </Form.Control.Feedback>
                                                    </InputGroup>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </>
                            }
                            {/* {(selectedType[0]?.value === t('configurations_consumers.modal_register_custom_field.submit.simple_select') || selectedType[0]?.value === t('configurations_consumers.modal_register_custom_field.submit.multiple_select')) && (
                                <>
                                    <Row className="d-flex justify-content-center">
                                        <Col sm={true} style={allStyles.textFontGlay} >
                                            <Form.Label htmlFor="basic-url">{t('configurations_consumers.modal_register_custom_field.options_list')}<span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black' }}>*</span></Form.Label>
                                            <Tags
                                                className="form-control-Default-text-area tag-min-height mb-3"
                                                placeholder={t('configurations_consumers.modal_register_custom_field.placeholder_options_list')}
                                                onChange={onChangeTags}
                                                defaultValue={selectedOptions}
                                                isInvalid={showFeedbackOptionList}
                                            />
                                            <Form.Control.Feedback type="invalid" id="feedbackoptionlist">
                                                {errors.optionList}
                                            </Form.Control.Feedback>
                                        </Col>
                                    </Row>
                                    {(selectedType[0]?.value === t('configurations_consumers.modal_register_custom_field.submit.simple_select')) && (
                                        <>
                                            <Row style={allStyles.textFontGlay} className=" d-flex justify-content-center">
                                                <Col sm={true}>
                                                    <Form.Label htmlFor="basic-url">{t('configurations_consumers.modal_register_custom_field.default_option')}<span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black' }}>*</span></Form.Label>
                                                    <SelectComponentController
                                                        className={"multiselectForm mb-3"}
                                                        options={setLabelledOptions.length > 0 ? labelledOptions : []}
                                                        setOptions={setLabelledOptions}
                                                        selecteds={setSelectedsOptions.length > 0 ? selectedsOptions : []}
                                                        setSelecteds={setSelectedsOptions}
                                                        singleSelect={true}
                                                        title={t('configurations_consumers.modal_register_custom_field.placeholder_default_option')}
                                                        overrideStrings={
                                                            {
                                                                "allItemsAreSelected": t('configurations_consumers.modal_register_custom_field.override_strings.allItemsAreSelected'),
                                                                "clearSearch": t('configurations_consumers.modal_register_custom_field.override_strings.clearSearch'),
                                                                "noOptions": t('configurations_consumers.modal_register_custom_field.override_strings.noOptions'),
                                                                "search": t('configurations_consumers.modal_register_custom_field.override_strings.search'),
                                                                "selectSomeItems": t('configurations_consumers.modal_register_custom_field.override_strings.selectSomeItems'),
                                                                "create": t('configurations_consumers.modal_register_custom_field.override_strings.create'),
                                                            }
                                                        }
                                                        hasCreateItem={false}
                                                        hasNullOption={false}
                                                        isFormNoFilter={false}
                                                        hasAllOption={false}
                                                    />
                                                </Col>
                                            </Row>
                                        </>
                                    )}
                                </>)} */}
                            <Row style={allStyles.textFontGlay} className=" d-flex justify-content-center">
                                <Col sm={true}>
                                    <Form.Label htmlFor="placeholder">{t('configurations_consumers.modal_register_custom_field.placeholder')}<span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black' }}>*</span></Form.Label>
                                    <InputGroup className="mb-3">
                                        {selectedType[0]?.value === 'Texto simples' || !selectedType[0]?.value ?
                                            <FormControl className="form-control-Default"
                                                isInvalid={showFeedbackPlaceholder}
                                                placeholder={t('configurations_consumers.modal_register_custom_field.placeholder_value')}
                                                aria-label={t('configurations_consumers.modal_register_custom_field.placeholder_value')}
                                                aria-describedby={t('configurations_consumers.modal_register_custom_field.placeholder_value')}
                                                id="placeholder"
                                                maxLength={50}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.placeholder}
                                            />
                                            :
                                            <FormControl className="form-control-Default"
                                                isInvalid={showFeedbackPlaceholder}
                                                as="textarea"
                                                placeholder={t('configurations_consumers.modal_register_custom_field.placeholder_value')}
                                                aria-label={t('configurations_consumers.modal_register_custom_field.placeholder_value')}
                                                aria-describedby={t('configurations_consumers.modal_register_custom_field.placeholder_value')}
                                                id="placeholder"
                                                type='textarea'
                                                maxLength={500}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.placeholder}
                                            />
                                        }
                                        <Form.Control.Feedback type="invalid" id="feedbackplaceholder">
                                            {errors.placeholder}
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Row className="d-flex justify-content-center mt-4">
                                <Col xs={6} className="d-flex justify-content-start">
                                    <Button size='lg' className="buttonWhite" style={{ width: '56%' }} onClick={clearFields} >{t('configurations_consumers.modal_register_custom_field.cancel')}</Button>
                                </Col>
                                <Col xs={6} className="d-flex justify-content-end">
                                    <Button size='lg' type='submit' className="buttonBlue" style={{ width: '56%' }} disabled={isLoading}>{t('configurations_consumers.modal_register_custom_field.save')}</Button>
                                </Col>
                            </Row>
                        </Form>
                    )}
                </Formik>
            </Modal.Body>
        </Modal>
    );
};

export default ModalRegisterCustomFields;