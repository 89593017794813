import { useTranslation } from "react-i18next";
import { BodyInterface, RegisterFormControllerInterface } from "./indexModel";
import React, { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AuthenticationRequests } from "../../../services/authentication/authenticationControler";
import RegisterForm from ".";
import { setShowAlertFeedback } from "../../../store/internal";
import { verifyCode } from "../../../services/codeCxpressInterface";
import Styles from './../registerDesktop/styles.module.scss';

const RegisterFormControler: FC<RegisterFormControllerInterface> = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const authenticationRequestsConst = new AuthenticationRequests();

  const [agentPhone, setAgentPhone] = useState(null as string || null);

  const [stage, setStage] = useState(1);
  const [progress, setProgress] = useState(34);

  const [companyName, setCompanyName] = useState(null as string || null);
  const [companyEmployeeNumber, setCompanyEmployeeNumber] = useState(null as string || null);
  const [userEmail, setUserEmail] = useState(null as string || null);
  const [userPassword, setUserPassword] = useState(null as string || null);
  const [userName, setuserName] = useState(null as string || null);
  const [userLastname, setuserLastname] = useState(null as string || null);
  const [subdomain, setSubdomain] = useState(null as string || null);
  const [showPassword, setShowPassword] = useState(false);
  const [showfeedbackEmail, setshowfeedbackEmail] = useState(false);
  const [showfeedbackName, setShowfeedbackName] = useState(false);
  const [showfeedbackLastname, setShowfeedbackLastname] = useState(false);
  const [showfeedbackCompanyName, setShowfeedbackCompanyName] = useState(false);
  const [showfeedbackPassword, setShowfeedbackPassword] = useState(false);
  const [documentNumber, setDocumentNumber] = useState(null as string || null);
  const [showfeedbackDocumentNumber, setShowfeedbackDocumentNumber] = useState(false);

  const [/*isLoading*/, setIsLoading] = useState(false);

  // const RegisterControlerConst = new RegisterDesktopHomeControler(setShowPassword, showPassword, setStage, stage, setProgress, progress, setCompanyEmployeeNumber);

  const countryCodes = {
    'PT-PT': 'PT',
    'PT-BR': 'BR',
    'UK': 'GB',
    'ES': 'ES',
    'FR': 'FR'
  };

  const [selectedCountry, setSelectedCountry] = useState([]);
  //const [mask, setMask] = useState(false);

  const validSelectDropdownCompanyEmployeeNumber = () => {
    if (document.getElementById("company_employee_number").innerHTML === t("register.select_dropdown")) {
      document.getElementById("feedbackCompany_employee_number").innerHTML = t("register.errors.select_number_employees");
      document.getElementById("feedbackCompany_employee_number").style.display = "block";
      
      if (props.isMobile) {
          document.getElementById("company_employee_number").className = Styles.dropdownButtonRedBorderRegisterMobile + " dropdownButtonRedBorderFontMobile" + " dropdown-toggle btn btn-primary";
      } else {
          document.getElementById("company_employee_number").className = Styles.dropdownButtonRedBorder + " " + Styles.dropdownButtonRedBorderFontNormal + " dropdown-toggle btn btn-primary";
      }
      return true;
    } else {
        document.getElementById("feedbackCompany_employee_number").style.display = "none";
        document.getElementById("company_employee_number").className = (props.isMobile? "dropdownButtonRegisterMobile":"dropdownButton") + " dropdown-toggle btn btn-primary " + (props.isMobile? "dropdownButtonRedBorderFontMobile": "");
        return false;
    }
  }

  const resendEmailConfirmation = () => {

    const body = {
      "email": userEmail,
      "subdomain": subdomain,
    };

    const headers = {
      "Content-Type": "application/json; charset=utf-8"
    };

    authenticationRequestsConst.SendEmailWithConfirmation(
      body,
      { headers },
      (response: Object) => {
        return response
      },
      (data: Object) => {
        dispatch(setShowAlertFeedback({ message: t("register.resend_email"), visibility: true, signalIcon: true }));
        return data;
        // setIsLoading(false);
      },
      (error: Object) => {
        // return error
        dispatch(setShowAlertFeedback({ message: t("errors.defaultErrorMessage"), visibility: true, signalIcon: false }));
      }, navigate, dispatch, setIsLoading,
      // {
      //   error400: () => {
      //     setIsLoading(false);
      //   },
      //   error422: () => {
      //     setIsLoading(false);
      //   }
      // }
    );
  }

  const [optionsCountry, setOptionsCountry] = useState([
    { label: t("register.options_country.brazil"), value: 'PT-BR' },
    { label: t("register.options_country.portugal"), value: 'PT-PT' },
    { label: t("register.options_country.spain"), value: 'ES' },
    { label: t("register.options_country.france"), value: 'FR' },
    { label: t("register.options_country.uk"), value: 'UK' },
  ])

  const switchLanguage = () => {
    if (selectedCountry[0]?.value === 'PT-BR') {
      return ['br']
    }
    if (selectedCountry[0]?.value === 'PT-PT') {
      return ['pt']
    }
    else if (selectedCountry[0]?.value === 'ES') {
      return ['es']
    }
    else if (selectedCountry[0]?.value === 'FR') {
      return ['fr']
    }
    else if (selectedCountry[0]?.value === 'UK') {
      return ['gb']
    }
    else {
      return ['br']
    }
  }

  const validatePhone = () => {
    if (selectedCountry[0]?.value === 'PT-BR' && agentPhone?.length === 13) {
      return false;
    }
    else if (selectedCountry[0]?.value === 'PT-PT' && agentPhone?.length === 12) {
      return false;
    }
    else if (selectedCountry[0]?.value === 'ES' && agentPhone?.length === 11) {
      return false;
    }
    else if (selectedCountry[0]?.value === 'FR' && agentPhone?.length === 11) {
      return false;
    }
    else if (selectedCountry[0]?.value === 'UK' && agentPhone?.length === 12) {
      return false;
    }
    return true
  }

  const validateCountry = () => {
    if (selectedCountry[0] !== undefined) { // verifica se o país foi selecionado, e libera o botão para seguir com o cadastro
      return false
    }
    else {
      return true
    }
  }

  const switchDocument = () => {
    if (selectedCountry[0]?.value === 'PT-BR') {
      return 'CNPJ'
    }
    else if (selectedCountry[0]?.value === 'PT-PT') {
      return 'NIPC'
    }
    else if (selectedCountry[0]?.value === 'ES') {
      return 'CIF'
    }
    else if (selectedCountry[0]?.value === 'FR') {
      return 'SIREN'
    }
    else if (selectedCountry[0]?.value === 'UK') {
      return 'VAT Reg No'
    }
    else {
      return 'CNPJ'
    }
  }

  const validateRegisterForm = (values: BodyInterface) => {
    setuserName(values.name);
    setuserLastname(values.lastname);

    let errors = {};
    if (values.name && !/^[a-zA-ZÀ-ÿ\s'.,-]+$/i.test(values.name)) {
      errors['name'] = t("register.errors.invalid_name");
      setShowfeedbackName(true);
    } else if (values.name.length > 25) {
      errors['name'] = t("register.errors.limit_chars_name");
      setShowfeedbackName(true);
    } else {
      setShowfeedbackName(false);
    }

    if (values.lastname && !/^[a-zA-ZÀ-ÿ\s'.,-]+$/i.test(values.lastname)) {
      errors['lastname'] = t("register.errors.invalid_lastname");
      setShowfeedbackLastname(true);
    } else if (values.lastname.length > 25) {
      errors['lastname'] = t("register.errors.limit_chars_lastname");
      setShowfeedbackLastname(true);
    } else {
      setShowfeedbackLastname(false);
    }

    return errors;
  }

  const validateEmailForm = (values: BodyInterface) => {
    setUserEmail(values.email);

    let errors = {};
    if (!values.email) {
      errors['email'] = t("login.error_fill_field");
      setshowfeedbackEmail(true);
    } else if (values.email &&
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(values.email)
    ) {
      errors['email'] = t("login.error_invalid_email");
      setshowfeedbackEmail(true);
    }
    else if (values.email.length >= 100) {
      errors['email'] = t("register.errors.limit_chars_email");
      setshowfeedbackEmail(true);
    } else {
      setshowfeedbackEmail(false);
    }

    return errors;
  }

  const validateCompanyForm = (values: BodyInterface) => {
    setCompanyName(values.company_name);
    setUserPassword(values.password);
    setDocumentNumber(values.document_number);

    let errors = {};

    if (
      values.company_name && !/^[a-zA-Z0-9]+(([',. -][a-zA-Z0-9 ])?[a-zA-Z0-9]*)*$/g.test(values.company_name)
    ) {
      errors['company_name'] = t("register.errors.invalid_company_name");
      setShowfeedbackCompanyName(true);
    } else if (values.company_name.length >= 100) { // ANALISAR PORQUE 100, SE O ERRO RETORNA 25
      errors['company_name'] = t("register.errors.limit_chars_company_name");
      setShowfeedbackCompanyName(true);
    } else {
      setShowfeedbackCompanyName(false);
    }

    if (values.password === null || values.password?.length === 0 || values.password?.length > 0 && values.password?.length <= 4) {
      errors['password'] = t("register.errors.limit_short_password");
      setShowfeedbackPassword(true);
    } else if (values.password.length > 128) {
      errors['password'] = t("register.errors.limit_password");
      setShowfeedbackPassword(true);
    } else if (userEmail?.includes(values?.password)) {
      errors['password'] = t("register.errors.distinct_password_email");
      setShowfeedbackPassword(true);
    } else if (!/[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]/.test(values.password)) {
      errors['password'] = t("register.errors.fill_special_character_password");
      setShowfeedbackPassword(true);
    } else if (!/[A-Z]/.test(values.password)) {
      errors['password'] = t("register.errors.fill_uppercase_character_password");
      setShowfeedbackPassword(true);
    } else if (!/[a-z]/.test(values.password)) {
      errors['password'] = t("register.errors.fill_lowercase_character_password");
      setShowfeedbackPassword(true);
    } else if (!/\d/.test(values.password)) {
      errors['password'] = t("register.errors.fill_number_password");
      setShowfeedbackPassword(true);
    } else {
      setShowfeedbackPassword(false);
    }

    validSelectDropdownCompanyEmployeeNumber();

    if (values.document_number && values.document_number.search("_") !== -1) {
      //errors['document_number'] = `${t('defaults.cnpj_nif')} inválido`;
      errors['document_number'] = t("register.errors.invalid_document");
      setShowfeedbackDocumentNumber(true);
    } else {
      setShowfeedbackDocumentNumber(false);
    }

    return errors;
  }

  const clickToViewPassword = () => {
    setShowPassword(!showPassword);

    if ((document.getElementById("user_password") as HTMLInputElement).type === "text") {
      (document.getElementById("user_password") as HTMLInputElement).type = "password";
    } else {
      (document.getElementById("user_password") as HTMLInputElement).type = "text";
    }
  }

  const clickStage = (isNextStage?: boolean) => {
    if (isNextStage) {
      setStage(stage + 1);
      setProgress(progress + 34);
    } else {
      setStage(stage - 1);
      setProgress(progress - 34);
    }

    setStage((state) => {
      for (let i = 1; i <= 4; i++) {
        if (i === state) {
          // Utils.changeStyleElementById('stage' + state, 'display', 'block');
          document.getElementById("stage" + state).style.display = "block";
        } else {
          document.getElementById("stage" + i).style.display = "none";
        }
      }
      return state;
    });
  }

  const onSelectDropdownCompanyEmployeeNumber = (value: string) => {
    setCompanyEmployeeNumber(value);
    document.getElementById("company_employee_number").innerHTML = value;
    validSelectDropdownCompanyEmployeeNumber();
  }

  const onSubmitCompanyForm = async (values, errors) => {
    const country = countryCodes[selectedCountry[0]?.value] || selectedCountry[0]?.value?.toUpperCase();

    if (!validSelectDropdownCompanyEmployeeNumber()) {
      const body = {
        "company_name": companyName,
        "employee_amount": companyEmployeeNumber,
        "email": userEmail,
        "password": userPassword,
        "user_name": userName,
        "user_lastname": userLastname,
        "phone": agentPhone,
        "document_number": documentNumber.toLocaleUpperCase().replace(/[^a-zA-Z0-9]/g, ''),
        "country": country
      };

      const headers = {
        "Content-Type": "application/json; charset=utf-8"
      };

      // setIsLoading(true);
      authenticationRequestsConst.RegisterCompany(
        body,
        { headers },
        (response: Object) => {
          return response
        },
        (data: { data: { subdomain: string; }; }) => {
          setSubdomain(data.data.subdomain);
          clickStage(true);
          // setIsLoading(false);
          return data
        },
        (error: { response: { status: number; data: { code_cxpress: number } } }) => {
          if (error.response.status === 500) {
            dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }));
          } else {
            if (error?.response?.data?.code_cxpress === 1005) {
              document.getElementById("feedbackCNPJ").innerHTML = t("register.errors.already_used_error_document");
              document.getElementById("feedbackCNPJ").style.display = "block";
            }
            else {
              // document.getElementById("feedbackCNPJ").innerHTML = verifyCode(error.response.data.code_cxpress, t);
              document.getElementById("feedbackCNPJ").innerHTML = t("register.errors.invalid_document");
              document.getElementById("feedbackCNPJ").style.display = "block";
              setShowfeedbackDocumentNumber(true);
            }
          }
          //}
        }, navigate, dispatch, setIsLoading,
      );
    } else {
      if (document.getElementById("feedbackCompany_employee_number")) {
        document.getElementById("feedbackCompany_employee_number").innerHTML = t("register.errors.select_number_employees");
        document.getElementById("feedbackCompany_employee_number").style.display = "block";
      }
    }
  }

  return <RegisterForm
    isMobile={props.isMobile}
    stage={stage}
    t={t}
    progress={progress}
    setUserEmail={setUserEmail}
    validateEmailForm={validateEmailForm}
    setOptionsCountry={setOptionsCountry}
    selectedCountry={selectedCountry}
    setSelectedCountry={setSelectedCountry}
    showfeedbackEmail={showfeedbackEmail}
    validateCountry={validateCountry}
    setuserName={setuserName}
    setuserLastname={setuserLastname}
    validateRegisterForm={validateRegisterForm}
    showfeedbackName={showfeedbackName}
    showfeedbackLastname={showfeedbackLastname}
    setAgentPhone={setAgentPhone}
    switchLanguage={switchLanguage}
    setDocumentNumber={setDocumentNumber}
    validatePhone={validatePhone}
    Setcompany_name={setCompanyName}
    Setuser_password={setUserPassword}
    validateCompanyForm={validateCompanyForm}
    countryCodes={countryCodes}
    validSelectDropdownCompanyEmployeeNumber={validSelectDropdownCompanyEmployeeNumber}
    companyName={companyName}
    companyEmployeeNumber={companyEmployeeNumber}
    userEmail={userEmail}
    userPassword={userPassword}
    userName={userName}
    userLastname={userLastname}
    agentPhone={agentPhone}
    documentNumber={documentNumber}
    authenticationRequestsConst={authenticationRequestsConst}
    setSubdomain={setSubdomain}
    dispatch={dispatch}
    setShowfeedbackDocumentNumber={setShowfeedbackDocumentNumber}
    navigate={navigate}
    setIsLoading={setIsLoading}
    showfeedbackCompanyName={showfeedbackCompanyName}
    onSelectDropdownCompanyEmployeeNumber={onSelectDropdownCompanyEmployeeNumber}
    switchDocument={switchDocument}
    ShowfeedbackDocument_number={showfeedbackDocumentNumber}
    showfeedbackPassword={showfeedbackPassword}
    showPassword={showPassword}
    clickToViewPassword={clickToViewPassword}
    subdomain={subdomain}
    resendEmailConfirmation={resendEmailConfirmation}
    optionsCountry={optionsCountry}
    onSubmitCompanyForm={onSubmitCompanyForm} 
    clickStage={clickStage}  
  />
}

export default RegisterFormControler;