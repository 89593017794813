import './../registerForm/styles.scss';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import HomeRegisterDesktop from '.';

const HomeRegisterDesktopControler = () => {
    const [isLoading, /*setIsLoading*/] = useState(false);
    const { t } = useTranslation();

    return <HomeRegisterDesktop t={t} isLoading={isLoading} />
}

export default HomeRegisterDesktopControler;