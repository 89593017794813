import { useState } from "react";
import HomeRegisterMobile from "."
import { useTranslation } from "react-i18next";

const HomeRegisterMobileController = () => {
    const [isLoading, /*setIsLoading*/] = useState(false);
    const { t } = useTranslation();

    return <HomeRegisterMobile t={t} isLoading={isLoading} />
}

export default HomeRegisterMobileController;