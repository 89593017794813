import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ModalRegisterCustomFieldTickets from '.';
import { useTranslation } from 'react-i18next';

const ModalRegisterCustomFieldTicketsController = ({ show, handleClose, callback }) => {
    const { t } = useTranslation()

    const [isLoading, setIsLoading] = useState(false)
    const [validated] = useState(false);
    const [name, setName] = useState('');
    const [min, setMin] = useState<number>();
    const [max, setMax] = useState<number>();
    const [showFeedbackName, setShowFeedbackName] = useState(false);
    const [type1, setType1] = useState([
        { 'label': t('configurations_ticket.modal_register_custom_field.text_type'), 'value': t('configurations_ticket.modal_register_custom_field.text_type') },
        { 'label': t('configurations_ticket.modal_register_custom_field.number_type'), 'value': t('configurations_ticket.modal_register_custom_field.number_type') },
        { 'label': t('configurations_ticket.modal_register_custom_field.alphanumeric_type'), 'value': t('configurations_ticket.modal_register_custom_field.alphanumeric_type') },]);
    const [type, setType] = useState([
        { 'label': t('configurations_ticket.modal_register_custom_field.simple_text_label'), 'value': t('configurations_ticket.modal_register_custom_field.simple_text_value') },
        { 'label': t('configurations_ticket.modal_register_custom_field.multiline_text_label'), 'value': t('configurations_ticket.modal_register_custom_field.multiline_text_value') },
        /*{ 'label': 'Seleção simples', 'value': 'Seleção simples' },
    { 'label': 'Seleção múltipla', 'value': 'Seleção múltipla' }*/]);
    const [selectedType1, setSelectedType1] = useState([])
    const [selectedType, setSelectedType] = useState([])
    const [selectedOptions, setSelectedOptions] = useState([])
    const [selectedsOptions, setSelectedsOptions] = useState([])
    const [showFeedbackOptionList, /*setShowFeedbackOptionList*/] = useState(false)
    const [/*defaultValue*/, setDefaultValue] = useState('')
    const [labelledOptions, setLabelledOptions] = useState([])
    const [placeholder, setPlaceholder] = useState('')
    const [showFeedbackPlaceholder, setShowFeedbackPlaceholder] = useState(false)
    const [customTicketIsRequired, setCustomTicketIsRequired] = useState(false)
    const [showFeedbackMin, setShowFeedbackMin] = useState(false);
    const [showFeedbackMax, setShowFeedbackMax] = useState(false);
    const entityId = '250204ce-44eb-4cdc-865a-f54eed9c6496'

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const clearFields = () => {
        setName('')
        setShowFeedbackName(false)
        setSelectedType([])
        setSelectedType1([])
        setSelectedsOptions([])
        setSelectedOptions([])
        setPlaceholder('')
        setShowFeedbackPlaceholder(false)
        handleClose()
        setCustomTicketIsRequired(false)
        setShowFeedbackMin(false)
        setShowFeedbackMax(false)
    }

    const onChangeTags = (e: { detail: { tagify: { getCleanValue: Function; }; }; }) => {
        setSelectedOptions(e.detail.tagify.getCleanValue().map((tag) => {
            return tag.value;
        }));
    }

    useEffect(() => {
        if (selectedOptions.length > 0) {
            setLabelledOptions(selectedOptions ? selectedOptions?.map((option, index) => {
                return ({ label: option, value: option })
            }) : [])
        }
    }, [selectedOptions])

    return <ModalRegisterCustomFieldTickets
        show={show}
        clearFields={clearFields}
        type={type}
        type1={type1}
        setName={setName}
        setType={setType}
        setType1={setType1}
        setSelectedOptions={setSelectedOptions}
        setDefaultValue={setDefaultValue}
        setPlaceholder={setPlaceholder}
        setShowFeedbackName={setShowFeedbackName}
        showFeedbackMin={showFeedbackMin}
        showFeedbackMax={showFeedbackMax}
        setShowFeedbackPlaceholder={setShowFeedbackPlaceholder}
        placeholder={placeholder}
        entityId={entityId}
        selectedType={selectedType}
        selectedType1={selectedType1}
        labelledOptions={labelledOptions}
        selectedsOptions={selectedsOptions}
        dispatch={dispatch}
        callback={callback}
        navigate={navigate}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        validated={validated}
        showFeedbackName={showFeedbackName}
        setShowFeedbackMin={setShowFeedbackMin}
        setShowFeedbackMax={setShowFeedbackMax}
        setSelectedType={setSelectedType}
        setSelectedType1={setSelectedType1}
        onChangeTags={onChangeTags}
        selectedOptions={selectedOptions}
        showFeedbackOptionList={showFeedbackOptionList}
        setLabelledOptions={setLabelledOptions}
        setSelectedsOptions={setSelectedsOptions}
        showFeedbackPlaceholder={showFeedbackPlaceholder}
        name={name}
        t={t}
        customTicketIsRequired={customTicketIsRequired}
        setCustomTicketIsRequired={setCustomTicketIsRequired}
        min={min}
        max={max}
        setMin={setMin}
        setMax={setMax}

    />
}

export default ModalRegisterCustomFieldTicketsController