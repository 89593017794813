import React from 'react'
import { Modal } from 'react-bootstrap'
import "./styles.scss"
import { IconAlertCircleFilled, IconAlertTriangleFilled, IconCircleCheckFilled } from '@tabler/icons-react'

const ModalEmailActivity = ({
    show,
    closeButton,
    iconStatus,
    data,
    convertDate,
    convertStatus,
    info
}) => {

    return (
        <Modal show={show} onHide={closeButton} centered className='modalActivity' size='sm'>
            <Modal.Header closeButton>
                <Modal.Title style={{ padding: '0 5px', font: 'normal normal bold calc(0.4em + 0.9vw) Montserrat' }}> Atividades do e-mail</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ padding: '0 22px 10px 22px' }} className='activityContainer'>
                <div className='cardInfo'>
                    <label>De</label>
                    <br />
                    <span >{info.from_email}</span>
                </div>

                <div className='cardInfo'>
                    <label>Para</label>
                    <br />
                    <span >{info.to_email}</span>

                </div>

                <div className='cardInfo'>

                    <label>Assunto</label>
                    <br />
                    <span >{info.subject}</span>

                </div>

                <div className='cardInfo'>

                    <label>ID da mensagem</label>
                    <br />
                    <span >{info.msg_id}</span>
                </div>

                <div className='cardInfo cardHistoric'>
                    <label style={{marginBottom: '10px'}}>Histórico de eventos</label>

                    <div style={{ maxHeight: '24vh', scrollbarWidth: 'thin', overflow: 'auto' }}>
                    {
                        data ? 
                            data.map(item => (
                            <div className='cardHistoric'>
                                <span className='titleHistoric' style={{ paddingLeft: '3px', paddingBottom: '5px' }}>{convertStatus(item.status)} por {item.from_email}</span>
                                <div className='d-flex justify-content-between' style={{marginTop: '4px'}}>
                                    <div className='d-flex align-items-center'>
                                        {
                                            iconStatus(item.status)
                                        }
                                        <span style={{ marginLeft: '5px', color: 'black' }}>{convertStatus(item.status)}</span>

                                    </div>
                                    <span className='d-flex align-items-center'style={{ color: '#707070' }}>{convertDate(item.last_event_time)}</span>
                                </div>
                            </div>
                            ))
                        :
                        <></>
                    }
                    </div>
                    
                </div>

            </Modal.Body>
        </Modal>
    )
}

export default ModalEmailActivity;