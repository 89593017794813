export default class SocketEvents {
    // Notification Socket
    public static newConsumerMessage = "new-consumer-message";
    public static newTicketFromConsumer = "new-ticket-from-consumer";
    public static readNotification = "read-notification";
    public static ticketUpdate = "ticket-update";
    public static ticketDeleted = "ticket-deleted";
    public static ticketBlock = "ticket-block";
    public static botCxpressUpdatedFiles = "botcxpress-updated-files";
    public static statusEmployee = "status-employee";
    public static errorOnUpdateStatus = "error-on-update-status-employee";
    public static ticketNotification = "ticket-notification";
    public static taskNotification = "task-notification";
    public static newTicketFromConsumerTelephony = "new-ticket-from-consumer-telephony";
    public static disconnectOldLoggedUsers = "disconnect-old-logged-users";
    public static newAutomaticMessage = "new-automatic-message";
}