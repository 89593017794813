import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BodyInterface } from "./indexModel";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AppRequesterController } from "../../services/appRequester/appRequesterController";
import Utils from "../../core/shared/utils";
import ForgotPassword from ".";
import { setShowAlertFeedback } from "../../store/internal";

const ForgotPasswordController = () => {
    // const host: string = window.location.host;
    // const arr = host.split(".").slice(0, host.includes("localhost") ? -1 : -2);

    const [userEmail, setUserEmail] = useState("");
    const [showfeedback, setShowfeedback] = useState(false);
    // const [subdomain, setSubDomain] = useState(arr[0]);
    const [path, setPath] = useState("")

    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const appRequesterConst = new AppRequesterController();
    const { t } = useTranslation();

    const urlCx = window.location.href

    useEffect(() => {
        setPath(urlCx.split('.')[0].split('//').pop()) 
    }, [urlCx, path, setPath])

    const resendEmailNewPassword = async () => {
        const JsonSend = {
            "email": userEmail,
            "subdomain": path
        };
        
        const headers = {
            "Content-Type": "application/json; charset=utf-8"
        };

        appRequesterConst.Post(
            '/authentication/request-reset-password',
            JsonSend,
            { headers: headers },
            (response: Object) => {
                return response;
            },
            (data) => {
                if (data.status) {
                    dispatch(setShowAlertFeedback({ message: t("forgot_password.new_email_sended"), visibility: true, signalIcon: true }))
                }
            },
            (error:Object) => {},
            navigate,
            dispatch,
            setIsLoading
        );
        /*
        await Api.post('/authentication/request-reset-password', JsonSend, { headers }).then((response) => {
        return response;
        }).then(data => {
        setIsLoading(false);
        }).catch((error) => {
        setIsLoading(false);
        });*/
    }

    const validateForgotPassword = (values: BodyInterface) => {
        let errors = {};
        if (!values.email) {
            errors['email'] = t("forgotUrl.requiredField");
            setShowfeedback(true);
        } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(values.email)
        ) {
            errors['email'] = t("forgotUrl.invalidEmail");
            setShowfeedback(true);
        } else {
            setShowfeedback(false);
        }

        return errors;
    }

    const validate = (values) => {
        setUserEmail(values.email);
        return validateForgotPassword(values);
    }

    const onSubmit = async (values, errors) => {
        const JsonSend = {
            "email": values.email,
            "subdomain": path
        };

        const headers = {
            "Content-Type": "application/json; charset=utf-8"
        };

        Utils.changeStyleElementById('screen1', 'display', 'none');
        Utils.changeStyleElementById('screen2', 'display', 'block');

        setIsLoading(true);

        appRequesterConst.Post(
            '/authentication/request-reset-password',
            JsonSend,
            { headers },
            (response:Object) => {
            return response;
            },
            (data : Object) => { 
                setIsLoading(false);
                dispatch(setShowAlertFeedback({ message: t("forgot_password.email_sended"), visibility: true, signalIcon: true })) 
            },
            (error: { response: { status: number; }; }) => {
            setIsLoading(false);
            if (error.response !== undefined) {
                if (error.response.status === 400) {
                    document.getElementById("feedbackemail").innerHTML = t("forgot_password.email_not_founded");
                    setShowfeedback(true);
                }
            }
            },
            navigate,
            dispatch,
            setIsLoading
        );
        /*await Api.post('/authentication/request-reset-password', JsonSend, { headers }).then((response) => {
            return response;
        }).then(data => {
            setIsLoading(false);
        }).catch((error) => {
            setIsLoading(false);
            if (error.response !== undefined) {
            if (error.response.status == 400) {
                document.getElementById("feedbackemail").innerHTML = "E-mail não encontrado";
                setShowfeedback(true);
            }
            }
        });*/

    }

    return <ForgotPassword 
        isLoading={isLoading}
        validate={validate}
        onSubmit={onSubmit}
        showfeedback={showfeedback}
        userEmail={userEmail}
        resendEmailNewPassword={resendEmailNewPassword} 
        t={t}    
    />;
}

export default ForgotPasswordController;