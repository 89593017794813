type CSSProperties = {
    [key: string]: React.CSSProperties;
  };
  
  export class StyleSheet {
    static create<Styles extends CSSProperties>(styles: Styles): Styles {
      return styles;
    };
  };
  
  const styles = StyleSheet.create({
    textFontGlay: {
      textAlign: 'left',
      font: 'normal normal normal calc(0.2em + 0.7vw) Montserrat',
      letterSpacing: '0px',
      color: '#707070',
      opacity: '1',
    },
    textBlack: {
      textAlign: 'left',
      font: 'normal normal bold calc(0.5em + 0.7vw) Montserrat',
      letterSpacing: '0px',
      color: '#1E1E1E',
      opacity: '1',
    },
    inputGray:{
      height: '1vh',
    },
    textRowCollums: {
      textAlign: 'left',
      font: 'normal normal bold calc(0.5em + 0.5vw) Montserrat',
      letterSpacing: '0px',
      color: '#1E1E1E',
      opacity: '1',
    },
    changeEditorDropzone: { 
        width: '100%', 
        display: 'flex', 
        overflowY: 'hidden'
    },
    dragDropzone: {
        width: '100%',
        height: '5vh',
        backgroundColor: 'white',
        border: '0.13rem dashed #E8E8E8',
        borderRadius: '4px',
        cursor: 'pointer',
        transition: 'height 0.2s ease',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflowY: 'hidden',
        font: 'normal normal normal calc(0.2em + 0.7vw) Montserrat',
        letterSpacing: '0px',
        color: '#BCBCBC',
        opacity: '1',
    },
  });
  
  export default styles;