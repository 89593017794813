import { useState, useEffect, FC } from 'react';
import { useDispatch } from 'react-redux'
import {
    setRegisterAgentsModal,
} from '../../../../store/internal'
import { AppRequesterController } from '../../../../services/appRequester/appRequesterController';
import { useNavigate } from 'react-router-dom';
import { ModalRegisterAgentsInterface } from './indexModel';
import { useTranslation } from 'react-i18next';
import ModalRegisterAgents from '.';

const AppRequesterConst = new AppRequesterController();

const ModalRegisterAgentsController: FC<ModalRegisterAgentsInterface> = (props) => {

    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false)
    const [validated] = useState(false);

    const [agent_name, SetAgents_name] = useState(null as string || null);
    const [agent_email, SetAgent_email] = useState(null as string || null);
    const [agent_cpf, SetAgent_cpf] = useState(null as string || null);
    const [agent_phone, SetAgent_phone] = useState(null as string || null);
    const [agent_observations, SetAgent_observations] = useState(null as string || null);
    const [agent_isAdministrator, SetAgent_isAdministrator] = useState(false);
    const [agent_profile, setAgent_profile] = useState('');
    const [agent_role, setAgent_role] = useState('');

    const [showfeedbackObs, setShowfeedbackObs] = useState(false);
    const [showfeedbackEmail, setShowfeedbackEmail] = useState(false);
    const [showfeedbackName, setShowfeedbackName] = useState(false);
    const [showfeedbackPhone, setShowfeedbackPhone] = useState(false);
    const [showfeedbackCpf, setShowfeedbackCpf] = useState(false);

    // const [phoneTag] = useState("phone");
    // const [phoneError, setPhoneError] = useState(null);

    const [modalShow, setModalShow] = useState(props.visibility);
    const [border, setBorder] = useState('')

    const [options_country, setOptions_country] = useState([
        { label: t("home_agents.register_modal.form_country.options_country.brazil"), value: 'pt-br' },
        { label: t("home_agents.register_modal.form_country.options_country.portugal"), value: 'pt-pt' },
        { label: t("home_agents.register_modal.form_country.options_country.spain"), value: 'es' },
        { label: t("home_agents.register_modal.form_country.options_country.france"), value: 'fr' },
        { label: t("home_agents.register_modal.form_country.options_country.uk"), value: 'en' },
    ]);
    const [selected_country, setSelected_country] = useState([{ label: t("home_agents.register_modal.form_country.select_some_items"), value: '' }]);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        setModalShow(props.visibility);
        setBorder('')
        setShowfeedbackPhone(false)
    }, [props.visibility, agent_phone])

    const Hide = () => {
        setShowfeedbackObs(false);
        setShowfeedbackEmail(false);
        setShowfeedbackName(false);
        setShowfeedbackPhone(false);
        setShowfeedbackCpf(false);
        dispatch(setRegisterAgentsModal(false));
        setModalShow(false);
        SetAgent_isAdministrator(false)
        setSelected_country([{ label: "Brasil", value: 'pt-br' }]);
        setShowfeedbackName(false);
        setShowfeedbackEmail(false);
        setShowfeedbackCpf(false);
        setSelected_country([{ label: t("home_agents.register_modal.form_country.select_some_items"), value: '' }])
    }

    const Validate = (values, language) => {
        let errors = {};

        if (!values.name) {
            errors["name"] = `${t('home_agents.register_modal.validate.name')}`;
            setShowfeedbackName(true);
        } else {
            setShowfeedbackName(false);
        }

        if (!values.email) {
            errors["email"] = `${t('home_agents.register_modal.validate.email_a')}`;
            setShowfeedbackEmail(true);
        }
        else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(values.email) && values.email) {
            errors["email"] = `${t('home_agents.register_modal.validate.email_b')}`;
            setShowfeedbackEmail(true);
        } else {
            setShowfeedbackEmail(false);
        }
        let number_cpf;
        if (values.cpf)
            number_cpf = values.cpf.replace(/[^a-zA-Z0-9]/g, '').length;
        if (language === 'pt-pt') {
            if (number_cpf !== 0 && number_cpf !== 9 && number_cpf !== 11 && values.cpf) {
                errors["cpf"] = t('home_agents.register_modal.submit.document_error', { document_type: switchDocument()});
                setShowfeedbackCpf(true);
            } else {
                setShowfeedbackCpf(false);
            }
        } else if (language === 'pt-br') {
            if (number_cpf !== 0 && number_cpf !== 11 && number_cpf !== 14 && values.cpf) {
                errors["cpf"] = t('home_agents.register_modal.submit.document_error', { document_type: switchDocument()});
                setShowfeedbackCpf(true);
            } else {
                setShowfeedbackCpf(false);
            }
        }
        else if (language === 'es') {
            if (number_cpf !== 0 && number_cpf !== 9 && number_cpf !== 11 && values.cpf) {
                errors["cpf"] = t('home_agents.register_modal.submit.document_error', { document_type: switchDocument()});
                setShowfeedbackCpf(true);
            } else {
                setShowfeedbackCpf(false);
            }
        }
        else if (language === 'fr') {
            if (number_cpf !== 0 && number_cpf !== 15 && number_cpf !== 18 && values.cpf) {
                errors["cpf"] = t('home_agents.register_modal.submit.document_error', { document_type: switchDocument()});
                setShowfeedbackCpf(true);
            } else {
                setShowfeedbackCpf(false);
            }
        }
        else if (language === 'en') {
            if (number_cpf !== 0 && number_cpf !== 9 && number_cpf !== 12 && values.cpf) {
                errors["cpf"] = t('home_agents.register_modal.submit.document_error', { document_type: switchDocument()});
                setShowfeedbackCpf(true);
            } else {
                setShowfeedbackCpf(false);
            }
        }

        // if (phoneError !== null)
        //     errors[phoneTag] = phoneError;

        if (values.observations.length > 100) {
            errors["observations"] = `${t('home_agents.register_modal.validate.observations')}`;
            setShowfeedbackObs(true);
        } else {
            setShowfeedbackObs(false);
        }

        return errors;
    }

    const switchLanguage = () => {
        if (selected_country[0]?.value === 'pt-br') {
            return ['br'];
        } else if (selected_country[0]?.value === 'pt-pt') {
            return ['pt'];
        }  else if (selected_country[0]?.value === 'es') {
            return ['es'];
        } else if (selected_country[0]?.value === 'fr') {
            return ['fr'];
        } else if (selected_country[0]?.value === 'en') {
            return ['gb'];
        } else {
            return ['br'];
        }
    }

    const switchDocument = () => {
        if (selected_country[0]?.value === 'pt-br') {
            return 'CPF';
        } else if (selected_country[0]?.value === 'pt-pt') {
            return 'NIF';
        } else if (selected_country[0]?.value === 'es') {
            return 'NIF';
        } else if (selected_country[0]?.value === 'fr') {
            return 'NIR';
        } else if (selected_country[0]?.value === 'en') {
            return 'NINO';
        } else {
            return 'CPF';
        }
    }

    return <ModalRegisterAgents
        t={t}
        modalShow={modalShow}
        Hide={Hide}
        SetAgents_name={SetAgents_name}
        SetAgent_email={SetAgent_email}
        SetAgent_cpf={SetAgent_cpf}
        SetAgent_observations={SetAgent_observations}
        setAgent_profile={setAgent_profile}
        agent_isAdministrator={agent_isAdministrator}
        SetAgent_isAdministrator={SetAgent_isAdministrator}
        SetAgent_phone={SetAgent_phone}
        agent_name={agent_name}
        agent_email={agent_email}
        agent_cpf={agent_cpf}
        agent_phone={agent_phone}
        agent_observations={agent_observations}
        agent_profile={agent_profile}
        showfeedbackObs={showfeedbackObs}
        showfeedbackEmail={showfeedbackEmail}
        showfeedbackName={showfeedbackName}
        showfeedbackPhone={showfeedbackPhone}
        showfeedbackCpf={showfeedbackCpf}
        Validate={Validate}
        validated={validated}
        props={props}
        AppRequesterConst={AppRequesterConst}
        dispatch={dispatch}
        navigate={navigate}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        border={border}
        setBorder={setBorder}
        setShowfeedbackPhone={setShowfeedbackPhone}
        options_country={options_country}
        setOptions_country={setOptions_country}
        selected_country={selected_country}
        setSelected_country={setSelected_country}
        switchLanguage={switchLanguage}
        switchDocument={switchDocument}
        setAgent_role={setAgent_role}
        agent_role={agent_role}
    />
}

export default ModalRegisterAgentsController;