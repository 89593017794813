import { useDispatch, useSelector } from "react-redux";
import ListTemplateMessage from ".";
import { AppRequesterController } from "../../../../../../services/appRequester/appRequesterController";
import { getIdCompany } from "../../../../../../store/company";
import { getProfileId } from "../../../../../../store/user";
import { getToken } from "../../../../../../store/token";
import { TokenInterface } from "../../../../../../services/requestsInterfacesModel";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Button, Popover } from "react-bootstrap";
import { setShowAlertFeedback, setShowConfirmationModal } from "../../../../../../store/internal";
import { useTranslation } from "react-i18next";
import { Brokers } from "../../../../../../core/enums/brokers";

const AppRequesterConst = new AppRequesterController();
const ListTemplateMessageController = (props) => {

  const [listTemplateMesage, setListTemplateMesage] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  
  const { t } = useTranslation();

  const values: TokenInterface = {
    user: {
      profile_id: useSelector(getProfileId)
    },
    token: {
      value: useSelector(getToken)
    },
  };
  
  useEffect(() => {
    const controller = new AbortController();
    getAllTemplateMessages(controller, props.company_id);
  
    return () => {
      controller.abort();
    }
  }, [props.company_id]);

  useEffect(() => {
    if (props.refreshTemplate === true) {
      const controller = new AbortController();
      getAllTemplateMessages(controller, props.company_id);
      props.setRefreshTemplate(false)
    }
  }, [props.refreshTemplate])

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getAllTemplateMessages = async (controller: AbortController, companyId: string) => {
    const signal = controller.signal;

    const headers = {
      "Content-Type": "application/json; charset=utf-8",
      "Authorization": "Bearer " + values.token.value
    };

    await AppRequesterConst.Get(
        `/template-message/whatsapp/${companyId}?broker_id=${Brokers.Smarters}`, { headers, /* signal */ },
        (response: Object) => {},
        (data: any) => {
            if(data?.status === 200 && data?.data?.templateMessages?.length > 0) {
              setListTemplateMesage(data.data.templateMessages);
            }
        },
        (error: { response: { status: number } }) => {
            dispatch(setShowAlertFeedback({ message: "Ocorreu um erro inesperado, tente novamente mais tarde.", visibility: true, signalIcon: false }));
            
        }, navigate, dispatch, props.setIsLoading, { }
    )
  }
  
  const updateListFunction = () => {
    setCurrentPage(currentPage + 1);
  };

  const deleteTemplate = async (id: string) => {
    const headers = {
      "Content-Type": "application/json; charset=utf-8",
      "Authorization": "Bearer " + values.token.value
    };

    await AppRequesterConst.Delete(
      '/template-message/' + id, { headers },
      (response: Object) => { },
      (data: Object) => {
        dispatch(setShowAlertFeedback({ message: t('home_departments.triage_tab.delete_success'), visibility: true, signalIcon: true }));
        // callbackModalTriage();
        props.setRefreshTemplate(true);
      },
      (error: Object) => {
        dispatch(setShowAlertFeedback({ message: t('home_departments.triage_tab.unexpected_error'), visibility: true, signalIcon: false }));
      },
      navigate,
      dispatch,
      props.setIsLoading,
    );
  }

  const deleteTemplateConfirm = (currentId) => {
    dispatch(setShowConfirmationModal({
      visibility: true, text: { "title": t('home_departments.template_message_tab.delete_template_message'), "body": t('home_departments.template_message_tab.confirm_delete_template_message'), "buttonConfirmationText": t('home_departments.template_message_tab.delete'), "buttonReturnText": t('home_departments.template_message_tab.cancel') },
      functionConfirmation: () => {
        deleteTemplate(currentId)
      }
    }));
  }

  const editTemplateMessage = (id: string) => {
    props.setIsLoading(true);
    let template = listTemplateMesage.find((item) => item.id === id);
    template['whatsapp_number'] = props.whatsappKeys.find((item) => item.value === template.whatsapp_keys_id);
    template['category'] = props.categories.find((item) => item.value === template.category_id);
    props.setCurrentTemplate(template);
    props.handleOpenCloseVisualizeTemplateMessage();
    props.setIsLoading(false);
  }

  const popoverActions = (currentId) => (
    <Popover id={currentId} style={{ zIndex: '500', backgroundColor: '#e6e6e6', border: 'none', color: '#40484E', font: 'var(--px15_19-font-Roboto)' }}>
      <Popover.Body className="nopadding">
        <Button onClick={() => editTemplateMessage(currentId)} className="m-1" style={{ backgroundColor: 'transparent', border: 'none', color: 'black', fontSize: 'inherit' }}>{t('home_departments.template_message_tab.see_information')}</Button>
        <div style={{ backgroundColor: '#707070', opacity: '0.5', height: '1px', width: '100%' }}></div>
        <Button onClick={() => deleteTemplateConfirm(currentId)} className="m-1" style={{ backgroundColor: 'transparent', border: 'none', color: 'black', fontSize: 'inherit' }}>{t('home_departments.template_message_tab.delete_template_message')}</Button>
      </Popover.Body>
    </Popover>
  );

  return (
    <ListTemplateMessage 
      listTemplateMesage={listTemplateMesage}
      t={props.t}
      popoverActions={popoverActions}
      updateListFunction={updateListFunction}
    />
  )
}

export default ListTemplateMessageController;