import React from 'react';

import allStyles from './styles';

import {
  Modal,
  Row,
  Container,
  Col,
  Button,
  Form,
  InputGroup,
  FormControl,
}
  from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Formik } from 'formik';
import InputMask from 'react-input-mask';
import constsApi from '../../../../services/constsApi';

import { setShowAlertFeedback } from '../../../../store/internal'

import MaskController from '../../../../core/shared/mask-controller';
import { verifyCode } from '../../../../services/codeCxpressInterface';

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css'
import pt from 'react-phone-input-2/lang/pt.json'
import SelectComponentController from '../selectComponent/indexController';

const ModalEditAgents = ({
  t,
  modalShow,
  Hide,
  props,
  dispatch,
  navigate,
  setIsLoading,
  setShowfeedbackPhone,
  showfeedbackPhone,
  border,
  setBorder,
  validated,
  SetAgents_name,
  SetAgent_email,
  SetAgent_cpf,
  SetAgent_observations,
  setAgent_profile,
  agent_isAdministrator,
  Validate,
  agent_name,
  agent_profile,
  agent_email,
  agent_cpf,
  agent_phone,
  agent_observations,
  AppRequesterConst,
  showfeedbackName,
  showfeedbackEmail,
  showfeedbackCpf,
  showfeedbackObs,
  SetAgent_isAdministrator,
  SetAgent_phone,
  options_country,
  setOptions_country,
  selected_country,
  setSelected_country,
  switchLanguage,
  switchDocument,
  agent_role,
  setAgent_role,
  agent_owner
}) => {
  return (
    <Modal show={modalShow} onHide={() => Hide()} aria-labelledby="" size="lg" centered={true} >
      <Modal.Header closeButton style={{ marginLeft: "6%", marginTop: "1%", marginBottom: "-1%" }}>
        <Modal.Title style={allStyles.textBlack}>
          {t('home_agents.edit_modal.edit_data')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ marginLeft: "4%", marginRight: "4%", marginBottom: "2%" }}>
        <Container>
          <Formik
            initialValues={{
              name: (props.data && props.data.name != null) ? props.data.name : '',
              email: (props.data && props.data.email != null) ? props.data.email : '',
              cpf: (props.data && props.data.document_number != null) ? props.data.document_number : '',
              phone: (props.data && props.data.phone != null) ? props.data.phone : '',
              observations: (props.data && props.data.observations != null) ? props.data.observations : '',
              isAdministrator: (props.data && props.data.profile_id != null && props.data.profile_id === constsApi.profile[0].id) ? true : false,
              cargo: (props?.data && props?.data?.role != null) ? props?.data?.role : '',
            }}
            validate={values => {
              SetAgents_name(values.name);
              SetAgent_email(values.email);
              SetAgent_cpf(values.cpf);
              // SetAgent_phone(values.phone);
              SetAgent_observations(values.observations);
              setAgent_profile(agent_owner ? constsApi.profile[2].id : agent_isAdministrator === true ? constsApi.profile[0].id : constsApi.profile[1].id);
              setAgent_role(values?.cargo)
              return Validate(values, selected_country[0]?.value)
            }}

            onSubmit={async () => {
              let JsonSend = {
                "name": agent_name,
                "id": props.data.user_id,
                "profile_id": agent_profile,
                // "employee_language": selected_country[0]?.value
              }

              if (selected_country[0]?.value === 'pt-pt') {
                JsonSend['country'] = 'PT'
              }
              else if (selected_country[0]?.value === 'pt-br') {
                JsonSend['country'] = 'BR'
              }
              else if (selected_country[0]?.value === 'en') {
                JsonSend['country'] = 'GB'
              }
              else {
                JsonSend['country'] = selected_country[0]?.value.toLocaleUpperCase()
              }

              if (agent_email !== '') {
                JsonSend['email'] = agent_email;
              }

              if (agent_cpf !== '') {
                JsonSend['document_number'] = agent_cpf.toLocaleUpperCase().replace(/[^a-zA-Z0-9]/g, '');
              }
              if (agent_phone != null && agent_phone !== "" && agent_phone !== "55" && agent_phone !=="351") {
                JsonSend['phone'] = agent_phone.replace(/\D/g, "");
              }

              if (agent_observations !== '') {
                JsonSend['observations'] = agent_observations;
              }

              if (agent_role !== '') {
                JsonSend['role'] = agent_role;
              }

              const headers = {
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": "Bearer " + props.token
              };

              await AppRequesterConst.Put(
                '/agent', JsonSend, { headers },
                (response: Object) => {
                  return response;
                },
                (data: { data: { user_id: string; }; }) => {
                  dispatch(setShowAlertFeedback({ message: `${t('home_agents.edit_modal.submit.message')}`, visibility: true, signalIcon: true }));
                  if (props.callback !== undefined) {
                    props.callback(data.data.user_id);
                  }
                  Hide();
                },
                (error: { response: { data: { message: { context: { label: string; }; }[], code_cxpress: number; }; status: number }; }) => {
                  if (error.response && error.response.data && error.response.data.message && error.response.status && error.response.status === 400) {
                    dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }));
                  } else if (error.response && error.response.data.message[0].context.label === "email") {
                    dispatch(setShowAlertFeedback({ message: `${t('home_agents.edit_modal.submit.error_email')}`, visibility: true, signalIcon: false }));
                  } else if (error.response.status === 422 && error.response.data.message[0].context.label === "phone") {
                    setBorder('1px solid red')
                    setShowfeedbackPhone(true)
                  } else if (error.response.status === 422) {
                    dispatch(setShowAlertFeedback({ message: `${t('home_agents.register_modal.submit.document_error', { document_type: switchDocument()})}`, visibility: true, signalIcon: false }));
                  }else {
                    dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }));
                  }
                }, navigate, dispatch, setIsLoading
              );
            }}
          >
            {({
              values,
              setValues,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,

            }) => (
              <Form onSubmit={handleSubmit} noValidate validated={validated}>
                <Row style={allStyles.textFontGlay} className=" d-flex justify-content-center">
                  <Col md={6}>
                    <Form.Label htmlFor="name">{t('home_agents.edit_modal.form_name.name')}<span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black' }}>*</span></Form.Label>
                    <InputGroup className="mb-3">
                      <FormControl className="form-control-Default"
                        isInvalid={showfeedbackName}
                        placeholder={t('home_agents.edit_modal.form_name.placeholder')}
                        aria-label={t('home_agents.edit_modal.form_name.aria_label')}
                        aria-describedby={t('home_agents.edit_modal.form_name.aria_describedby')}
                        size='lg'
                        type='name'
                        id="agents_name"
                        name="name"
                        autoComplete='given-name'
                        maxLength={100}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.name}
                      >
                      </FormControl>
                      <Form.Control.Feedback type="invalid" id="feedbackname">
                        {errors.name}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Col>
                  <Col md={6}>
                    <Form.Label htmlFor="email">{t('home_agents.edit_modal.form_email.email')}<span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black' }}>*</span></Form.Label>
                    <InputGroup className="mb-3">
                      <FormControl className="form-control-Default"
                        isInvalid={showfeedbackEmail}
                        placeholder={t('home_agents.edit_modal.form_email.placeholder')}
                        aria-label={t('home_agents.edit_modal.form_email.aria_label')}
                        aria-describedby={t('home_agents.edit_modal.form_email.aria_describedby')}
                        size='lg'
                        type='email'
                        id="agents_email"
                        name="email"
                        autoComplete='email'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                      >
                      </FormControl>
                      <Form.Control.Feedback type="invalid" id="feedbackemail">
                        {errors.email}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Col>
                </Row>
                <Row style={allStyles.textFontGlay} className=" d-flex justify-content-center">
                  <Col sm={true}>
                    <Form.Label htmlFor="basic-url">{t('home_agents.register_modal.form_country.country_label')}</Form.Label>
                    <SelectComponentController
                      className={"multiselectForm mb-3"}
                      options={options_country}
                      setOptions={setOptions_country}
                      selecteds={selected_country}
                      setSelecteds={setSelected_country}
                      singleSelect={true}
                      isFormNoFilter={true}
                      title={"Selecione o tipo de disparo"}
                      overrideStrings={
                        {
                          "allItemsAreSelected": selected_country[0] ? selected_country[0].label : "",
                          "clearSearch": t('home_agents.register_modal.form_country.clear_search'),
                          "clearSelected": t('home_agents.register_modal.form_country.clear_selected'),
                          "noOptions": t('home_agents.register_modal.form_country.no_options'),
                          "search": t('home_agents.register_modal.form_country.search'),
                          "selectAll": t('home_agents.register_modal.form_country.select_all'),
                          "selectAllFiltered": t('home_agents.register_modal.form_country.selec_all_filtered'),
                          "selectSomeItems": t('home_agents.register_modal.form_country.select_some_items'),
                          "create": t('home_agents.register_modal.form_country.create'),
                        }
                      }
                      hasCreateItem={false}
                      hasNullOption={false}
                      hasAllOption={false}
                    />
                  </Col>
                </Row>
                <Row style={allStyles.textFontGlay} className=" d-flex justify-content-center">
                  <Col md={6}>
                    <Form.Label htmlFor="basic-url">{switchDocument()}</Form.Label>
                    <InputMask
                      mask={MaskController.maskCpfNif(values.cpf, selected_country[0]?.value)}
                      maskChar="_"
                      value={values.cpf}
                      onChange={handleChange}
                    >
                      {(inputProps) => <InputGroup className="mb-3">
                        <FormControl
                          isInvalid={showfeedbackCpf}
                          className="form-control-Default"
                          placeholder={`${t('home_agents.edit_modal.form_document_number.message_a')} ${switchDocument()} ${t('home_agents.edit_modal.form_document_number.message_b')}`}
                          aria-label={`${t('home_agents.edit_modal.form_document_number.message_a')} ${switchDocument()} ${t('home_agents.edit_modal.form_document_number.message_b')}`}
                          aria-describedby={`${t('home_agents.edit_modal.form_document_number.message_a')} ${switchDocument()} ${t('home_agents.edit_modal.form_document_number.message_b')}`}
                          type="text"
                          size='lg'
                          id="customers_cpf"
                          name="cpf"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          disabled={selected_country[0]?.value !== undefined ? false : true}
                        />
                        <Form.Control.Feedback type="invalid" id="feedbackcpf">
                          {errors.cpf}
                        </Form.Control.Feedback>
                      </InputGroup>
                      }
                    </InputMask>
                  </Col>
                  <Col md={6}>
                    <Form.Label htmlFor="basic-url">{t('home_agents.edit_modal.form_phone.phone')}</Form.Label>
                    <PhoneInput
                      specialLabel={null}
                      placeholder={t('home_agents.edit_modal.form_phone.placeholder')}
                      value={values.phone}
                      onChange={(e)=> SetAgent_phone(e)}
                      onBlur={handleBlur}
                      inputClass='form-control-Default form-control form-control-lg'
                      inputProps={{
                        name: 'Telefone',
                        required: true,
                        border: 'none',
                        style: {
                          width: '100%',
                          font: 'var(--px15_19-font-Roboto)',
                          border: border
                        }
                      }}
                      country={switchLanguage()[0]}
                      onlyCountries={['br', 'pt', 'es', 'fr', 'gb']}
                      localization={pt}
                      disableDropdown={true}
                    />
                    <div className='invalid-feedback' style={{ display: showfeedbackPhone ? "block" : "none", height: '0.75rem' }}>
                      {t('home_agents.edit_modal.form_phone.invalid')}
                    </div>
                  </Col>

                </Row>
                <Row className="d-flex justify-content-center">
                  <Col sm={true} style={allStyles.textFontGlay}>
                    <Form.Label htmlFor="role">{t('home_agents.edit_modal.form_role.role')}</Form.Label>
                    <InputGroup className="mb-3">
                      <FormControl className="form-control-Default"
                        placeholder={t('home_agents.edit_modal.form_role.placeholder')}
                        aria-label={t('home_agents.edit_modal.form_role.placeholder')}
                        aria-describedby={t('home_agents.edit_modal.form_role.placeholder')}
                        size='lg'
                        type='cargo'
                        id="agents_cargo"
                        name="cargo"
                        autoComplete='given-cargo'
                        maxLength={50}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.cargo}
                      >
                      </FormControl>
                    </InputGroup>
                  </Col>
                </Row>
                <Row className="d-flex justify-content-center">
                  <Col style={allStyles.textFontGlay}>
                    <Form.Label htmlFor="basic-url">{t('home_agents.edit_modal.form_observations.observations')}</Form.Label>
                    <InputGroup className="mb-3">
                      <FormControl className="form-control-Default-text-area" style={{ height: '10vh' }}
                        isInvalid={showfeedbackObs}
                        as="textarea"
                        placeholder={t('home_agents.edit_modal.form_observations.placeholder')}
                        aria-label={t('home_agents.edit_modal.form_observations.aria_label')}
                        aria-describedby={t('home_agents.edit_modal.form_observations.aria_describedby')}
                        size='lg'
                        type='text'
                        id="customers_observations"
                        name="observations"
                        maxLength={1000}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.observations}

                      >
                      </FormControl>
                      <Form.Control.Feedback type="invalid" id="feedbackobservations">
                        {errors.observations}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Col>
                </Row>
                {agent_isAdministrator != null &&
                  <Row className="d-flex justify-content-center">
                    <Col md={12} style={allStyles.textFontGlay}>
                      <Form.Label htmlFor="basic-url">{t('home_agents.edit_modal.is_admin')} &nbsp; &nbsp;</Form.Label>
                      <Form.Check name="radioIsAdministrator" label={t('home_agents.register_modal.no_label')} type={'radio'} checked={!agent_isAdministrator} onChange={() => { SetAgent_isAdministrator(false) }} inline></Form.Check>
                      <Form.Check name="radioIsAdministrator" label={t('home_agents.register_modal.yes_label')} type={'radio'} checked={agent_isAdministrator} onChange={() => { SetAgent_isAdministrator(true) }} inline></Form.Check>
                    </Col>
                  </Row>
                }
                <Row style={allStyles.textFontGlay} className="d-flex justify-content-center mt-4">
                  <Col md={6} className="d-flex justify-content-start">
                    <Button size='lg' className="buttonWhite" style={{ width: '50%' }} onClick={() => Hide()} >{t('home_agents.edit_modal.cancel')}</Button>
                  </Col>
                  <Col md={6} className="d-flex justify-content-end">
                    <Button type='submit' size='lg' className="buttonBlue" style={{ width: '50%' }}>{t('home_agents.edit_modal.save')}</Button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Container>
      </Modal.Body>
    </Modal>
  );
}

export default ModalEditAgents;
