import React from 'react';

import allStyles from './styles';

import {
  Modal,
  Row,
  Container,
  Col,
  Button,
  Form,
  InputGroup,
  FormControl,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Formik } from 'formik';

import {
  setShowAlertFeedback
} from '../../../../store/internal'
import { AppRequesterController } from '../../../../services/appRequester/appRequesterController';

import "@yaireo/tagify/dist/tagify.css"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import SelectComponentController from '../selectComponent/indexController';
import { IconTicket, IconUsers } from "@tabler/icons-react";

const AppRequesterConst = new AppRequesterController();

const ModalRegisterTriage = ({
  modalShow,
  Hide,
  setTriageName,
  setShowfeedbackName,
  setShowfeedbackInitialMessage,
  setShowfeedbackFinalMessage,
  triageName,
  triageIsActive,
  initialMessage,
  finalMessage,
  selectedChatbots,
  values,
  dispatch,
  props,
  navigate,
  isLoading,
  setIsLoading,
  validated,
  showfeedbackName,
  showfeedbackInitialMessage,
  showfeedbackFinalMessage,
  optionsChatbots,
  setOptionsChatbots,
  setSelectedChatbots,
  setTriageIsActive,
  setInitialMessage,
  setFinalMessage,
  ticketAvailable,
  triageFields,
  onDragEnd,
  getItemStyle,
  getListStyle,
  t,
  setTriageIsMandatory,
  triageIsMandatory,
}) => {
  return (
    <Modal show={modalShow} onHide={() => Hide()} aria-labelledby="" size="sm" centered={true} >
      <Modal.Header closeButton style={{ marginLeft: "6%", marginTop: "1%", marginBottom: "-1%" }}>
        <Modal.Title style={allStyles.textBlack}>
          {t('home_departments.modal_register_triage.create_triage')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ marginLeft: "4%", marginRight: "4%", marginBottom: "2%" }}>
        <Container>
          <Formik
            initialValues={{ name: '', initialMessage: '', finalMessage: '' }}
            validate={values => {
              let errors = {};
              setTriageName(values.name);
              setInitialMessage(values.initialMessage)
              setFinalMessage(values.finalMessage)

              if (!values.name) {
                errors["name"] = t('home_departments.modal_register_triage.validate.empty_field');
                setShowfeedbackName(true);
              } else {
                setShowfeedbackName(false);
              }

              if (!values.initialMessage) {
                errors["initialMessage"] = t('home_departments.modal_register_triage.validate.empty_field');
                setShowfeedbackInitialMessage(true);
              } else {
                setShowfeedbackInitialMessage(false);
              }

              if (!values.finalMessage) {
                errors["finalMessage"] = t('home_departments.modal_register_triage.validate.empty_field');
                setShowfeedbackFinalMessage(true);
              } else {
                setShowfeedbackFinalMessage(false);
              }

              return errors;
            }}

            onSubmit={async () => {
              let JsonSend_sector = {
                "name": triageName,
                "active": triageIsActive,
                "start_message": initialMessage,
                "end_message": finalMessage,
                "has_mandatory_fields": triageIsMandatory
              }

              if (triageIsActive) {
                if (selectedChatbots && selectedChatbots.length > 0) {
                  JsonSend_sector["channel_key_infos"] = selectedChatbots.map(({ value }) => value.id);
                }
                if (triageFields && triageFields.length > 0) {
                  JsonSend_sector["fields"] = triageFields.map((value) => { return { id: value.id, is_custom: value.is_custom, name: value.name } })
                }
              }

              const headers = {
                "Content-Type": "application/json; charset=utf-8",
                "Authorization": "Bearer " + values.token.value
              };

              await AppRequesterConst.Post(
                '/triage', JsonSend_sector, { headers },
                (response: Object) => {
                  return response;
                },
                (data: { data: { triage: { name: string; id: string; }; }; }) => {
                  dispatch(setShowAlertFeedback({ message: t('home_departments.modal_register_triage.create_success'), visibility: true, signalIcon: true }));
                  props.callback(data.data.triage.id);
                  Hide();
                },
                (error: { response: { status: number; data: { message: string; } } }) => {
                  if (error.response.status === 422) {
                    dispatch(setShowAlertFeedback({ message: error.response.data.message, visibility: true, signalIcon: false }))
                  } else {
                    dispatch(setShowAlertFeedback({ message: t('home_departments.modal_register_triage.unexpected_error'), visibility: true, signalIcon: false }));
                  }
                }, navigate, dispatch, setIsLoading
              );
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,

            }) => (
              <Form onSubmit={handleSubmit} noValidate validated={validated}>
                <Row style={allStyles.textFontGlay} className=" d-flex justify-content-center">
                  <Col sm={true}>
                    <Form.Label htmlFor="name">{t('home_departments.modal_register_triage.form_name.label')}<span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black' }}>*</span></Form.Label>
                    <InputGroup className="mb-3">
                      <FormControl className="form-control-Default"
                        isInvalid={showfeedbackName}
                        placeholder={t('home_departments.modal_register_triage.form_name.placeholder')}
                        aria-label={t('home_departments.modal_register_triage.form_name.placeholder')}
                        aria-describedby={t('home_departments.modal_register_triage.form_name.placeholder')}
                        size='lg'
                        type='name'
                        id="triageName"
                        name="name"
                        autoComplete='given-name'
                        maxLength={100}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.name}
                      >
                      </FormControl>
                      <Form.Control.Feedback type="invalid" id="feedbackname">
                        {errors.name}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Col>
                </Row>

                <div className="switch-input mb-3" >
                  <span style={allStyles.textFontGlay}>{t('home_departments.modal_register_triage.active_triage')}</span>
                  <OverlayTrigger
                    key='top'
                    placement='top'
                    overlay={
                      <Tooltip id='tooltip'>
                        {triageIsActive ?
                          t("home_departments.modal_register_triage.yes") :
                          t("home_departments.modal_register_triage.no")
                        }
                      </Tooltip>
                    }
                  >
                    <label className="switch">
                      <input
                        type="checkbox"
                        checked={triageIsActive}
                        onChange={triageIsActive ? () => setTriageIsActive(false) : () => setTriageIsActive(true)}
                      />
                      <span className="slider round"></span>
                    </label>
                  </OverlayTrigger>
                </div>

                <Row style={allStyles.textFontGlay} className=" d-flex justify-content-center">
                  <Form.Label htmlFor="name">{t('home_departments.modal_register_triage.form_initial_message.label')}<span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black' }}>*</span></Form.Label>
                  <InputGroup className="mb-2">
                    <Form.Control className="form-control-Default-text-area"
                      isInvalid={showfeedbackInitialMessage}
                      style={{ height: '10vh' }}
                      as="textarea"
                      placeholder={t('home_departments.modal_register_triage.form_initial_message.placeholder')}
                      aria-label={t('home_departments.modal_register_triage.form_initial_message.placeholder')}
                      aria-describedby={t('home_departments.modal_register_triage.form_initial_message.placeholder')}
                      size='lg'
                      type='text'
                      // componentClass="textarea"
                      id="initialMessage"
                      name="initialMessage"
                      maxLength={1000}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.initialMessage}
                    >
                    </Form.Control>
                    <Form.Control.Feedback type="invalid" id="setShowfeedbackInitialMessage">
                      {errors.initialMessage}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Row>

                <Row style={allStyles.textFontGlay} className=" d-flex justify-content-center">
                  <Form.Label htmlFor="name">{t('home_departments.modal_register_triage.form_final_message.label')}<span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black' }}>*</span></Form.Label>
                  <InputGroup className="mb-2">
                    <Form.Control className="form-control-Default-text-area"
                      isInvalid={showfeedbackFinalMessage}
                      style={{ height: '10vh' }}
                      as="textarea"
                      placeholder={t('home_departments.modal_register_triage.form_final_message.placeholder')}
                      aria-label={t('home_departments.modal_register_triage.form_final_message.placeholder')}
                      aria-describedby={t('home_departments.modal_register_triage.form_final_message.placeholder')}
                      size='lg'
                      type='text'
                      // componentClass="textarea"
                      id="finalMessage"
                      name="finalMessage"
                      maxLength={1000}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.finalMessage}
                    >
                    </Form.Control>
                    <Form.Control.Feedback type="invalid" id="setShowfeedbackFinalMessage">
                      {errors.finalMessage}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Row>

                {triageIsActive ?
                  <>
                    <Row style={allStyles.textFontGlay} className=" d-flex justify-content-center">
                      <Col sm={true}>
                        <Form.Label htmlFor="basic-url">{t('home_departments.modal_register_triage.form_chatbots.label')}<span style={{ font: 'normal bold normal calc(0.2em + 0.7vw) Montserrat', color: 'black' }}>*</span></Form.Label>
                        {/* <pre>{JSON.stringify(selectedChatbots)}</pre> */}
                        <SelectComponentController
                          //  multiselectFormError
                          // className={'multiselectForm mb-3'}
                          options={optionsChatbots}
                          setOptions={setOptionsChatbots}
                          selecteds={selectedChatbots}
                          setSelecteds={setSelectedChatbots}
                          singleSelect={false}
                          isFormNoFilter={true}
                          title={t('home_departments.modal_register_triage.form_chatbots.placeholder')}
                          overrideStrings={
                            {
                              "allItemsAreSelected": t('home_departments.modal_register_triage.form_chatbots.override_strings.allItemsAreSelected'),
                              "clearSearch": t('home_departments.modal_register_triage.form_chatbots.override_strings.clearSearch'),
                              "clearSelected": t('home_departments.modal_register_triage.form_chatbots.override_strings.clearSelected'),
                              "noOptions": t('home_departments.modal_register_triage.form_chatbots.override_strings.noOptions'),
                              "search": t('home_departments.modal_register_triage.form_chatbots.override_strings.search'),
                              "selectAll": t('home_departments.modal_register_triage.form_chatbots.override_strings.selectAll'),
                              "selectAllFiltered": t('home_departments.modal_register_triage.form_chatbots.override_strings.selectAllFiltered'),
                              "selectSomeItems": t('home_departments.modal_register_triage.form_chatbots.override_strings.selectSomeItems'),
                              "create": t('home_departments.modal_register_triage.form_chatbots.override_strings.create'),
                            }
                          }
                          hasCreateItem={false}
                          hasNullOption={false}
                          hasAllOption={true}
                        />
                      </Col>
                    </Row>

                    <Row className="d-flex justify-content-center" style={{marginTop: '1vh'}}>
                      <DragDropContext onDragEnd={onDragEnd}>
                        <Col md={6} sm={true} style={allStyles.textFontGlay} >
                          <Form.Label htmlFor="basic-url">{t('home_departments.modal_register_triage.available_fields')}
                            <OverlayTrigger
                              key='top'
                              placement='top'
                              overlay={
                                <Tooltip id='tooltip'>
                                  {t('home_departments.modal_register_triage.available_fields_tooltip')}
                                </Tooltip>
                              }
                            >
                              <svg id="informação" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="mx-1">
                                <path id="Caminho_10010" data-name="Caminho 10010" d="M0,0H24V24H0Z" fill="none" />
                                <circle id="Elipse_1556" data-name="Elipse 1556" cx="9" cy="9" r="9" transform="translate(3 3)" fill="none" stroke="#0C2461" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                <line id="Linha_604" data-name="Linha 604" x2="0.01" transform="translate(12 8)" fill="none" stroke="#0C2461" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                <path id="Caminho_10011" data-name="Caminho 10011" d="M11,12h1v4h1" fill="none" stroke="#0C2461" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                              </svg>
                            </OverlayTrigger>
                          </Form.Label>

                          <Droppable droppableId="droppable">
                            {(provided, snapshot) => (
                              <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                style={getListStyle(snapshot.isDraggingOver)}
                              >
                                {ticketAvailable.map((item, index) => (
                                  <Draggable key={item.id} draggableId={item.id} index={index}>
                                    {(provided, snapshot) => (
                                      <Row
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                                      >
                                        <Col className='oneLineClampEllipsis'>
                                          <OverlayTrigger
                                            key='top'
                                            placement='top'
                                            overlay={
                                              <Tooltip id='tooltip'>
                                                {item.name}
                                              </Tooltip>
                                            }
                                          >
                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                              <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '80%' }}>
                                                {item.name}
                                              </span>
                                              {item.entity_type_id === "250204ce-44eb-4cdc-865a-f54eed9c6496" && <IconTicket style={{ marginLeft: '8px', width: '20px', height: '20px'}} />} 
                                              {item.entity_type_id === "b93b0740-a77d-4474-aad9-7aa0044d5654" && <IconUsers style={{ marginLeft: '8px', width: '20px', height: '20px' }} />} 
                                            </div>
                                          </OverlayTrigger>
                                        </Col>
                                      </Row>
                                    )}
                                  </Draggable>
                                ))}
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        </Col>
                        <Col md={6} sm={true} style={allStyles.textFontGlay} >
                          <Form.Label htmlFor="basic-url">{t('home_departments.modal_register_triage.triage_fields')}
                            <OverlayTrigger
                              key='top'
                              placement='top'
                              overlay={
                                <Tooltip id='tooltip'>
                                  {t('home_departments.modal_register_triage.triage_fields_tooltip')}
                                </Tooltip>
                              }
                            >
                              <svg id="informação" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="mx-1">
                                <path id="Caminho_10010" data-name="Caminho 10010" d="M0,0H24V24H0Z" fill="none" />
                                <circle id="Elipse_1556" data-name="Elipse 1556" cx="9" cy="9" r="9" transform="translate(3 3)" fill="none" stroke="#0C2461" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                <line id="Linha_604" data-name="Linha 604" x2="0.01" transform="translate(12 8)" fill="none" stroke="#0C2461" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                <path id="Caminho_10011" data-name="Caminho 10011" d="M11,12h1v4h1" fill="none" stroke="#0C2461" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                              </svg>
                            </OverlayTrigger>
                          </Form.Label>
                          <Droppable droppableId="droppable2">
                            {(provided, snapshot) => (
                              <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                style={getListStyle(snapshot.isDraggingOver)}
                              >
                                {triageFields.map((item, index) => (
                                  <Draggable key={item.id} draggableId={item.id} index={index}>
                                    {(provided, snapshot) => (
                                      <Row
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                                      >
                                        <Col className='oneLineClampEllipsis'>
                                          <OverlayTrigger
                                            key='top'
                                            placement='top'
                                            overlay={
                                              <Tooltip id='tooltip'>
                                                {item.name}
                                              </Tooltip>
                                            }
                                          >
                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                              <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '80%' }}>
                                                {item.name}
                                              </span>
                                              {item.entity_type_id === "250204ce-44eb-4cdc-865a-f54eed9c6496" && <IconTicket style={{ marginLeft: '8px', width: '20px', height: '20px'}} />} 
                                              {item.entity_type_id === "b93b0740-a77d-4474-aad9-7aa0044d5654" && <IconUsers style={{ marginLeft: '8px', width: '20px', height: '20px' }} />} 
                                            </div>
                                          </OverlayTrigger>
                                        </Col>
                                      </Row>
                                    )}
                                  </Draggable>
                                ))}
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        </Col>
                      </DragDropContext>
                    </Row>  
                                     
                    <div className="switch-input mt-3" >
                      <span style={allStyles.textFontGlay}>{t("home_departments.modal_register_triage.mandatory_fields")}</span> {/* ADD TRADUÇÃO */}
                      <OverlayTrigger
                        key='top'
                        placement='top'
                        overlay={
                          <Tooltip id='tooltip'>
                            {triageIsMandatory ?
                              t("home_departments.modal_register_triage.yes") :
                              t("home_departments.modal_register_triage.no")
                            }
                          </Tooltip>
                        }
                      >
                        <label className="switch">
                          <input
                            type="checkbox"
                            checked={triageIsMandatory}
                            onChange={triageIsMandatory ? () => setTriageIsMandatory(false) : () => setTriageIsMandatory(true)}
                          />
                          <span className="slider round"></span>
                        </label>
                      </OverlayTrigger>
                    </div>
                  </>
                  :
                  <></>
                }

                <Row className="d-flex justify-content-center mt-4">
                  <Col xs={6} className="d-flex justify-content-start">
                    <Button size='lg' className="buttonWhite" style={{ width: '56%' }} onClick={() => Hide()} >{t('home_departments.modal_register_triage.cancel')}</Button>
                  </Col>
                  <Col xs={6} className="d-flex justify-content-end">
                    <Button size='lg' type='submit' className="buttonBlue" style={{ width: '56%' }} disabled={isLoading}>{t('home_departments.modal_register_triage.save')}</Button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Container>
      </Modal.Body>
    </Modal >
  );
}

export default ModalRegisterTriage;
