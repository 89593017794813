import { useTranslation } from "react-i18next";
import { setShowAlertFeedback, setShowEditPhoneModal } from "../../../../store/internal"
import { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import ModalEditPhone from ".";
import { IModalEditPhoneController } from "./indexModal";
import { AppRequesterController } from "../../../../services/appRequester/appRequesterController";
import { verifyCode } from "../../../../services/codeCxpressInterface";
import { Channels } from "../../../../core/enums/channels";

const ModalEditPhoneController: FC<IModalEditPhoneController> = (props) => {

    const { t } = useTranslation();
    const [/*isLoading*/, setIsLoading] = useState(false)
    const [validated] = useState(false);

    const [/*customers_phone*/, SetCustomers_phone] = useState(null as string || null);
    const [phoneBorder, /*setPhoneBorder*/] = useState('')
    const [selected_departments, setSelected_departments] = useState([]);
    const [modalShow, setModalShow] = useState(props.visibility);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const AppRequesterConst = new AppRequesterController();

    const [options_departments, setOptions_departments] = useState(props?.departments ? props?.departments : []);

    useEffect(() => {
        setModalShow(props.visibility);
        setOptions_departments((prevOptions) => {
            if(props?.departments) {
                const novasOpcoes = props?.departments?.filter((novo) => !prevOptions.some((opcao) => opcao.value === novo.value));
                return [...prevOptions, ...novasOpcoes];
            }
        });

        setSelected_departments([])
        if (props?.sector?.name !== undefined) {
            setSelected_departments(
                [{
                    label: props?.sector?.name,
                    value: props?.sector?.id
                }]
            );
        }

    }, [props.visibility])

    const selectDepartment = (value: any[]) => {
        if (value?.length > 0) {
            setSelected_departments([value[value?.length - 1]]);
        } else {
            setSelected_departments([]);
        }
    }

    const Hide = () => {
        // setSelected_departments([]);
        dispatch(setShowEditPhoneModal(false));
        setModalShow(false);
    }

    const Validate = (values) => {
        let errors = {};

        return errors;
    }

    const onSubmit = async () => {
        let JsonSend_sector = {
            "whatsapp_keys_id": props.phoneId,
            "channel_id": Channels.WhatsApp, /* adicionado id de Whatsapp */
        }

        if (selected_departments && selected_departments?.length >= 1) {
            JsonSend_sector["sector_id"] = selected_departments[0].value;
        }
        
        const headers = {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": "Bearer " + props.token
        };
        console.log(JsonSend_sector)

        await AppRequesterConst.Patch(
            'sector/key-association', JsonSend_sector, { headers },
            (response: Object) => {
                return response;
            },
            (data: { data: { id: string; }; }) => {
                dispatch(setShowAlertFeedback({ message: t("components.modal_phone.sector_updated"), visibility: true, signalIcon: true }));
                props.getListWhatsapp(null)
                Hide();
            },
            (error: { response: { data: { code_cxpress: number, message } } }) => {

                if (error?.response.data.message[0].path[0] === 'sector_id') {
                    dispatch(setShowAlertFeedback({ message: t("components.modal_phone.sector_required"), visibility: true, signalIcon: false }));
                }
                else {
                    dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }));
                }
            }, navigate, dispatch, setIsLoading
        );
    }
    return <ModalEditPhone
        modalShow={modalShow}
        Hide={Hide}
        number={props?.number}
        phoneId={props?.phoneId}
        selected_departments={selected_departments}
        token={props?.token}
        getListWhatsapp={props?.getListWhatsapp}
        dispatch={dispatch}
        navigate={navigate}
        setIsLoading={setIsLoading}
        validated={validated}
        SetCustomers_phone={SetCustomers_phone}
        options_departments={options_departments}
        setOptions_departments={setOptions_departments}
        setSelected_departments={setSelected_departments}
        t={t}
        phoneBorder={phoneBorder} 
        onSubmit={onSubmit}
    />
}

export default ModalEditPhoneController