import React, { useEffect, useState } from 'react'
import ModalEmailActivity from '.'
import { IconAlertCircleFilled, IconAlertTriangleFilled, IconCircleCheckFilled } from '@tabler/icons-react';

const ModalEmailActivityController = ({
    show,
    handleClose,
    data,
    convertDate,
    convertStatus,
    emailInfo
}) => {

    const closeButton = () => {
        handleClose();
    }

    const iconStatus = (status) => {
        if(status === "delivered") {
            return(
                <IconCircleCheckFilled color='#3EA95A' strokeWidth={1} style={{ color: '#3EA95A' }} size={22}/>
            )
        }
        else if(status === "not_delivered") {
            return(
                <IconAlertCircleFilled color='#F15154' strokeWidth={1} style={{ color: '#F15154' }} size={22}/>
            )
        }
        else {
            return(
                <IconAlertTriangleFilled color='#FFB753' strokeWidth={1} style={{ color: '#FFB753' }} size={22}/>
            )
        }
    }

    const [info, setInfo] = useState([])

    useEffect(() => {
        if(show) {
            const emailData = data.find(item => item.msg_id === emailInfo);
    
            if (emailData) {
                setInfo(emailData);
            }
        }
    }, [show])


    return (
        <ModalEmailActivity
            show={show}
            closeButton={closeButton}
            iconStatus={iconStatus}
            data={data}
            convertDate={convertDate}
            convertStatus={convertStatus}
            info={info}
        />
    )
}

export default ModalEmailActivityController