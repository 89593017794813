import { useState, useEffect, FC, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import { Popover, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
    setShowConfirmationModal,
    setShowEditAgentsModal,
    setShowAlertFeedback,
    getShowEditAgentsModal
} from '../../../../store/internal'
import { getToken } from '../../../../store/token'
import { getIdCompany } from '../../../../store/company'
import { getIdEmployee, setIdStatusEmployee } from '../../../../store/employee'
import { getIdUser, getNameUser, getProfileId } from '../../../../store/user'
import constsApi from '../../../../services/constsApi';
import { AppRequesterController } from '../../../../services/appRequester/appRequesterController';
import Utils from '../../../../core/shared/utils';
import UserService from '../../../../services/user-service';
import { TokenInterface } from '../../../../services/requestsInterfacesModel';
import { ListAgentsInterface } from './indexModel';
import { useTranslation } from 'react-i18next';
import constsRouters from "../../../../routes/constsRouter";
import ListOfAgents from '.';
import { verifyCode } from '../../../../services/codeCxpressInterface';
import { AgentData } from '../indexModel';
import useStatusEmployee from '../../../../hooks/useStatusEmployee';

const AppRequesterConst = new AppRequesterController();

const ListOFAgentsController: FC<ListAgentsInterface> = (props) => {

    const { t } = useTranslation();
    let navigate = useNavigate();
    //const [confirmationText, setConfirmationText] = useState({ "title": '', "body": '', 'id': '' });
    const dispatch = useDispatch();
    //TIRA ID E USAR SÓ AGENT DATA
    const [currentAgentId, setCurrentAgentId] = useState('');
    const [currentAgentName, setCurrentAgentName] = useState('');
    const [currentAgentData, setCurrentAgentData] = useState(null);
    const [currentListedAgentIsOwner, setCurrentListedAgentIsOwner] = useState(null);

    const [deleteListAgents, setDeleteListAgents] = useState([]);
    const [agentsDeletedNames, setAgentsDeletedNames] = useState([]);
    const [show, setShow] = useState(false);
    const [agentId, setAgentId] = useState<string>()
    const [agentStatusId, setAgentStatusId] = useState<string>()
    const [agentsNotification, setAgentsNotification] = useState([])

    const { statusEmployee } = useStatusEmployee()


    const [isLoading, setIsLoading] = useState(false);

    const values: TokenInterface = {
        company: {
            id: useSelector(getIdCompany)
        },
        employee: {
            id: useSelector(getIdEmployee)
        },
        user: {
            id: useSelector(getIdUser),
            name: useSelector(getNameUser),
            profile_id: useSelector(getProfileId)
        },
        token: {
            value: useSelector(getToken)
        },
        internal: {
            getShowEditAgentsModal: useSelector(getShowEditAgentsModal),
        }
    };

    const isAdmin = () => values.user.profile_id === constsApi.profile[0].id;
    const isAgent = () => values.user.profile_id === constsApi.profile[1].id;
    const isOwner = () => values.user.profile_id === constsApi.profile[2].id;
    const isMaster = () => values.user.profile_id === constsApi.profile[3].id;

    const handleClose = () => setShow(false)
    const handleShow = () => setShow(true)

    // quando o componente é montado os modais devem estar com visibilidade desabilitada
    useEffect(() => {
        if (values.internal.getShowEditAgentsModal) {
            dispatch(setShowEditAgentsModal(false));
        }
    }, []); 
    

    useEffect(() => {
        if (props.datas !== undefined) {
            for (let i = 0; i < props.datas.length; i++) {
                if (document.getElementById("checkboxesAll" + i)) {
                    (document.getElementById("checkboxesAll" + i) as HTMLInputElement).checked = props?.isNotification ? props?.datas[i].send_email_notification : false;
                    setDeleteListAgents([]);
                }
            }
            Utils.changeStyleElementById('buttonDeleteAllSelected', 'display', 'none');
        }

        Utils.changeActiveScrollBar("ListOfAgents");
    }, [props.datas]);

    const deleteAgents = async (listAgents) => {
        const headers = UserService.getHeaders()

        const config = {
            headers,
            // headers: {
            //     "Content-Type": "application/json; charset=utf-8",
            //     Authorization: "Bearer " + values.token.value
            // },
            data: {
                ids: listAgents
            }
        };

        await AppRequesterConst.Delete(
            '/agent', config,
            (response: Object) => { },
            (data: Object) => {
                props.updateListAfterDelete();
            },
            (error: { response: { status: number; data:{code_cxpress: number} }; }) => {
                dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }));
                if (error.response !== undefined) {
                    if (error.response.status === 400) {
                        //document.getElementById("feedbackemail").innerHTML = "Usuário ou senha incorreto";
                        //setShowfeedback(true);
                    }
                    if (error.response.status === 401) {
                        //document.getElementById("feedbackpassword").innerHTML = "Usuário ou senha incorreto";
                        //setShowfeedbackPassword(true);
                    }
                }
                setIsLoading(false);
            }, navigate, dispatch, setIsLoading
        );
    }

    const deleteAllSelected = () => {
        dispatch(setShowConfirmationModal({ visibility: true, text: { "title": `${t('home_agents.list_agents.delete_all_selected.title')}`, "body": `${t('home_agents.list_agents.delete_all_selected.body')}`, 'id': '', "buttonReturnText": `${t('home_agents.list_agents.delete_all_selected.cancel')}` }, functionConfirmation: funtionConfirmationAll }));
    }

    const AllCheckboxesClick = (e: { currentTarget: { checked: boolean; }; }) => {
        let aux = [];
        if (e.currentTarget.checked === true) {
            if (props.setAllMarked)
                props.setAllMarked(true)

            for (let i = 0; i < props.datas.length; i++) {
                if ((document.getElementById("checkboxesAll" + i) as HTMLInputElement) != null) {
                    (document.getElementById("checkboxesAll" + i) as HTMLInputElement).checked = true;

                    aux.push((document.getElementById("checkboxesAll" + i) as HTMLInputElement).parentElement.parentElement.parentElement.parentElement.id.split("*ID*")[1]);
                    setDeleteListAgents(aux);
                }
            }
            Utils.changeStyleElementById('buttonDeleteAllSelected', 'display', 'block');
        } else {
            if (props.setAllMarked)
                props.setAllMarked(false)
            
            for (let i = 0; i < props.datas.length; i++) {
                if ((document.getElementById("checkboxesAll" + i) as HTMLInputElement) != null) {
                    (document.getElementById("checkboxesAll" + i) as HTMLInputElement).checked = false;
                    setDeleteListAgents(aux);
                }
            }
            Utils.changeStyleElementById('buttonDeleteAllSelected', 'display', 'none');
        }
    }

    const CheckboxesClick = (id, check) => {
        const index = agentsNotification.findIndex(notification => notification.user_id === id);

        if (index !== -1) {
            agentsNotification[index] = { user_id: id, send_email_notification: check };
        } else {
            agentsNotification.push({ user_id: id, send_email_notification: check });
            if (props.setEmployes)
                props.setEmployes(agentsNotification)
        }
        let count = 0;
        let aux = [];
        let auxName = [];
        for (let i = 0; i < props.datas.length; i++) {
            let checkAgent = document.getElementById("checkboxesAll" + i);
            if (checkAgent && (checkAgent as HTMLInputElement).checked === true) {
                count++;
                aux.push((document.getElementById("checkboxesAll" + i) as HTMLInputElement).parentElement.parentElement.parentElement.parentElement.id.split("*ID*")[1]);
                setDeleteListAgents(aux);
                auxName.push((document.getElementById("checkboxesAll" + i) as HTMLInputElement).parentElement.parentElement.parentElement.parentElement.id.split("*ID*")[0]);
                setAgentsDeletedNames(auxName);
            } 
        }

        if (count !== 0) {
            Utils.changeStyleElementById('buttonDeleteAllSelected', 'display', 'block');

        } else {
            Utils.changeStyleElementById('buttonDeleteAllSelected', 'display', 'none');
        }


    }

    const funtionConfirmation = () => {
        setDeleteListAgents((state) => {
            deleteAgents(state).finally(() => {
                dispatch(setShowAlertFeedback({
                    message: `${t('home_agents.list_agents.function_confirmation.message_a')} ${currentAgentData?.name + " " + (currentAgentData?.name && currentAgentData?.lastname ? currentAgentData.lastname : "")} ${t('home_agents.list_agents.function_confirmation.message_b')}`, signalIcon: true, visibility: true
                }));
            });
            return state;
        });
    }

    // const onSocketStatusAgent = (agent: { email: string, name: string, profile_id: string, id: string, status_employee_id: string }) => {
    //     if (agent !== null) {
    //         setAgentId(agent.id)
    //         setAgentStatusId(agent.status_employee_id)
    //     }
    // }

    const funtionConfirmationAll = () => {
        setDeleteListAgents((state) => {
            deleteAgents(state).finally(() => {
                if (agentsDeletedNames != null && agentsDeletedNames.length == 1) {
                    dispatch(setShowAlertFeedback({
                        message: `${t('home_agents.list_agents.function_confirmation_all.message_a')} ${agentsDeletedNames[0]} ${t('home_agents.list_agents.function_confirmation_all.message_b')}`, signalIcon: true, visibility: true
                    }));
                } else {
                    dispatch(setShowAlertFeedback({
                        message: `${t('home_agents.list_agents.function_confirmation.message_else')}`, signalIcon: true, visibility: true
                    }));
                }
            });
            return state;
        });

        Utils.changeStyleElementById('buttonDeleteAllSelected', 'display', 'none');
    }

    const deleteAgent = () => {
        let aux = [];
        aux.push(currentAgentId);

        setDeleteListAgents(aux);
        dispatch(setShowConfirmationModal({ visibility: true, text: { "title": `${t('home_agents.list_agents.delete_agent.title')}`, "body": `${t('home_agents.list_agents.delete_agent.body')}`, "id": ' ' + currentAgentName + '?', "buttonReturnText": `${t('home_agents.list_agents.delete_agent.cancel')}` }, functionConfirmation: funtionConfirmation }));
        //mudar o componente para aceitar duas linhas.
        //fazer if quando o agente tiver tickets atribuídos.
        //dispatch(setShowConfirmationModal({ visibility: true, text: { "title": 'Excluir Agente', "body": 'Este agente possui tickets atribuidos atualmente. Tem certeza que deseja excluir o agente', "id": ' ' + currentAgentId.split("ID")[0] + '?' }, functionConfirmation: funtionConfirmation }));
    }

    const setCurrentAgentInfo = (e: { currentTarget: { id: string; }; }) => {
        setCurrentAgentName(e.currentTarget.id.split("*ID*")[0]);
        setCurrentAgentId(e.currentTarget.id.split("*ID*")[1]);
        setCurrentListedAgentIsOwner(e.currentTarget.id.split("*ID*")[2]);
        handleSetCurrentAgentData(e.currentTarget.id.split("*ID*")[1]);
    }
    
    const handleSetCurrentAgentData = (selectedAgentId: string) => {
        let dataAgent = props.datas.filter(item => item.user_id == selectedAgentId)[0];
        setCurrentAgentData(dataAgent);
        getAgentByUserId(selectedAgentId);
    }

    const getAgentByUserId = async (user_id: string) => {
        const headers = UserService.getHeaders();

        await AppRequesterConst.Get(
            '/agent/' + user_id, { headers },
            (response: Object) => { },
            (data: AgentData) => {
                if ((data.status === 200 || data.status === 204) && data.data.employees.length >= 0) {
                    setCurrentAgentData(Object.assign(data.data.employees[0], { user_id: user_id }));
                } else {
                    setCurrentAgentData(null);
                }

            },
            (error: { response: { status: number; }; }) => {
                if (error.response !== undefined) {
                    if (error.response.status === 400) {
                        //document.getElementById("feedbackemail").innerHTML = "Usuário ou senha incorreto";
                        //setShowfeedback(true);
                    }
                    if (error.response.status === 401) {
                        //document.getElementById("feedbackpassword").innerHTML = "Usuário ou senha incorreto";
                        //setShowfeedbackPassword(true);
                    }
                }
            }, navigate, dispatch, () => {}, { values: values }
        );
    }

    const showEditModal2 = (e: { currentTarget: { parentElement: { parentElement: { id: string; }; }; }; }) => {
        props.setCurrentAgentFunction(e.currentTarget.parentElement.parentElement.id.split("*ID*")[1]);
        dispatch(setShowEditAgentsModal(true));
    }

    const viewAgentPage = (e: { currentTarget: { id: string; }; }) => {
        setCurrentAgentId(e.currentTarget.id);
        let selectedAgentId = e.currentTarget.id.split("*ID*")[1];
        navigate(constsRouters.routers.agentsView.path, { state: props.datas.filter(item => item.user_id == selectedAgentId)[0] });
    }

    const documentNumberFormat = (item) => {
        if (item?.country === "BR") {
            if (item?.document_number.length <= 11) {
                return `${item.document_number.slice(0, 3)}.${item.document_number.slice(3, 6)}.${item.document_number.slice(6, 9)}-${item.document_number.slice(9, 11)}`;
            } else {
                return `${item.document_number.slice(0, 2)}.${item.document_number.slice(2, 5)}.${item.document_number.slice(5, 8)}/${item.document_number.slice(8, 12)}-${item.document_number.slice(12, 14)}`;
            }
        } else if (item?.country === "ES") {
            return item.document_number;
        } else if (item?.country === "FR") {
            return `${item.document_number.slice(0, 1)} ${item.document_number.slice(1, 3)} ${item.document_number.slice(3, 5)} ${item.document_number.slice(5, 7)} ${item.document_number.slice(7, 10)} ${item.document_number.slice(10, 13)} ${item.document_number.slice(13, 15)}`;
        } else if (item?.country === "PT") {
            return item.document_number;
        } else if (item?.country === "GB") {
            return `${item.document_number.slice(0, 2)} ${item.document_number.slice(2, 4)} ${item.document_number.slice(4, 6)} ${item.document_number.slice(6, 8)} ${item.document_number.length === 9 ? item.document_number.slice(8) : ''}`.trim();
        }
    }

    const popover = (
        <Popover id={currentAgentId} style={{ zIndex: '500', backgroundColor: '#e6e6e6', border: 'none', color: '#40484E', font: 'var(--px15_19-font-Roboto)' }}>
            <Popover.Body className="nopadding">
                <Button onClick={(e) => showEditModal2(e)} className="m-1" style={{ backgroundColor: 'transparent', border: 'none', color: 'black', fontSize: 'inherit' }}>{t('home_agents.list_agents.popover.edit')}</Button>
                <div style={{ backgroundColor: '#707070', opacity: '0.5', height: '1px', width: '100%' }}></div>
                <div>
                    <Button onClick={handleShow} className="m-1" style={{ backgroundColor: 'transparent', border: 'none', color: 'black', fontSize: 'inherit' }}>{t("home_agents.list_agents.view_consumers")}</Button>
                </div>
                {((currentListedAgentIsOwner !== constsApi.profile[2].id) && (currentListedAgentIsOwner !== constsApi.profile[3].id)) &&
                    <div>
                        <div style={{ backgroundColor: '#707070', opacity: '0.5', height: '1px', width: '100%' }}></div>
                        <Button onClick={deleteAgent} className="m-1" style={{ backgroundColor: 'transparent', border: 'none', color: 'black', fontSize: 'inherit' }}>{t('home_agents.list_agents.popover.delete')}</Button>
                    </div>
                }
            </Popover.Body>
        </Popover>
    );

    return <ListOfAgents
        t={t}
        isLoading={isLoading}
        values={values}
        props={props}
        currentAgentData={currentAgentData}
        AllCheckboxesClick={AllCheckboxesClick}
        CheckboxesClick={CheckboxesClick}
        viewAgentPage={viewAgentPage}
        popover={popover}
        setCurrentAgentInfo={setCurrentAgentInfo}
        deleteAllSelected={deleteAllSelected}
        isAdmin={isAdmin}
        isAgent={isAgent}
        isOwner={isOwner}
        isMaster={isMaster}
        documentNumberFormat={documentNumberFormat}
        show={show}
        handleClose={handleClose}
        handleShow={handleShow}
        currentAgentId={currentAgentId}
        setCurrentAgentId={setCurrentAgentId}
        agentId={agentId}
        agentStatusId={agentStatusId}
        isNotification={props?.isNotification}
        statusEmployee={statusEmployee}
    />

}

export default ListOFAgentsController;