import React, { useEffect, useState } from 'react'
import ListBlockedEmails from '.';
import { Button, Popover } from 'react-bootstrap';
import { AppRequesterController } from '../../../../services/appRequester/appRequesterController';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { TokenInterfaceControlPanel } from '../../../../services/requestsInterfacesModel';
import { getToken } from '../../../../store/token';
import UserService from '../../../../services/user-service';
import { setShowAlertFeedback } from '../../../../store/internal';

const ListBlockedEmailsController = ({
    data,
    // isLoading,
    updateListfunction,
    hasMoreInformation,
    updateListAfterDelete,
}) => {

    const AppRequesterConst = new AppRequesterController()

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);

    const [emailInfo, setEmailInfo] = useState("");
    // const [blockedEmailsCheck, setBlockedEmailsCheck] = useState([]);

    const values: TokenInterfaceControlPanel = {
        token: {
            value: useSelector(getToken)
        }
    }

    // const setElementDisplayById = (id: string, value: string) => {
    //     const exists_element = document.getElementById(id);

    //     if (exists_element) {
    //         document.getElementById(id).style.display = value;
    //     }
    // }

    // const allCheckboxesClick = (e: { currentTarget: { checked: boolean } }) => {
    //     let aux = [];
    //     if (e.currentTarget.checked === true) {
    //         for (let i = 0; i < data.length; i++) {
    //             if ((document.getElementById("checkboxesAll" + i) as HTMLInputElement) != null) {
    //                 (document.getElementById("checkboxesAll" + i) as HTMLInputElement).checked = true;
    //                 /* VERIFICAR SE SEPARA POR EMAIL OU ID */
    //                 aux.push((document.getElementById("checkboxesAll" + i) as HTMLInputElement).parentElement.parentElement.parentElement.parentElement.id.split("*EMAIL*")[1]);
    //             }
    //             setBlockedEmailsCheck(aux);
    //         }
    //         setElementDisplayById("buttonUnblockEmail", "block")
    //     }
    //     else {
    //         for (let i = 0; i < data.length; i++) {
    //             if ((document.getElementById("checkboxesAll" + i) as HTMLInputElement) != null) {
    //                 (document.getElementById("checkboxesAll" + i) as HTMLInputElement).checked = false;
    //             }
    //             setBlockedEmailsCheck(aux);
    //         }
    //         setElementDisplayById("buttonUnblockEmail", "none")
    //     }
    // }

    // const checkboxClick = () => {
    //     let count = 0;
    //     let aux = [];

    //     for (let i = 0; i < data.length; i++) {
    //         let checkTag = document.getElementById("checkboxesAll" + i);
    //         if (checkTag && (checkTag as HTMLInputElement).checked === true) {
    //             count++
    //             aux.push((document.getElementById("checkboxesAll" + i) as HTMLInputElement).parentElement.parentElement.parentElement.parentElement.id.split("*EMAIL*")[1]);
    //         }
    //         setBlockedEmailsCheck(aux);
    //     }
        
    //     if (count !== 0) {
    //         setElementDisplayById('buttonUnblockEmail', 'block');
    //     } else {
    //         setElementDisplayById('buttonUnblockEmail', 'none');
    //     }
    // }

    const popover = (
        <Popover style={{ backgroundColor: '#e6e6e6', border: 'none', color: '#40484E', font: 'var(--px15_19-font-Roboto)' }} id='popover'>
            <Popover.Body className="nopadding">
                <Button className="m-2" style={{ backgroundColor: 'transparent', border: 'none', color: 'black', fontSize: 'inherit' }} onClick={() => unBlockEmail(emailInfo)}>Desbloquear e-mail</Button>
            </Popover.Body>
        </Popover>
    )

    const setCurrentEmailInfo = (e: { currentTarget: { id: string } }) => {
        setEmailInfo(e.currentTarget.id.split("*EMAIL*")[1])
    }

    const unBlockEmail = async (email: string) => {
        const headers = UserService.getHeaders();

        let JsonSend = {
            "email": email
        }

        await AppRequesterConst.Put(
            '/panel/email/subscribe', JsonSend, { headers },
            (response: Object) => { },
            (data: Object) => {
                document.getElementById('popover').style.display = 'none'; /* forçando popover a sumir */
                dispatch(setShowAlertFeedback({ message: "E-mail desbloqueado com sucesso.", visibility: true, signalIcon: true }));
                updateListAfterDelete();
            },
            (error: Object) => {
                dispatch(setShowAlertFeedback({ message: "Ocorreu um erro inesperado, tente novamente mais tarde.", visibility: true, signalIcon: false }));
            },
            navigate,
            dispatch,
            setIsLoading,
        )
    }

    const convertTimestamp = (time) => {
        var newDate = new Date(time * 1000);
        const day = String(newDate.getDate()).padStart(2, '0');
        const month = String(newDate.getMonth() + 1).padStart(2, '0');
        const year = newDate.getFullYear();
        return `${day}/${month}/${year}`;
    }

  return (
    <ListBlockedEmails
        popover={popover}
        // allCheckboxesClick={allCheckboxesClick}
        // checkboxClick={checkboxClick}
        data={data}
        isLoading={isLoading}
        updateListfunction={updateListfunction}
        hasMoreInformation={hasMoreInformation}
        setCurrentEmailInfo={setCurrentEmailInfo}
        convertTimestamp={convertTimestamp}
    />
  )
}

export default ListBlockedEmailsController;