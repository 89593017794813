import { FC } from 'react';
import styles from './styles'
import { IconAlertCircle, IconRefresh, IconTicket } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';

const AlertUpdateTicketBySector = (props) => {

  const {t} = useTranslation()

  return (
    <div style={{ display: props.displayFunction(), ...styles.customContainer }} className="">
      <div className="d-flex align-items-center me-0" style={styles.rowAlert}>
        <div className="d-flex justify-content-start align-items-center" style={{ width: 'fit-content', marginRight: '15px' }}>
          <IconTicket size={26} color="#4a69bd" />
        </div>
        <div className="d-flex justify-content-start nopadding" style={styles.primary}>
          {t("home_tickets.list_tickets.alert_ticket_sector.title")}
          {/* <span style={styles.secondary}>{t("home_tickets.list_tickets.alert_ticket_sector.subtitle")}</span> */}
        </div>
          {/* <div className="d-flex justify-content-end">
            <IconRefresh size={20} color="#707070" style={{ cursor: 'pointer', marginLeft: '10px' }} onClick={() => props.refreshWindow()}/>
          </div> */}
      </div>
    </div>
  );
}

export default AlertUpdateTicketBySector;