type CSSProperties = {
    [key: string]: React.CSSProperties;
};

export class StyleSheet {
    static create<Styles extends CSSProperties>(styles: Styles): Styles {
        return styles;
    };
};

const styles = StyleSheet.create({
    bodyPhoneDialer: {
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        boxShadow: '0px 3px 6px #00000029',
        borderRadius: '10px',
        opacity: 1,
        width: 'min(max(240px, 36vw), 690px)',
        //height: 'min(max(240px, 50vh), 506px)',
    },
    bodyPhoneDialer1: {
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        boxShadow: '0px 3px 6px #00000029',
        borderRadius: '10px',
        opacity: 1,
        width: 'min(max(240px, 36vw), 690px)',
        //height: 'min(max(240px, 55vh), 500px)',
    },
    title: {
        textAlign: 'left',
        // font: 'normal normal bold 20px/24px Montserrat',
        font: 'normal normal bold calc(0.2em + 1vw) Montserrat',
        letterSpacing: '0px',
        color: '#0C2461',
        opacity: '1',
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    title2: {
        textAlign: 'left',
        // font: 'normal normal bold 20px/24px Montserrat',
        font: 'normal normal bold calc(0.2em + 0.7vw) Roboto',
        letterSpacing: '0px',
        color: '#1E1E1E',
        opacity: '1',
    },
    title3: {
        textAlign: 'left',
        font: 'normal normal bold calc(0.2em + 0.7vw) Montserrat',
        letterSpacing: '0px',
        color: '#0C2461',
        opacity: '1',
        marginTop: '3vh'
    },
    responseText: {
        textAlign: 'left',
        // font: 'normal normal bold 20px/24px Montserrat',
        font: 'normal normal normal calc(0.2em + 0.7vw) Roboto',
        letterSpacing: '0px',
        color: '#1E1E1E',
        opacity: '1',
    },
    responseTextBold: {
        textAlign: 'left',
        // font: 'normal normal bold 20px/24px Montserrat',
        font: 'normal normal bold calc(0.2em + 0.7vw) Roboto',
        letterSpacing: '0px',
        color: '#1E1E1E',
        opacity: '1',
    },
    lettersDialer: {
        textAlign: 'center',
        font: 'var(--px15_19-font)',
        letterSpacing: '0px',
        color: '#B8B8B8',
        opacity: '1',
        minHeight: '2.7vh',
    },
    buttonCallOn: {
        width: '100% !important',
        background: '#0D9D9A 0% 0% no-repeat padding-box',
        border: 'none',
        borderRadius: '3px',
        opacity: '1',
        textAlign: 'center',
        font: 'var(--px15_19-font)',
        letterSpacing: '0px',
        color: '#FFFFFF',
    },
    buttonRecents: {
        background: 'none', 
        border: 'none',
    },
    numberAgent: {
        textAlign: 'left',
        font: 'var(--px15_19-font-Roboto)',
        letterSpacing: '0.11px',
        color: '#BCBCBC',
        opacity: '1',
    },
    divSquare: {
        background: '#707070 0% 0% no-repeat padding-box',
        opacity: '1', 
        width: '8px',
        height: '8px'
    },
    colSquare: {
        marginTop: '0px',
        marginBottom: '0px',
        paddingTop: '0px',
        paddingBottom: '0px',
        marginLeft: '0px',
        marginRight: '1px',
        paddingLeft: '0px',
        paddingRight: '0px',
    },
    rowSquare: {
        marginTop: '1px',
        marginBottom: '0px',
        paddingTop: '0px',
        paddingBottom: '0px',
        marginLeft: '0px',
        marginRight: '0px',
        paddingLeft: '0px',
        paddingRight: '0px',
    },
    spanTicket: {
        fontWeight: 600, 
        color: '#4A69BD', 
        backgroundColor: '#E5ECFF', 
        padding: '1px 4px', 
        borderRadius: '4px',
        // cursor: 'pointer'
    },
    dateTime: {
        color: '#707070',
        fontSize: '11px',
    },
    textFontGlay: {
        textAlign: 'left',
        font: 'normal normal normal calc(0.2em + 0.7vw) Montserrat',
        letterSpacing: '0px',
        color: '#707070',
        opacity: '1',
    },
});

export default styles;






