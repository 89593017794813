import { useState } from "react";
import HomeControlPanelConfig from ".";
import { IconMailSearch, IconUserCancel } from "@tabler/icons-react";

const HomeControlPanelConfigController = ({}) => {

  const [activeTab, setActiveTab] = useState("blocked-emails");

  const handleTabChange = (eventKey) => {
    setActiveTab(eventKey);
  };

  const opcoesMenu = [
    {
      title: "E-mails bloqueados",
      icon: <IconUserCancel/>,
      iconActive: <IconUserCancel color="white"/>,
      tabName: "blocked-emails"
    },
    {
      title: "Atividades de e-mails",
      icon: <IconMailSearch/>,
      iconActive: <IconMailSearch color="white"/>,
      tabName: "activity"
    }
  ]

  return (
    <HomeControlPanelConfig
      handleTabChange={handleTabChange}
      activeTab={activeTab}
      opcoesMenu={opcoesMenu}
    />
  )
}

export default HomeControlPanelConfigController;
