import { useState, useEffect, FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Popover } from 'react-bootstrap';
import Utils from "../../../../../core/shared/utils";
import { setShowAlertFeedback, setShowEditEmailModal, setShowConfirmationModal, getShowEditEmailModal } from "../../../../../store/internal";
import { useDispatch, useSelector } from "react-redux";
import { getIdCompany } from "../../../../../store/company";
import { getIdEmployee } from "../../../../../store/employee";
import { getIdUser, getNameUser, getProfileId } from "../../../../../store/user";
import { getToken } from "../../../../../store/token";
import { AppRequesterController } from '../../../../../services/appRequester/appRequesterController';
import UserService from '../../../../../services/user-service';
import { TokenInterface } from '../../../../../services/requestsInterfacesModel';
import { ListOfEmailsControllerInterface } from './indexModel';
import ListOfEmails from '.';
import { useTranslation } from 'react-i18next';
import { verifyCode } from '../../../../../services/codeCxpressInterface';

const AppRequesterConst = new AppRequesterController();

const ListOfEmailsController: FC<ListOfEmailsControllerInterface> = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {t} = useTranslation();

    const [currentEmailId, setCurrentEmailId] = useState('');
    const [currentEmailName, setCurrentEmailName] = useState('');
    const [currentEmailIsActive, setCurrentEmailIsActive] = useState(null);
    const [currentEmailData, setCurrentEmailData] = useState(null);

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        Utils.changeActiveScrollBar("listOfEmails");
    }, [props.datas, props.departmentsTuples]);

    const values: TokenInterface = {
        company: {
            id: useSelector(getIdCompany)
        },
        employee: {
            id: useSelector(getIdEmployee)
        },
        user: {
            id: useSelector(getIdUser),
            name: useSelector(getNameUser),
            profile_id: useSelector(getProfileId)
        },
        token: {
            value: useSelector(getToken)
        },
        internal: {
            getShowEditEmailModal: useSelector(getShowEditEmailModal),
        },
    };

    useEffect(() => {
        Utils.changeActiveScrollBar("listOfEmails");
    }, [props.datas, props.departmentsTuples]);

    useEffect(() => {
        if (values.internal.getShowEditEmailModal) {
            dispatch(setShowEditEmailModal(false));
        }
    }, []);

    //const isAdm = values.user.profile_id === constsApi.profile[0].id || values.user.profile_id === constsApi.profile[2].id;

    const enableEmailFunction = async () => {
        const headers = UserService.getHeaders()

        let JsonSend = {
            "email": currentEmailName,
            "active": true
        }

        await AppRequesterConst.Put(
            '/company/email/active', JsonSend, { headers },
            (response: Object) => { },
            (data: Object) => {
                dispatch(setShowAlertFeedback({ message: t('home_channels.lists_channels.list_emails.email_has_active'), visibility: true, signalIcon: true }));
                props.updateListAfterEdit();
            },
            (error: { response: { status: number; data:{code_cxpress:number} }; }) => {
                dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }));
                // if (error.response !== undefined) {
                //     if (error.response.status === 400) {
                //         //document.getElementById("feedbackemail").innerHTML = "Usuário ou senha incorreto";
                //         //setShowfeedback(true);
                //     }
                //     if (error.response.status === 401) {
                //         //document.getElementById("feedbackpassword").innerHTML = "Usuário ou senha incorreto";
                //         //setShowfeedbackPassword(true);
                //     }
                // }
            }, navigate, dispatch, setIsLoading
        );
    }

    const disableEmailFunction = async () => {

        const headers = UserService.getHeaders()

        let JsonSend = {
            "email": currentEmailName,
            "active": false
        }

        await AppRequesterConst.Put(
            '/company/email/active', JsonSend, { headers },
            (response: Object) => { },
            (data: Object) => {
                dispatch(setShowAlertFeedback({ message: t('home_channels.lists_channels.list_emails.email_has_inactive'), visibility: true, signalIcon: true }));

                props.updateListAfterEdit();
            },
            (error: { response: { status: number; data:{code_cxpress: number} }; }) => {
                dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }));
                // if (error.response !== undefined) {
                //     if (error.response.status === 400) {
                //         //document.getElementById("feedbackemail").innerHTML = "Usuário ou senha incorreto";
                //         //setShowfeedback(true);
                //     }
                //     if (error.response.status === 401) {
                //         //document.getElementById("feedbackpassword").innerHTML = "Usuário ou senha incorreto";
                //         //setShowfeedbackPassword(true);
                //     }
                // }
            }, navigate, dispatch, setIsLoading, () => { },
            {
                error500: (error) => {
                    dispatch(setShowAlertFeedback({ visibility: true, message: t('home_channels.lists_channels.list_emails.disable_email_error'), signalIcon: false }));
                }
            }
        );
    }


    // Confirmação do email principal
    const mainEmailConfirmation = async () => {
        let JsonSend_sector ={ 
            "id": currentEmailId,
            "active": true,
            "is_primary": true
        }

        const headers = UserService.getHeaders()

        await AppRequesterConst.Put(
            '/company/email', JsonSend_sector, { headers },
            (response: Object) => {},
            (data: Object) => {
                dispatch(setShowAlertFeedback({ message: t('home_channels.lists_channels.list_emails.email_has_defined'), visibility: true, signalIcon: true }))
                props.updateListAfterEdit()
            },
            (error: { response: { status: number; data:{code_cxpress: number} }; }) => {
                dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }))
                if(error.response !== undefined) {
                //     if (error.response.status === 400) {

                //     }
                //     if (error.response.status === 401) {
                    if (error.response.status === 500) {
                        dispatch(setShowAlertFeedback({ message: t('home_channels.lists_channels.list_emails.it_was_not_possible'), visibility: true, signalIcon: false }))
                    }
                }
            }, navigate, dispatch, setIsLoading
        )
    }

    const mainEmailConfirmationDisable = async () => {
        let JsonSend_sector = {
            "id": currentEmailId,
            "active": true,
            "is_primary": false
        }

        const headers = UserService.getHeaders()

        await AppRequesterConst.Put(
            '/company/email', JsonSend_sector, { headers },
            (response: Object) => {},
            (data: Object) => {
                dispatch(setShowAlertFeedback({ message: t('home_channels.lists_channels.list_emails.successfully_unlinked'), visibility: true, signalIcon: true }))
                props.updateListAfterEdit()
            },
            (error: { response: { status: number; data:{code_cxpress: number} }; }) => {
                dispatch(setShowAlertFeedback({ message: verifyCode(error.response.data.code_cxpress, t), visibility: true, signalIcon: false }))
                // if(error.response !== undefined) {

                //     if (error.response.status === 400) {

                //     }
                //     if (error.response.status === 401) {

                //     }
                // }
            }, navigate, dispatch, setIsLoading

        )
    }

    const showEditModal = () => {
        // props.setCurrentConsumerFunction(e.currentTarget.parentElement.parentElement.id.split("*ID*")[1]);
        dispatch(setShowEditEmailModal(true));
    }

    const setCurrentEmailInfo = (e: { currentTarget: { id: string; name: string }; }) => {
        // console.log(e.currentTarget.id.split("*is_primary*")[1]);
        setCurrentEmailId(e.currentTarget.id.split("*ID*")[1]);
        setCurrentEmailName(e.currentTarget.name);
        // setCurrentIsPrimary(e.currentTarget.id.split("*is_primary*")[1])
        setCurrentEmailIsActive(e.currentTarget.id.split("*ID*")[2] === "true" ? true : false);
        handleSetCurrentEmailData(e.currentTarget.id.split("*ID*")[1]);
    }

    const handleSetCurrentEmailData = (selectedEmailId: string) => {
        let dataEmail = props.datas.filter((item: { id: string; }) => item.id === selectedEmailId)[0];
        dataEmail['departments'] = props.departmentsTuples;

        let currentDepartment = props.datas.filter((item: { id: string; }) => item.id === selectedEmailId)[0].sector;
        if (currentDepartment) {
            dataEmail['department_selected'] = [{ 'label': currentDepartment.name, 'value': currentDepartment.id }];
        } else {
            dataEmail['department_selected'] = null;
        }

        /* setCurrentDepartmentsTuples(currentDepartments); */
        setCurrentEmailData(dataEmail);
    }

    const activeEmailConfirm = () => {
        dispatch(setShowConfirmationModal({ 
            visibility: true, 
            text: { 
                title: t("home_channels.lists_channels.list_emails.active_access"), 
                body: t("home_channels.lists_channels.list_emails.body_text"), 
                id: currentEmailName + "?", 
                message: t("home_channels.lists_channels.list_emails.message_middle"), 
                middleText: t("home_channels.lists_channels.list_emails.middle_text"), 
                warning: t("home_channels.lists_channels.list_emails.get_to"), 
                end: t("home_channels.lists_channels.list_emails.in_cxpress"), 
                buttonConfirmationText: t("home_channels.lists_channels.list_emails.active_access_buttom"), 
                buttonReturnText: t("home_channels.lists_channels.list_emails.cancel_buttom") 
            }, 
            functionConfirmation: enableEmailFunction 
        }));
    }

    const disableEmailConfirm = () => {
        dispatch(setShowConfirmationModal({ 
            visibility: true, 
            text: { 
                title: t("home_channels.lists_channels.list_emails.revoke_access"), 
                body: t("home_channels.lists_channels.list_emails.he_is_sure"), 
                id: currentEmailName + "?", 
                message: t("home_channels.lists_channels.list_emails.send_message"), 
                middleText: t("home_channels.lists_channels.list_emails.for_email"), 
                warning: t("home_channels.lists_channels.list_emails.will_not_arrive"), 
                end: t("home_channels.lists_channels.list_emails.in_cxpress"), 
                buttonConfirmationText: t("home_channels.lists_channels.list_emails.revoke_access"), 
                buttonReturnText: t("home_channels.lists_channels.list_emails.cancel_buttom") 
            }, 
            functionConfirmation: disableEmailFunction 
        }));
    }

    const mainEmail = () => {
        dispatch(setShowConfirmationModal({ 
            visibility: true, 
            text: { 
                title: t("home_channels.lists_channels.list_emails.email_main"), 
                body: t("home_channels.lists_channels.list_emails.become_email_main"), 
                id: currentEmailName + "?", 
                buttonConfirmationText: 'Ok', 
                buttonReturnText: t("home_channels.lists_channels.list_emails.cancel_buttom")
            }, 
            functionConfirmation: mainEmailConfirmation 
        }));
    }

    const disableMainEmail = () => {
        dispatch(setShowConfirmationModal({ 
            visibility: true, 
            text: { 
                "title": t("home_channels.lists_channels.list_emails.email_main"), 
                "body": t("home_channels.lists_channels.list_emails.disable_email"), 
                "id": currentEmailName + "?", 
                "buttonConfirmationText": 'Ok', 
                "buttonReturnText": t("home_channels.lists_channels.list_emails.cancel_buttom") 
            }, 
            functionConfirmation: mainEmailConfirmationDisable 
        }));
    }

    const popover = (item: { active: boolean; is_primary: boolean; email_suport: boolean }) => {
        return (
            <Popover id={currentEmailId} style={{ zIndex: '500', backgroundColor: '#e6e6e6', border: 'none', color: '#40484E', font: 'var(--px15_19-font-Roboto)' }}>
                <Popover.Body className="nopadding">
                    {item.email_suport === false ?
                        <>
                            <Button onClick={showEditModal} className="m-2" style={{ backgroundColor: 'transparent', border: 'none', color: 'black', fontSize: 'inherit' }}>{t("home_channels.lists_channels.list_emails.edit_information")}</Button>
                            <div style={{ backgroundColor: '#707070', opacity: '0.5', height: '0.1vh', width: '100%' }}></div>
                        </> :
                        <></>
                    }
                    {item.active && item.is_primary === false?
                        <Button onClick={mainEmail} className="m-2" style={{ backgroundColor: 'transparent', border: 'none', color: 'black', fontSize: 'inherit' }}>{t("home_channels.lists_channels.list_emails.make_main")}</Button>:
                        <></>
                    }
                    {item.active && item.is_primary === true?
                        <Button onClick={disableMainEmail} className="m-2" style={{ backgroundColor: 'transparent', border: 'none', color: 'black', fontSize: 'inherit' }}>{t("home_channels.lists_channels.list_emails.revoke_main")}</Button>:
                        <></>
                    }
                    {item.active && item.email_suport === false && 
                        <>
                            <div style={{ backgroundColor: '#707070', opacity: '0.5', height: '0.1vh', width: '100%' }}></div>
                            <Button onClick={disableEmailConfirm} name="ButtonViewRelatedTags" className="m-2" style={{ backgroundColor: 'transparent', border: 'none', color: 'black', fontSize: 'inherit' }}>{t("home_channels.lists_channels.list_emails.revoke_access")}</Button>
                        </>
                    }
                    {!item.active && item.email_suport === false && 
                        <>
                            <div style={{ backgroundColor: '#707070', opacity: '0.5', height: '0.1vh', width: '100%' }}></div>
                            <Button onClick={activeEmailConfirm} name="ButtonViewRelatedTags" className="m-2" style={{ backgroundColor: 'transparent', border: 'none', color: 'black', fontSize: 'inherit' }}>{t("home_channels.lists_channels.list_emails.active_access_buttom")}</Button>
                        </>
                    }
                </Popover.Body>
            </Popover>
        )
    };

    return <ListOfEmails
        props={props}
        isLoading={isLoading}
        values={values}
        currentEmailData={currentEmailData}
        setCurrentEmailInfo={setCurrentEmailInfo}
        popover={popover}
        t={t}
    />
}

export default ListOfEmailsController;