import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setShowAlertFeedback } from '../../store/internal';
import AlertUpdateTicketBySector from './index'

const AlertUpdateTicketBySectorController = (props) => {
  const dispatch = useDispatch();

  const displayFunction = () => {
    return props.visibility ? 'block' : 'none'
  }

  const refreshWindow = () => {
    dispatch(setShowAlertFeedback({ visibility: false, message: "", signalIcon: false }));
    props.setVisibility(false);
    props.updateList()
  }

  return (
    <AlertUpdateTicketBySector 
      displayFunction={displayFunction}
      refreshWindow={refreshWindow}
    />
  );
}

export default AlertUpdateTicketBySectorController;