import { FC, useEffect, useState } from "react";
import { ModalExportTicketsInterface } from "./indexModel";
import ModalExportTickets from ".";
import { useDispatch, useSelector } from "react-redux";
import { setShowExportTicketModal, setShowAlertFeedback } from "../../../../store/internal";
import { useTranslation } from "react-i18next";
import constsApi from "../../../../services/constsApi";
import UserService from "../../../../services/user-service";
import { AppRequesterController } from "../../../../services/appRequester/appRequesterController";
import {
  DataSectorActivesInterface,
  DataTagIdInterface,
  DataUserInterface,
  TokenInterface,
} from "../../../../services/requestsInterfacesModel";
import { useNavigate } from "react-router-dom";
import { getToken } from "../../../../store/token";
import { getIdCompany } from "../../../../store/company";
import { verifyCode } from "../../../../services/codeCxpressInterface";
import { differenceInDays,  } from "date-fns";
import { getProfileId } from "../../../../store/user";

const ModalExportTicketsController: FC<ModalExportTicketsInterface> = (
  props
) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [validDate, setValidDate] = useState(validateDate());
  const [showLinks, setShowLinks] = useState(false);
  const [links, setLinks] = useState([])
  const values: TokenInterface = {
    user: {
      profile_id: useSelector(getProfileId),
    },
    token: {
      value: useSelector(getToken),
    },
  };
  const companyId = useSelector(getIdCompany);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [modalShow, setModalShow] = useState(props.visibility);
  const [isReseting, setIsReseting] = useState(false);
  const AppRequesterConst = new AppRequesterController();
  useEffect(() => {
    setModalShow(props.visibility);
    if (props.visibility === true) {
      setSelectedChannel(props.filters.selectedChannel || [])
      setSelectedAgents(props.filters.selectedAgents || [])
      setSelectedDepartments(props.filters.selectedDepartments || [])
      setSelectedTags(props.filters.selectedTags || [])
      setSelectedStatus(props.filters.selectedStatus || [])
      onChangeDate(props.filters.dates)
      getAgents();
      getTags();
      if (values.user.profile_id !== constsApi.profile[1].id) {
        getDepartments();
      }
    }
  }, [props.visibility]);

  function Hide() {
    dispatch(setShowExportTicketModal(false));
    setModalShow(false)
    setShowLinks(false)
    setLinks([])
    reset()
  };

  function reset() {
    setIsReseting(true)
    resetDate()
  }

  // Canal
  const [channelOptions, setChannelOptions] = useState([
    { label: t("filter_option.channel_email"), value: constsApi.channel[0].id },
    { label: "Facebook", value: constsApi.channel[3].id },
    { label: "Instagram", value: constsApi.channel[4].id },
    {
      label: t("filter_option.channel_telephony"),
      value: constsApi.channel[1].id,
    },
    { label: "Webchat", value: constsApi.channel[5].id },
    { label: "WhatsApp", value: constsApi.channel[2].id },
  ]);

  const [selectedChannel, setSelectedChannel] = useState([]);
  const channelOverrideStrings = {
    allItemsAreSelected: `${t("filter_option.allItemsAreSelectedCanal")}`,
    clearSearch: `${t("filter_option.clearSearchCanal")}`,
    clearSelected: `${t("filter_option.clearSelectedCanal")}`,
    noOptions: `${t("filter_option.noOptionsCanal")}`,
    search: `${t("filter_option.searchCanal")}`,
    selectAll: `${t("filter_option.selectAllCanal")}`,
    selectAllFiltered: `${t("filter_option.selectAllFilteredCanal")}`,
    selectSomeItems: `${t("filter_option.canal")}`,
    create: `${t("filter_option.create")}`,
  };

  // Atribuido
  const [agentOptions, setAgentOptions] = useState([]);
  const [selectedAgents, setSelectedAgents] = useState([]);

  const getAgents = async () => {
    const headers = UserService.getHeaders();

    await AppRequesterConst.Get(
      "/agent",
      { headers },
      (response: Object) => {
        return response;
      },
      (data: DataUserInterface) => {
        if (data.status === 200 && data.data.employees) {
          let agents = [];
          data.data.employees.map((item) => {
            agents.push({ label: item.name, value: item.user_id });
          });
          setAgentOptions((state) => {
            return state.concat(agents);
          });
        }
      },
      (error: Object) => {},
      navigate,
      dispatch,
      setIsLoading,
      { values: values }
    );
  };
  const agentsOverrideStrings = {
    allItemsAreSelected: `${t("filter_option.allItemsAreSelectedAgente")}`,
    clearSearch: `${t("filter_option.clearSearchAgente")}`,
    clearSelected: `${t("filter_option.clearSelectedAgente")}`,
    noOptions: `${t("filter_option.noOptionsAgente")}`,
    search: `${t("filter_option.searchAgente")}`,
    selectAll: `${t("filter_option.selectAllAgente")}`,
    selectAllFiltered: `${t("filter_option.selectAllFilteredAgente")}`,
    selectSomeItems: `${t("filter_option.atribuicao")}`,
    create: `${t("filter_option.create")}`,
  };

  // Status
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [statusOptions, setStatusOptions] = useState([
    { label: t("ticket_status.resolved"), value: constsApi.status[1].id },
    { label: t("ticket_status.abandoned"), value: constsApi.status[5].id },
  ]);

  const statusOverrideStrings = {
    allItemsAreSelected: `${t('filter_option.allItemsAreSelectedStatus')}`,
    clearSearch: `${t('filter_option.clearSearchStatus')}`,
    clearSelected: `${t('filter_option.clearSelectedStatus')}`,
    noOptions: `${t('filter_option.noOptionsStatus')}`,
    search: `${t('filter_option.searchStatus')}`,
    selectAll: `${t('filter_option.selectAllStatus')}`,
    selectAllFiltered: `${t('filter_option.selectAllFilteredStatus')}`,
    selectSomeItems: `${t('filter_option.status')}`,
    create: `${t('filter_option.create')}`,
  }

  // Setor
  const [departmentOptions, setDepartmentsOptions] = useState([]);
  const [selectedDepartments, setSelectedDepartments] = useState([]);

  const getDepartments = async () => {
    const headers = UserService.getHeaders();

    await AppRequesterConst.Get(
      "/sector/actives/",
      { headers, params: { companyid: companyId } },
      (response: Object) => {},
      (data: DataSectorActivesInterface) => {
        if (data.status === 200 && data.data.sectors) {
          let departments = [];
          data.data.sectors.map((item) => {
            departments.push({ label: item.name, value: item.id });
          });
          setDepartmentsOptions((state) => {
            return state.concat(departments);
          });
        }
      },
      (error: Object) => {},
      navigate,
      dispatch,
      setIsLoading,
      { values: values }
    );
  };

  const departmentOverrideStrings = {
    allItemsAreSelected: `${t("filter_option.allItemsAreSelectedSetor")}`,
    clearSearch: `${t("filter_option.clearSearchSetor")}`,
    clearSelected: `${t("filter_option.clearSelectedSetor")}`,
    noOptions: `${t("filter_option.noOptionsSetor")}`,
    search: `${t("filter_option.searchSetor")}`,
    selectAll: `${t("filter_option.selectAllSetor")}`,
    selectAllFiltered: `${t("filter_option.selectAllFilteredSetor")}`,
    selectSomeItems: `${t("filter_option.setor")}`,
    create: `${t("filter_option.create")}`,
  };

  // Tags
  const [tagsOptions, setTagsOptions] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);

  const getTags = async () => {
    const headers = UserService.getHeaders();

    await AppRequesterConst.Get(
      "/tag",
      { headers },
      (response: Object) => {},
      (data: DataTagIdInterface) => {
        if (data.status === 200 && data.data.tags) {
          let tags = [];
          data.data.tags.map((item) => {
            tags.push({ label: item.name, value: item.id });
          });
          setTagsOptions((state) => {
            return state.concat(tags);
          });
        }
      },
      (error: Object) => {},
      navigate,
      dispatch,
      setIsLoading,
      { values: values }
    );
  };

  const tagsOverrideStrings = {
    allItemsAreSelected: `${t("filter_option.allItemsAreSelectedTag")}`,
    clearSearch: `${t("filter_option.clearSearchTag")}`,
    clearSelected: `${t("filter_option.clearSelectedTag")}`,
    noOptions: `${t("filter_option.noOptionsTag")}`,
    search: `${t("filter_option.searchTag")}`,
    selectAll: `${t("filter_option.selectAllTag")}`,
    selectAllFiltered: `${t("filter_option.selectAllFilteredTag")}`,
    selectSomeItems: `${t("filter_option.tags")}`,
    create: `${t("filter_option.create")}`,
  };

  const selectItems = [
    {
      title: "Canal",
      options: channelOptions,
      setOptions: setChannelOptions,
      selected: selectedChannel,
      setSelected: setSelectedChannel,
      overrideStrings: channelOverrideStrings,
    },
    {
      title: "Atribuicao",
      options: agentOptions,
      setOptions: setAgentOptions,
      selected: selectedAgents,
      setSelected: setSelectedAgents,
      overrideStrings: agentsOverrideStrings,
    },
    {
      title: "Status",
      options: statusOptions,
      setOptions: setStatusOptions,
      selected: selectedStatus,
      setSelected: setSelectedStatus,
      overrideStrings: statusOverrideStrings,
    },
    {
      title: "Setor",
      options: departmentOptions,
      setOptions: setDepartmentsOptions,
      selected: selectedDepartments,
      setSelected: setSelectedDepartments,
      overrideStrings: departmentOverrideStrings,
    },
    {
      title: "Tags",
      options: tagsOptions,
      setOptions: setTagsOptions,
      selected: selectedTags,
      setSelected: setSelectedTags,
      overrideStrings: tagsOverrideStrings,
    },
  ];

  // Date
  const onChangeDate = (dates: any) => {
    if (dates) {
      const [start, end] = dates;
      setStartDate(start);
      setEndDate(end);
    }
  };

  useEffect(() => {
    setValidDate(validateDate())
  }, [startDate, endDate]);

  function validateDate() {
    return (startDate && endDate) && differenceInDays(endDate, startDate) <= 31;
  }

  function resetDate() {
    setStartDate(null);
    setEndDate(null);
  }

  const exportTickets = (hasAdditionalContent: boolean) => {
    const filter = {
      start_date: startDate.toISOString().split('T')[0],
      end_date: endDate.toISOString().split('T')[0],
      has_additional_content: hasAdditionalContent,
      deleted: props.currentState === 'deleted',
      level: 2
    };
    selectedChannel.length > 0 && (filter['channel'] = selectedChannel.map(item => item.value).toString());
    selectedAgents.length > 0 && (filter['employee'] = selectedAgents.map(item => item.value).toString());
    selectedDepartments.length > 0 && (filter['sector'] = selectedDepartments.map(item => item.value).toString());
    selectedTags.length > 0 && (filter['tags'] = selectedTags.map(item => item.value).toString());
    selectedStatus.length > 0 && (filter['status'] = selectedStatus.map(item => item.value).toString());
    postExport(filter)
    setShowLinks(true);
  };

  const postExport = async (filters) => {
    const headers = {
      "Content-Type": "application/json; charset=utf-8",
      "Authorization": "Bearer " + values.token.value
    };
    
    await AppRequesterConst.Post(
      '/ticket/historic/export',
      filters,
      { headers },
      (response: Object) => {
        return response;
      },
      (data) => {
          if (data.status) { 
            if (data.data.links) {
              setLinks(data.data.links)
            }
          }
      },
      (error: {code_cxpress: number, message: string}) => {
        dispatch(setShowAlertFeedback({ message: verifyCode(error.code_cxpress, t), visibility: true, signalIcon: false })); 
        setLinks([])
        setShowLinks(false);
      },
      navigate,
      dispatch,
      setIsLoading,
    );
  }

  return (
    <ModalExportTickets
      modalShow={modalShow}
      Hide={Hide}
      selectItems={selectItems}
      startDate={startDate}
      endDate={endDate}
      onChangeDate={onChangeDate}
      exportTickets={exportTickets}
      showLinks={showLinks}
      links={links}
      isReseting={isReseting}
      setIsReseting={setIsReseting}
      reset={reset}
      isLoading={isLoading}
      t={t}
      validDate={validDate}
    />
  );
};

export default ModalExportTicketsController;
