import { Button, Popover } from "react-bootstrap"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { setShowAlertFeedback, setShowConfirmationModal } from "../../../../../store/internal"
import UserService from "../../../../../services/user-service"
import { AppRequesterController } from "../../../../../services/appRequester/appRequesterController"
import ListOfQuickMessage from "."
import { useTranslation } from "react-i18next"
import Utils from "../../../../../core/shared/utils"

const ListOfQuickMessageController = (props) => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { t } = useTranslation()

    const AppRequesterConst = new AppRequesterController()

    const [showQuickMessageModalUpdate, setShowQuickMessageModalUpdate] = useState(false)
    const [currentQuickMessageId, setCurrentQuickMessageId] = useState('')
    const [currentQuickMessageTitle, setCurrentQuickMessageTitle] = useState('')
    const [/*isLoading*/, setIsLoading] = useState(false)
    const [quickMessageById, setQuickMessageById] = useState("")


    const handleCloseQuickMessageModalUpdate = () => {
        setShowQuickMessageModalUpdate(false)
        setCurrentQuickMessageId("")
    }
    const handleShowQuickMessageModalUpdate = () => setShowQuickMessageModalUpdate(true)

    useEffect(() => {
        Utils.changeActiveScrollBar("listOfQuickMessage");
    }, [props.data]);

    useEffect(() => {
        const controller = new AbortController()

        getInformationQuickMessageById(currentQuickMessageId, controller)

    }, [currentQuickMessageId])

    useEffect(() => {
        Utils.changeActiveScrollBar("listOfQuickMessage");
    }, [props.data]);

    const deleteQuickMessageRequest = async (id: String) => {
        const headers = UserService.getHeaders()

        await AppRequesterConst.Delete(
            `template-message/quick-message/${id}`, { headers: headers },
            (response: Object) => { },
            (data) => {
                dispatch(setShowAlertFeedback({ message: t('configurations_ticket.list_of_quick_messages.delete_quick_message_request.message'), visibility: true, signalIcon: true }))
                props.updateListAfterDelete()
            },
            (error) => {
                dispatch(setShowAlertFeedback({ message: t('configurations_ticket.list_of_quick_messages.delete_quick_message_request.error'), visibility: true, signalIcon: false }))
            }, navigate, dispatch, setIsLoading
        )
    }

    const getQuickMessageById = async (id: String, controller: AbortController) => {
        const headers = UserService.getHeaders()

        const signal = controller.signal

        await AppRequesterConst.Get(
            `template-message/quick-message/${id}`, { headers, signal },
            (response: Object) => { },
            (data) => {
                if (data.status === 200 && data) {
                    setQuickMessageById(data.data.quickMessage)
                }
            },
            (error) => { }, navigate, dispatch, setIsLoading, {}
        )
    }

    const setCurrentQuickMessageInfo = (e: { currentTarget: { id: string } }) => {
        setCurrentQuickMessageId(e.currentTarget.id.split("*ID*")[1])
        setCurrentQuickMessageTitle(e.currentTarget.id.split("*ID*")[0])
    }

    const getInformationQuickMessageById = (id: string, controller: AbortController) => {
        getQuickMessageById(id, controller)
    }

    const deleteQuickMessage = () => {
        dispatch(setShowConfirmationModal({ visibility: true, text: { "title": t('configurations_ticket.list_of_quick_messages.delete_quick_message.title'), "body": t('configurations_ticket.list_of_quick_messages.delete_quick_message.body'), "id": currentQuickMessageTitle + '?', "buttonConfirmationText": t("configurations_ticket.general.delete"), 'buttonReturnText': t('configurations_ticket.list_of_quick_messages.delete_quick_message.cancel') }, functionConfirmation: () => deleteQuickMessageRequest(currentQuickMessageId) }))
    }

    const popover = (
        <Popover style={{ zIndex: '500', backgroundColor: '#e6e6e6', border: 'none', color: '#40484E', font: 'var(--px15_19-font-Roboto)' }}>
            <Popover.Body className="nopadding">
                <Button className="m-1" style={{ backgroundColor: 'transparent', border: 'none', color: 'black', fontSize: 'inherit' }} onClick={handleShowQuickMessageModalUpdate}>{t('configurations_ticket.list_of_quick_messages.edit')}</Button>
                <div style={{ backgroundColor: '#707070', opacity: '0.5', height: '1px', width: '100%' }}></div>
                <Button className="m-1" style={{ backgroundColor: 'transparent', border: 'none', color: 'black', fontSize: 'inherit' }} onClick={deleteQuickMessage}>{t('configurations_ticket.list_of_quick_messages.delete')}</Button>
            </Popover.Body>
        </Popover>
    )

    return <ListOfQuickMessage
        t={t}
        showQuickMessageModalUpdate={showQuickMessageModalUpdate}
        handleCloseQuickMessageModalUpdate={handleCloseQuickMessageModalUpdate}
        quickMessageById={quickMessageById}
        props={props}
        setCurrentQuickMessageInfo={setCurrentQuickMessageInfo}
        popover={popover}
    />
}

export default ListOfQuickMessageController