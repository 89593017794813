import { useEffect, useState } from "react";
import ChatbotSurveyTab from ".";
import { t, use } from "i18next";
import { TokenInterface } from "../../../../../services/requestsInterfacesModel";
import { getIdCompany } from "../../../../../store/company";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import UserService from "../../../../../services/user-service";
import { setShowAlertFeedback } from "../../../../../store/internal";
import { AppRequesterController } from "../../../../../services/appRequester/appRequesterController";


const ChatbotSurveyTabController = ({
    lifeTime,
    lifeTimeLevelOne,
    enableNpsLevelOne,
    enableBotCSAT,
    npsWaitTimeLevelOne,
    npsBotInitialMessage,
    npsBotFinalMessage,
    npsBotInvalidResponse,
    consumerDowntimeHour,
    hourWaitSurvey,
    enableInactivityMessage,
    inactivityMessage,
}) => {
    const AppRequesterConst = new AppRequesterController();
    
    const values: TokenInterface = {
        company: {
            id: useSelector(getIdCompany)
        }
    };
    
    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    const [hourLevelOne, setHourLevelOne] = useState("");
    const [showFeedbackTimeLevelOne, setShowFeedbackTimeLevelOne] = useState(false);
    
    const [ableDisableLevelOne, setAbleDisableLevelOne] = useState(enableNpsLevelOne);
    const [ableDisableBotCSAT, setAbleDisableBotCSAT] = useState(enableBotCSAT);

    const [initialBotMessage, setInitialBotMessage] = useState("");
    const [botInvalidResponse, setBotInvalidResponse] = useState("");
    const [showFeedbackInitialBotMessage, setShowFeedbackInitialBotMessage] = useState(false);

    const [finalBotMessage, setFinalBotMessage] = useState("");
    const [showFeedbackFinalBotMessage, setShowFeedbackFinalBotMessage] = useState(false);

    const [resetBotMessages, setResetBotMessages] = useState(false);

    const [showFeedbackBotInvalidResponse, setShowFeedbackBotInvalidResponse] = useState(false);
    const [showFeedbackTimeConsumerDowntime, setShowFeedbackTimeConsumerDowntime] = useState(false);
    const [consumerDowntime, setConsumerDowntime] = useState("");
    const [npsWaitTimeSurvey, setNpsWaitTimeSurvey] = useState("")
    const [showFeedbackTimeWaitSurvey, setShowFeedbackTimeWaitSurvey] = useState(false);

    const [/* isLoading */, setIsLoading] = useState(false);

    const [timeStringChatbotWait, setTimeStringChatbotWait] = useState("");
    const [timeStringChatbotSend, setTimeStringChatbotSend] = useState("");
    const [timeStringDowntime, setTimeStringDowntime] = useState("");

    const [inactivityMessageNps, setInactivityMessageNps] = useState("");
    const [ableDisableInactivityMessageNps, setAbleDisableInactivityMessageNps] = useState(true);
    const [showFeedbackInactivityMessage, setShowFeedbackInactivityMessage] = useState(false);

    useEffect(() => {
        extendedHours(hourLevelOne, showFeedbackTimeLevelOne, setTimeStringChatbotSend)
        extendedHours(npsWaitTimeSurvey, showFeedbackTimeWaitSurvey, setTimeStringChatbotWait)
        extendedHours(consumerDowntime, showFeedbackTimeConsumerDowntime, setTimeStringDowntime)
      }, [hourLevelOne, npsWaitTimeSurvey, consumerDowntime])

    useEffect(() => {
        setHourLevelOne(npsWaitTimeLevelOne !== null ? convertMinToHour(npsWaitTimeLevelOne) : '00:00');
        setAbleDisableLevelOne(enableNpsLevelOne);
        setAbleDisableBotCSAT(enableBotCSAT);
        setInitialBotMessage(npsBotInitialMessage ? npsBotInitialMessage : "");
        setFinalBotMessage(npsBotFinalMessage ? npsBotFinalMessage : "");
        setBotInvalidResponse(npsBotInvalidResponse ? npsBotInvalidResponse : "");
        setResetBotMessages(false);
        setConsumerDowntime(convertNumToHour(consumerDowntimeHour));
        setNpsWaitTimeSurvey(hourWaitSurvey !== null ? convertMinToHour(hourWaitSurvey) : '00:00')
        setInactivityMessageNps(inactivityMessage ? inactivityMessage : "")
        setAbleDisableInactivityMessageNps(enableInactivityMessage)
    }, [enableNpsLevelOne, enableBotCSAT, npsWaitTimeLevelOne, npsBotInitialMessage, npsBotFinalMessage, npsBotInvalidResponse, hourWaitSurvey, enableInactivityMessage, inactivityMessage])

    const converterMinuto = (hora: string) => {
        let separaHora = hora.split(":")
        let Minutos = parseInt(separaHora[0]) * 60 + parseInt(separaHora[1])
        return (Minutos)
    }

    const converterHora = (hora: string) => {
        let separaHora = hora.split(":")
        let Data = new Date(2000, 1, 1, parseInt(separaHora[0]), parseInt(separaHora[1]))
        return (Data)
    }

    const convertNumToHour = (num: number) => {
        // var hour = Math.floor(num);
        // var dec = num - hour;
        // var min = 1 / 60;
        // dec = min * Math.round(dec / min);

        // var minute = Math.floor(dec * 60) + '';
        // if (minute.length < 2) {
        //     minute = '0' + minute;
        // }

        // return hour + ':' + minute

        // Calcula o número de horas inteiras
        const hour = Math.floor(num / 60);
        // Calcula o número de minutos restantes
        const minute = num % 60;
        
        // Formata as horas e os minutos para terem dois dígitos
        const formattedHour = hour < 10 ? '0' + hour : hour;
        const formattedMinute = minute < 10 ? '0' + minute : minute;
        
        // Retorna a string no formato "hh:mm"
        return formattedHour + ':' + formattedMinute;
    }

    
    const convertMinToHour = (num: number) => {
        var dec = num / 60;
        var hour = Math.floor(dec);
        var min = dec - hour
        min = 60 * min;
        
        if (hour.toString().length < 2) {
            var hora = '0' + hour
        }
        if (min.toString().length < 2) {
            var minute = '0' + min
        }
        
        return (hora ? hora : hour) + ':' + (minute ? minute : min)
    }

    const validate = (values) => {
        let errors = {};

        setHourLevelOne(values.hourLevelOne);
        setConsumerDowntime(values.consumerDowntime);
        setInitialBotMessage(values.initial_bot_message);
        setFinalBotMessage(values.final_bot_message);
        setBotInvalidResponse(values.nps_bot_invalid_answer_message);
        setNpsWaitTimeSurvey(values.npsWaitTimeSurvey);
        setInactivityMessageNps(values.inactivity_message_nps);

        if (!values.hourLevelOne && !ableDisableLevelOne) {
            setShowFeedbackTimeLevelOne(false)
        }
        else if (!values.hourLevelOne && ableDisableLevelOne) {
            errors['hourLevelOne'] = t('configurations_ticket.survey_tab.validate.hour')
            setShowFeedbackTimeLevelOne(true);
        } else if (converterHora(values.hourLevelOne).toString() === "Invalid Date" ||
            parseInt(values.hourLevelOne.split(":")[0]) > parseInt(convertNumToHour(lifeTimeLevelOne).split(":")[0]) ||
            parseInt(values.hourLevelOne.split(":")[1]) > 59) {

            errors["hourLevelOne"] = `${t('configurations_ticket.survey_tab.validate.valid_hour')} ${convertNumToHour(lifeTimeLevelOne)}`;
            setShowFeedbackTimeLevelOne(true);
        } else if (parseInt(values.hourLevelOne.split(":")[0]) === parseInt(convertNumToHour(lifeTimeLevelOne).split(":")[0]) &&
            parseInt(values.hourLevelOne.split(":")[1]) > parseInt(convertNumToHour(lifeTimeLevelOne).split(":")[1])) {
            errors["hourLevelOne"] = `${t('configurations_ticket.survey_tab.validate.valid_hour')} ${convertNumToHour(lifeTimeLevelOne)}`;
            setShowFeedbackTimeLevelOne(true);
        } else {
            setShowFeedbackTimeLevelOne(false);
        }

        if (resetBotMessages) {
            setShowFeedbackInitialBotMessage(false);
        } else if (values.initial_bot_message?.length > 1000) {
            setShowFeedbackInitialBotMessage(true);
            errors['initial_bot_message'] = t('configurations_ticket.survey_tab.validate.message_limit_max');
        } else if (values.initial_bot_message === "") {
            setShowFeedbackInitialBotMessage(true);
            errors['initial_bot_message'] = t('configurations_ticket.survey_tab.validate.message_limit_min');
        } else {
            setShowFeedbackInitialBotMessage(false);
        }

        if (resetBotMessages) {
            setShowFeedbackFinalBotMessage(false);
        } else if (values.final_bot_message?.length > 1000) {
            setShowFeedbackFinalBotMessage(true);
            errors['final_bot_message'] = t('configurations_ticket.survey_tab.validate.message_limit_max');
        } else if (values.final_bot_message === "") {
            setShowFeedbackFinalBotMessage(true);
            errors['final_bot_message'] = t('configurations_ticket.survey_tab.validate.message_limit_min');
        } else {
            setShowFeedbackFinalBotMessage(false);
        }

        if (resetBotMessages) {
            setShowFeedbackBotInvalidResponse(false);
        } else if (values.nps_bot_invalid_answer_message?.length > 1000) {
            setShowFeedbackBotInvalidResponse(true);
            errors['nps_bot_invalid_answer_message'] = t('configurations_ticket.survey_tab.validate.message_limit_max');
        } else if (values.nps_bot_invalid_answer_message === "") {
            setShowFeedbackBotInvalidResponse(true);
            errors['nps_bot_invalid_answer_message'] = t('configurations_ticket.survey_tab.validate.message_limit_min');
        } else {
            setShowFeedbackBotInvalidResponse(false);
        }

        if (!values.consumerDowntime) {
            errors['consumerDowntime'] = t('configurations_ticket.survey_tab.validate.hour')
            setShowFeedbackTimeConsumerDowntime(true);
        } else if (converterHora(values.consumerDowntime).toString() === "Invalid Date" ||
            parseInt(values.consumerDowntime.split(":")[0]) > parseInt(convertNumToHour(lifeTimeLevelOne).split(":")[0]) ||
            parseInt(values.consumerDowntime.split(":")[1]) > 59) {

            errors["consumerDowntime"] = `${t('configurations_ticket.survey_tab.validate.valid_hour')} ${convertNumToHour(lifeTimeLevelOne)}`;
            setShowFeedbackTimeConsumerDowntime(true);

        } else if (parseInt(values.consumerDowntime.split(":")[0]) === parseInt(convertNumToHour(lifeTimeLevelOne).split(":")[0]) &&
            parseInt(values.consumerDowntime.split(":")[1]) > parseInt(convertNumToHour(lifeTimeLevelOne).split(":")[1])) {

            errors["consumerDowntime"] = `${t('configurations_ticket.survey_tab.validate.valid_hour')} ${convertNumToHour(lifeTimeLevelOne)}`;
            setShowFeedbackTimeConsumerDowntime(true);

        } else {
            setShowFeedbackTimeConsumerDowntime(false);
        }

        if (!values.npsWaitTimeSurvey && !ableDisableLevelOne) {
            setShowFeedbackTimeWaitSurvey(false);
        }
        else if (!values.npsWaitTimeSurvey && ableDisableLevelOne) {
            errors['npsWaitTimeSurvey'] = t('configurations_ticket.survey_tab.validate.hour')
            setShowFeedbackTimeWaitSurvey(true);
        }
        else if (converterHora(values.npsWaitTimeSurvey).toString() === "Invalid Date" ||
            parseInt(values.npsWaitTimeSurvey.split(":")[0]) > parseInt(convertNumToHour(lifeTimeLevelOne).split(":")[0]) ||
            parseInt(values.npsWaitTimeSurvey.split(":")[1]) > 59) {
            errors["npsWaitTimeSurvey"] = `${t('configurations_ticket.survey_tab.validate.valid_hour')} ${convertNumToHour(lifeTimeLevelOne)}`;
            setShowFeedbackTimeWaitSurvey(true);
        }
        else if (values.npsWaitTimeSurvey.split(":")[0] === parseInt(convertNumToHour(lifeTimeLevelOne).split(":")[0]) &&
            parseInt(values.npsWaitTimeSurvey.split(":")[1]) > parseInt(convertNumToHour(lifeTimeLevelOne).split(":")[1])) {
            errors["npsWaitTimeSurvey"] = `${t('configurations_ticket.survey_tab.validate.valid_hour')} ${convertNumToHour(lifeTimeLevelOne)}`;
            setShowFeedbackTimeWaitSurvey(true);
        }
        else {
            setShowFeedbackTimeWaitSurvey(false);
        }

        if (values.inactivity_message_nps?.length > 4096) {
            setShowFeedbackInactivityMessage(true);
            errors['inactivity_message_nps'] = t('configurations_ticket.survey_tab.validate.message_limit_max');
        } else if (values.inactivity_message_nps === "") {
            setShowFeedbackInactivityMessage(true);
            errors['inactivity_message_nps'] = t('configurations_ticket.survey_tab.validate.message_limit_min');
        } else {
            setShowFeedbackInactivityMessage(false);
        }

        return errors;
    }

    const onSubmit = async () => {
        let JsonSend = {
            id: values.company.id,
            enable_nps_level_one: ableDisableLevelOne,
            enable_check_nps_bot_answer: ableDisableBotCSAT,
            nps_wait_time_level_one: ableDisableLevelOne ? converterMinuto(hourLevelOne) : undefined,
            nps_n1_time_to_await_response: ableDisableLevelOne ? converterMinuto(npsWaitTimeSurvey) : undefined,
            send_inactivity_message: ableDisableInactivityMessageNps
        }

        if(ableDisableInactivityMessageNps){
            JsonSend['consumer_downtime'] = converterMinuto(consumerDowntime)
            JsonSend['inactivity_message'] = inactivityMessageNps;
        }

        if (initialBotMessage.length > 0) {
            JsonSend['nps_bot_initial_message'] = initialBotMessage;
        }

        if (finalBotMessage.length > 0) {
            JsonSend['nps_bot_final_message'] = finalBotMessage;
        }

        if (botInvalidResponse.length > 0) {
            JsonSend['nps_bot_invalid_answer_message'] = botInvalidResponse;
        }

        const headers = UserService.getHeaders();
        await AppRequesterConst.Put(
            '/company', JsonSend, { headers: headers },
            (response: Object) => { },
            (data) => {
                dispatch(setShowAlertFeedback({ message: t('configurations_ticket.survey_tab.submit.message'), visibility: true, signalIcon: true }));

                /* if (data.status === 200 && data?.data?.company ) {
                  let initialAgentMsg = data.data.company.nps_agent_initial_message;
                  let finalAgentnMsg = data.data.company.nps_agent_final_message;
                  let initialBotMsg = data.data.company.nps_bot_initial_message;
                  let finalBotMsg = data.data.company.nps_bot_final_message;
    
                  setResetAgentMessages(false);
                  setResetBotMessages(false);
    
                  setInitialAgentMessage(initialAgentMsg);
                  setFinalAgentMessage(finalAgentnMsg);
                  setInitialBotMessage(initialBotMsg);
                  setFinalBotMessage(finalBotMsg);
                } */
            },
            (error: any) => {
                if (error.response.status === 400) {
                    dispatch(setShowAlertFeedback({ message: error.response.data.message, visibility: true, signalIcon: false }));
                } else if (error.response.status === 500 && error.response.data.code_cxpress === 1201) {
                    dispatch(setShowAlertFeedback({ message: t('configurations_ticket.survey_tab.submit.error500'), visibility: true, signalIcon: false }));
                } /* else if (error.response.status === 422 && error.response.data.message[0].path[0] === 'nps_wait_time_level_one') {
                    dispatch(setShowAlertFeedback({ message: t('configurations_ticket.survey_tab.submit.error422'), visibility: true, signalIcon: false }));
                } else if (error.response.status === 422 && error.response.data.message[0].path[0] !== 'nps_wait_time_level_one') {
                    dispatch(setShowAlertFeedback({ message: t('configurations_ticket.survey_tab.submit.hour_error'), visibility: true, signalIcon: false }));
                } */ 
                else if(error.response.status === 422){
                    if(error.response.data.message && error.response.data.message[0] && error.response.data.message[0].path){
                        if (error.response.data.message[0].path[0] === 'nps_wait_time_level_one'){
                            dispatch(setShowAlertFeedback({ message: t('configurations_ticket.survey_tab.submit.error422'), visibility: true, signalIcon: false }));
                        } else {
                            dispatch(setShowAlertFeedback({ message: t('configurations_ticket.survey_tab.submit.hour_error'), visibility: true, signalIcon: false }));

                        }
                    } else {
                        dispatch(setShowAlertFeedback({ message: t('configurations_ticket.survey_tab.submit.error_invalid_hour'), visibility: true, signalIcon: false }));

                    }
                } else {
                    dispatch(setShowAlertFeedback({ message: t('configurations_ticket.survey_tab.submit.error'), visibility: true, signalIcon: false }));
                }
            }, navigate, dispatch, setIsLoading,
        );
    }

    const extendedHours = (setTime: any, feedbackTime: any, timeString: Function) => {
        if (setTime) {
            if (!feedbackTime && setTime !== "00:00") {
    
                const hours = parseInt(setTime.split(":")[0]);
                const minutes = setTime.split(":")[1];
    
                let auxTime = "";
    
                if (hours > 0) {
                    auxTime += `${hours} ${t("configurations_ticket.survey_tab.hour")}${hours > 1 ? 's' : ''}`;
                }
    
                if (minutes.split("")[1] === '_') { /* verifica se o ultimo digito ainda está para ser preenchido, e coloca o espaço vazio */
                    auxTime += `${auxTime ? ` ${t("configurations_ticket.survey_tab.label_and")} ` : ''}${minutes}_ ${t("configurations_ticket.survey_tab.minute")}${parseInt(minutes) > 1 ? 's' : ''}`;
                }
                else {
                    if (parseInt(minutes) > 0) {
                        auxTime += `${auxTime ? ` ${t("configurations_ticket.survey_tab.label_and")} ` : ''}${parseInt(minutes)} ${t("configurations_ticket.survey_tab.minute")}${parseInt(minutes) > 1 ? 's' : ''}`;
                    }
                }
                timeString(auxTime);
            }
            else {
                timeString("") /* não mostrar nada caso em que der erro ou estiver 00:00 */
            }
        }
    }

    const resetInitialBotMessage = (values, setValues) => {
        // values['initial_bot_message'] = t('configurations_ticket.survey_tab.nps_default_messages.initial_bot');
        setInitialBotMessage(t('configurations_ticket.survey_tab.nps_default_messages.initial_bot'));
    }

    const resetFinalBotMessage = (values, setValues) => {
        // values['final_bot_message'] = t('configurations_ticket.survey_tab.nps_default_messages.final_bot');
        setFinalBotMessage(t('configurations_ticket.survey_tab.nps_default_messages.final_bot'));
    }

    const resetBotInvalidResponse = (values, setValues) => {
        setBotInvalidResponse(t('configurations_ticket.survey_tab.nps_default_messages.invalid_response'));
    }

    const resetInactivityMessage = (values, setValues) => {
        setInactivityMessageNps(t('configurations_ticket.survey_tab.nps_default_messages.message_inactivity')); /* ADD TRADUÇÃO PARA A MENSAGEM */
    }

    return (
        <ChatbotSurveyTab
            ableDisableLevelOne={ableDisableLevelOne}
            setAbleDisableLevelOne={setAbleDisableLevelOne}
            ableDisableBotCSAT={ableDisableBotCSAT}
            setAbleDisableBotCSAT={setAbleDisableBotCSAT}
            initialBotMessage={initialBotMessage}
            setInitialBotMessage={setInitialBotMessage}
            botInvalidResponse={botInvalidResponse}
            setBotInvalidResponse={setBotInvalidResponse}
            hourLevelOne={hourLevelOne}
            showFeedbackTimeLevelOne={showFeedbackTimeLevelOne}
            setShowFeedbackTimeLevelOne={setShowFeedbackTimeLevelOne}
            finalBotMessage={finalBotMessage}
            setFinalBotMessage={setFinalBotMessage}
            resetBotMessages={resetBotMessages}
            showFeedbackInitialBotMessage={showFeedbackInitialBotMessage}
            showFeedbackFinalBotMessage={showFeedbackFinalBotMessage}
            showFeedbackBotInvalidResponse={showFeedbackBotInvalidResponse}
            setShowFeedbackBotInvalidResponse={setShowFeedbackBotInvalidResponse}
            setShowFeedbackInitialBotMessage={setShowFeedbackInitialBotMessage}
            setShowFeedbackFinalBotMessage={setShowFeedbackFinalBotMessage}
            lifeTimeLevelOne={lifeTimeLevelOne}
            lifeTime={lifeTime}
            convertNumToHour={convertNumToHour}
            resetInitialBotMessage={resetInitialBotMessage}
            resetFinalBotMessage={resetFinalBotMessage}
            resetBotInvalidResponse={resetBotInvalidResponse}
            consumerDowntime={consumerDowntime}
            consumerDowntimeHour={consumerDowntimeHour}
            showFeedbackTimeConsumerDowntime={showFeedbackTimeConsumerDowntime}
            setShowFeedbackTimeConsumerDowntime={setShowFeedbackTimeConsumerDowntime}
            converterMinuto={converterMinuto}
            converterHora={converterHora}
            onSubmit={onSubmit}
            validate={validate}
            npsWaitTimeSurvey={npsWaitTimeSurvey}
            setShowFeedbackTimeWaitSurvey={setShowFeedbackTimeWaitSurvey}
            showFeedbackTimeWaitSurvey={showFeedbackTimeWaitSurvey}
            timeStringChatbotWait={timeStringChatbotWait}
            timeStringChatbotSend={timeStringChatbotSend}
            timeStringDowntime={timeStringDowntime}
            inactivityMessageNps={inactivityMessageNps}
            setInactivityMessageNps={setInactivityMessageNps}
            ableDisableInactivityMessageNps={ableDisableInactivityMessageNps}
            setAbleDisableInactivityMessageNps={setAbleDisableInactivityMessageNps}
            resetInactivityMessage={resetInactivityMessage}
            showFeedbackInactivityMessage={showFeedbackInactivityMessage}
        />
    )
}

export default ChatbotSurveyTabController;