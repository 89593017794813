import React, { useEffect } from 'react';

import { ListGroup, Row, Col, OverlayTrigger, Tooltip, Button, Form } from 'react-bootstrap';

import InfiniteScroll from 'react-infinite-scroll-component';

import Loading from "../../../../../components/loading";
import Utils from "../../../../../core/shared/utils";

import { getShowEditTagsModal } from "../../../../../store/internal";
import { useSelector } from "react-redux";
import ButtonOrderByList from '../../../components/buttonOrderByList';
import { ListName } from '../../../../../core/enums/order-sort-by';
import ModalEditTagsController from '../../../components/modalEditTags/indexController';


const ListTags = ({
    t,
    isLoading,
    token,
    callbackModalTags,
    currentTagData,
    callbackOrderBy,
    datas,
    updateListfunction,
    HasMoreInformation,
    viewTagPage,
    formatedDate,
    popover,
    setCurrentTagInfo,
    activeTagFunction,
    showCreateTag,
    allCheckboxesClick,
    checkboxesClick,
    activationAllSelected,
    deactivationAllSelected,
    deleteAllTag,
    consumer,
    setConsumer
}) => {

    return (
        <>
            <Loading visibility={isLoading}></Loading>
            <ModalEditTagsController token={token} visibility={useSelector(getShowEditTagsModal)} callback={callbackModalTags} data={currentTagData} consumer={consumer} setConsumer={setConsumer}></ModalEditTagsController>
            <div className="ps-3" style={{ marginTop: '2vh', marginLeft: '2vh' }}>
                <ListGroup variant="flush">
                    <ListGroup.Item className="TicketsRowCollums">
                        <Row name="listOfTagsHead">
                            <Col className='nopadding' md={1} style={{ width: '3%' }}>
                                <Form.Check
                                    type='checkbox'
                                    id='Allcheckboxes'
                                    onClick={(e) => { allCheckboxesClick(e) }}
                                    
                                />
                            </Col>
                            <Col md={2} className="nopadding">
                                <ButtonOrderByList title="ID" columnIdName="reference_id" callbackOrderBy={callbackOrderBy} />
                            </Col>
                            <Col md={2} className="nopadding ps-0">
                                <ButtonOrderByList title={t('home_tags.list_tag')} columnIdName="name" callbackOrderBy={callbackOrderBy} />
                            </Col>
                            <Col md={3} className="noppading ps-0">
                                <ButtonOrderByList title={t('home_tags.list_date')} columnIdName="created_at" callbackOrderBy={callbackOrderBy} />
                            </Col>
                            <Col md={3} className="noppading ps-0">
                                <ButtonOrderByList title={t('home_tags.list_status')} columnIdName="active" callbackOrderBy={callbackOrderBy} />
                            </Col>
                            <Col md={1} className='d-flex justify-content-end' >
                                <p>{t('home_tags.list_actions')}</p>
                            </Col>
                        </Row>
                    </ListGroup.Item>
                </ListGroup>

                {
                    datas !== undefined ?
                        <div id="listOfTags" style={{ overflowY: 'auto', height: '58vh' }}>
                            <InfiniteScroll
                                dataLength={datas.length}
                                next={updateListfunction}
                                hasMore={HasMoreInformation as boolean}
                                loader={<h4> </h4>}
                                scrollableTarget={"listOfTags"}
                                onScroll={() => { Utils.hidePopoverOnScroll(ListName.ListOfTags) }}
                            >
                                {
                                    <ListGroup variant="flush" className="TicketsRow" >
                                        {

                                            datas.map((item, index) => {
                                                let date = new Date(item.created_at);

                                                return (
                                                    <ListGroup.Item style={{ color: '#707070' }} id={item.name + " " + "*ID*" + item.id} key={"keyAll" + index.toString()}>
                                                        <Row className='TicketsRowHeight'>
                                                            <Col md={1} className='d-flex nopadding align-self-center' style={{ width: '3%' }}>
                                                                <Form.Check
                                                                    type='checkbox'
                                                                    id={"checkboxesAll" + index}
                                                                    onClick={(e) => {checkboxesClick(e)}}
                                                                    className="form-check-input-2"
                                                                />
                                                            </Col>
                                                            <Col md={2} className='align-self-center nopadding' style={{ cursor: 'pointer' }} id={item.name + '*ID*' + item.id + '*ID*' + item.active} onClick={(e) => viewTagPage(e)}>
                                                                <OverlayTrigger
                                                                    key='top'
                                                                    placement='top'
                                                                    overlay={
                                                                        <Tooltip id='tooltip'>
                                                                            #{item.reference_id}
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <span>
                                                                        #{item.reference_id}
                                                                    </span>
                                                                </OverlayTrigger>
                                                            </Col>
                                                            <Col md={2} className='align-self-center nopadding' style={{ cursor: 'pointer' }} id={item.name + '*ID*' + item.id + '*ID*' + item.active} onClick={(e) => viewTagPage(e)}>
                                                                <div className='oneLineClampEllipsis'>
                                                                    <OverlayTrigger
                                                                        key='top'
                                                                        placement='top'
                                                                        overlay={
                                                                            <Tooltip id='tooltip'>
                                                                                {item.name}
                                                                            </Tooltip>
                                                                        }
                                                                    >
                                                                        <span>
                                                                            {item.name}
                                                                        </span>
                                                                    </OverlayTrigger>
                                                                </div>
                                                            </Col>
                                                            <Col md={3} className='align-self-center nopadding' style={{ cursor: 'pointer' }} id={item.name + '*ID*' + item.id + '*ID*' + item.active} onClick={(e) => viewTagPage(e)}>
                                                                <OverlayTrigger
                                                                    key='top'
                                                                    placement='top'
                                                                    overlay={
                                                                        <Tooltip id='tooltip'>
                                                                            {formatedDate(date)}
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <span>
                                                                        {formatedDate(date)}
                                                                    </span>
                                                                </OverlayTrigger>
                                                            </Col>
                                                            <Col md={3} className='align-self-center nopadding' style={{ cursor: 'pointer' }} id={item.name + '*ID*' + item.id + '*ID*' + item.active} onClick={(e) => viewTagPage(e)}>
                                                                <OverlayTrigger
                                                                    key='top'
                                                                    placement='top'
                                                                    overlay={
                                                                        <Tooltip id='tooltip'>
                                                                            {item.active ?
                                                                                <>{t('home_tags.list_active')}</> : <>{t('home_tags.list_inactive')}</>
                                                                            }
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <div style={{ width: '33%' }}>
                                                                        {item.active ?
                                                                            <div className="d-flex justify-content-center py-1"
                                                                                style={{
                                                                                    width: '100%',
                                                                                    background: '#EEF3FF 0% 0% no-repeat padding-box',
                                                                                    borderRadius: '3px',
                                                                                    font: 'normal normal bold calc(0.2em + 0.7vw) Roboto',
                                                                                    letterSpacing: '0px',
                                                                                    color: '#0C2461',
                                                                                }}>
                                                                                {t('home_tags.list_active')}
                                                                            </div> :
                                                                            <div className="d-flex justify-content-center py-1"
                                                                                style={{
                                                                                    width: '100%',
                                                                                    background: '#FFD4B0 0% 0% no-repeat padding-box',
                                                                                    borderRadius: '3px',
                                                                                    font: 'normal normal bold calc(0.2em + 0.7vw) Roboto',
                                                                                    letterSpacing: '0px',
                                                                                    color: '#FF8119',
                                                                                }}>
                                                                                {t('home_tags.list_inactive')}
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </OverlayTrigger>
                                                            </Col>
                                                            <Col md={1} className='d-flex justify-content-end' >
                                                                <OverlayTrigger rootClose={true} trigger={['click']} placement="left" overlay={popover(item.active)}>
                                                                    <Button id={"button*ID*" + item.id + "*ID*" + item.active.toString()} name={item.name} onClick={(e) => setCurrentTagInfo(e)} className='nopadding d-flex justify-content-start align-self-center' style={{ marginTop: '', backgroundColor: 'transparent', border: 'none', width: "24px" }}>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="90%" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#6A6A6A" fill="#6A6A6A" strokeLinecap="round" strokeLinejoin="round">
                                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                            <circle cx="12" cy="12" r="1" />
                                                                            <circle cx="12" cy="19" r="1" />
                                                                            <circle cx="12" cy="5" r="1" />
                                                                        </svg>
                                                                    </Button>
                                                                </OverlayTrigger>
                                                            </Col>
                                                        </Row>
                                                    </ListGroup.Item>);
                                            })}
                                    </ListGroup>

                                }
                            </InfiniteScroll>
                        </div>
                        :
                        <div className='align-self-center' style={{ overflow: 'hidden', height: '42vh', backgroundColor: '#F9F9F9' }}>
                            <Row className='d-flex justify-content-center align-content-center' style={{ marginTop: '17vh', backgroundColor: '#F9F9F9' }}>
                                <Col md={1}>
                                    <svg id="Grupo_11197" data-name="Grupo 11197" xmlns="http://www.w3.org/2000/svg" width="4vw" viewBox="0 0 24 24">
                                        <path id="Caminho_9561" data-name="Caminho 9561" d="M0,0H24V24H0Z" fill="none" />
                                        <line id="Linha_144" data-name="Linha 144" y2="2" transform="translate(15 5)" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                        <line id="Linha_145" data-name="Linha 145" y2="2" transform="translate(15 11)" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                        <line id="Linha_146" data-name="Linha 146" y2="2" transform="translate(15 17)" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                        <path id="Caminho_9562" data-name="Caminho 9562" d="M5,5H19a2,2,0,0,1,2,2v3a2,2,0,0,0,0,4v3a2,2,0,0,1-2,2H5a2,2,0,0,1-2-2V14a2,2,0,0,0,0-4V7A2,2,0,0,1,5,5" fill="none" stroke="#4a69bd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
                                    </svg>
                                </Col>
                            </Row>
                            <Row className='d-flex justify-content-center align-content-center mt-2' style={{ marginLeft: '8%', backgroundColor: '#F9F9F9' }}>
                                <Col md={4}>
                                    <p style={{ color: '#0C2461', font: 'normal normal bold calc(0.2em + 0.7vw) Montserrat' }}>{t('home_tags.list_no_results')} <Button className="buttonAsText3 nopadding mb-1" onClick={showCreateTag}>{t('home_tags.list_no_results_add')}</Button></p>
                                </Col>
                            </Row>
                        </div>
                }
                {

                }
                <Row style={{ marginTop: '3vh' }}>
                    <Col md={3}>
                        <Button style={{ display: 'none', height: '5.5vh', maxWidth: '20vw' }} id="buttonDeleteAllSelected" className='buttonBlue' onClick={() => activationAllSelected()}>{t("home_tags.multi_selection_active_tags")}</Button>
                    </Col>
                    <Col md={3}>
                        <Button style={{ display: 'none', height: '5.5vh', maxWidth: '20vw' }} id="deactivationButton" className='buttonBlue' onClick={() => deactivationAllSelected()}>{t("home_tags.multi_selection_disable_tags")}</Button>
                    </Col>

                    <Col md={3}>
                        <Button style={{ display: 'none', height: '5.5vh', maxWidth: '20vw' }} id="deleteTags" className='buttonBlue' onClick={() => deleteAllTag()}>{t("home_tags.multi_selection_delete_tags")}</Button>
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default ListTags;