type CSSProperties = {
    [key: string]: React.CSSProperties;
  };
  
  export class StyleSheet {
    static create<Styles extends CSSProperties>(styles: Styles): Styles {
      return styles;
    }
  }
  
  const styles = StyleSheet.create({
    rowAlert: {
      width: '100% ',
      padding: '15px',
      minHeight: '6vh',
      background: '#ffffffeb',
      borderRadius: '3px',
      boxShadow: '0px 3px 6px #00000029',
      opacity: '1',
    },
  
    customContainer: {
      position: 'absolute',
      right: '20px',
      top: '20px',
      width: 'fit-content',
      paddingRight: 0,
      paddingLeft: 0,
      maxWidth: '400px',
      zIndex: 5000,
    },
  
    primary: {
      textAlign: 'left',
      font: 'normal normal bold calc(0.4em + 0.40vw) Montserrat',
      letterSpacing: '0px',
      color: '#1E1E1E',
      opacity: '1',
      flexDirection: 'column'
    },
  
    secondary: {
      fontWeight: 'normal',
      color: '#000',
      fontSize: 'calc(0.4em + 0.40vw)',
      marginTop: '5px',
    },
  });
  
  export default styles;
  