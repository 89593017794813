import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from "react-router-dom"
import { TokenInterfaceControlPanel } from '../../../../services/requestsInterfacesModel'
import { getToken } from '../../../../store/token'
import FormConfigContract from '.'
import { ContractStatus } from '../../../../core/enums/contract-status'


const FormConfigContractController = (props) => {

    const [isLoading, setIsLoading] = useState(false)
    const [optionsStatus, setOptionsStatus] = useState([
        { label: 'Ativo', value: '345ca1be-ac4c-45f5-b6f3-3c7924fc2d6b' },
        { label: 'Inativo', value: 'e62b6586-6ff9-4389-9028-2c1b82036862' },
    ]);
    const [selectedStatus, setSelectedStatus] = useState([]);
    const [clickEmail, setClickEmail] = useState(false)
    const [clickFacebook, setClickFacebook] = useState(false)
    const [clickInstagram, setClickInstagram] = useState(false)
    const [clickWhatsApp, setClickWhatsApp] = useState(false)
    const [clickTelefonia, setClickTelefonia] = useState(false)
    const [clickWebchat, setClickWebchat] = useState(false)
    const [qtdAgentes, setQtdAgentes] = useState("");
    const [customFildsConsumer, setCustomFildsConsumer] = useState("")
    const [customFildsTicket, setCustomFildsTicket] = useState("")
    const [statusCompany, setStatusCompany] = useState("")

    const [showfeedbackAgents, setShowFeedbackAgents] = useState(false)
    const [showfeedbackConsumers, setShowFeedbackConsumers] = useState(false)
    const [showfeedbackTicket, setShowFeedbackTicket] = useState(false)
    const [showfeedbackLimit, setShowFeedbackLimit] = useState(false)
    const [showSumary, setShowSumary] = useState(false);
    const [limitMessage, setLimitMessage] = useState(null)

    const [channels, setChannels] = useState([])

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const values: TokenInterfaceControlPanel = {
        token: {
            value: useSelector(getToken)
        }
    }

    useEffect(() => {
        updateChannelsArray()
    }, [clickWebchat, clickTelefonia, clickWhatsApp, clickInstagram, clickFacebook, clickEmail])

    useEffect(() => {
        if (props?.company_data) {
            setShowSumary(props?.company_data?.enable_summary)
            setQtdAgentes(props?.data?.quantity_agents)
            setCustomFildsConsumer(props?.data?.consumer_custom_fields_limit)
            setCustomFildsTicket(props?.data?.ticket_custom_fields_limit)
            setStatusCompany(props?.data?.contract_status_id)
            setLimitMessage(props?.data?.summary_volume)
            
            if (props?.data?.contract_status_id === '345ca1be-ac4c-45f5-b6f3-3c7924fc2d6b') {
                setSelectedStatus([{ label: 'Ativo', value: '345ca1be-ac4c-45f5-b6f3-3c7924fc2d6b' }])
            }
            else if (props?.data?.contract_status_id === 'e62b6586-6ff9-4389-9028-2c1b82036862') {
                setSelectedStatus([{ label: 'Inativo', value: 'e62b6586-6ff9-4389-9028-2c1b82036862' }])
            }
            else if (props?.data?.contract_status_id === 'a74e91d1-b802-47d2-80b1-65a06f7968e8') {
                setSelectedStatus([{ label: 'Período grátis', value: 'a74e91d1-b802-47d2-80b1-65a06f7968e8' }])
            }
            else if (props?.data?.contract_status_id === 'c9f8db0d-9ca6-4dc0-9fbe-881f9faa96dc'){
                setSelectedStatus([{ label: 'Fim da gratuidade', value: 'c9f8db0d-9ca6-4dc0-9fbe-881f9faa96dc' }])
            }
            else {
                setSelectedStatus([])
            }

            props?.data?.channels.map((item, index) => {
                if (item?.name === "WhatsApp") {
                    setClickWhatsApp(true)
                }
                else if (item?.name === "Facebook") {
                    setClickFacebook(true)
                }
                else if (item?.name === "Email") {
                    setClickEmail(true)
                }
                else if (item?.name === "Instagram") {
                    setClickInstagram(true)
                }
                else if (item?.name === "Telefonia") {
                    setClickTelefonia(true)
                }
                else if (item?.name === "Webchat") {
                    setClickWebchat(true)
                }
            })

            updateChannelsArray();
        }

    }, [props?.data])

    const updateChannelsArray = () => {
        const channelsArray = [];

        if (clickEmail) {
            channelsArray.push('3f3fa530-fd3e-4018-ab98-4d9c3cd5406a');
        }
        if (clickTelefonia) {
            channelsArray.push('6977996d-11d1-44cc-a863-2e1d9c24e046');
        }
        if (clickWhatsApp) {
            channelsArray.push('33be81d8-88ac-4e08-bb4d-cf69fd23267f');
        }
        if (clickFacebook) {
            channelsArray.push('aa1cea93-23de-46aa-af14-d6f766acf5c8');
        }
        if (clickInstagram) {
            channelsArray.push('f28cb4a4-e42b-425a-8011-59d5f9c1d0f2');
        }
        if (clickWebchat) {
            channelsArray.push('4a7b71bb-6432-4794-ad11-f978b067871d');
        }

        setChannels(channelsArray);
    };

    return <FormConfigContract
        optionsStatus={optionsStatus}
        setOptionsStatus={setOptionsStatus}
        selectedStatus={selectedStatus}
        setSelectedStatus={setSelectedStatus}
        clickEmail={clickEmail}
        setClickEmail={setClickEmail}
        clickFacebook={clickFacebook}
        setClickFacebook={setClickFacebook}
        clickInstagram={clickInstagram}
        setClickInstagram={setClickInstagram}
        clickWhatsApp={clickWhatsApp}
        setClickWhatsApp={setClickWhatsApp}
        clickTelefonia={clickTelefonia}
        setClickTelefonia={setClickTelefonia}
        qtdAgentes={qtdAgentes}
        setQtdAgentes={setQtdAgentes}
        setCustomFildsConsumer={setCustomFildsConsumer}
        customFildsConsumer={customFildsConsumer}
        setCustomFildsTicket={setCustomFildsTicket}
        customFildsTicket={customFildsTicket}
        setStatusCompany={setStatusCompany}
        statusCompany={statusCompany}
        setClickWebchat={setClickWebchat}
        clickWebchat={clickWebchat}
        showfeedbackAgents={showfeedbackAgents}
        setShowFeedbackAgents={setShowFeedbackAgents}
        setShowFeedbackTicket={setShowFeedbackTicket}
        showfeedbackTicket={showfeedbackTicket}
        showfeedbackConsumers={showfeedbackConsumers}
        setShowFeedbackConsumers={setShowFeedbackConsumers}
        acessToken={values.token.value}
        navigate={navigate}
        dispatch={dispatch}
        setIsLoading={setIsLoading}
        id={props?.data?.id}
        company_id={props?.data?.company_id}
        channels={channels}
        setChannels={setChannels}
        getCompanyById={props?.getCompanyById}
        isEditAllowed={props.isEditAllowed}
        setStatusIcon={props.setStatusIcon}
        showfeedbackLimit={showfeedbackLimit}
        setShowFeedbackLimit={setShowFeedbackLimit}
        showSumary={showSumary}
        setShowSumary={setShowSumary}
        limitMessage={limitMessage}
        setLimitMessage={setLimitMessage}
    />
}

export default FormConfigContractController