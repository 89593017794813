import { FC, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { AppRequesterController } from "../../../../../services/appRequester/appRequesterController"
import { TokenInterface } from "../../../../../services/requestsInterfacesModel"
import { getIdCompany } from "../../../../../store/company"
import AutomaticDistribution from "."
import { useTranslation } from "react-i18next"
import BackendConstants from "../../../../../core/constants/backend-constants"
import UserService from "../../../../../services/user-service"
import { AutomaticDistributionControllerInterface } from "./indexModel"

export interface OptionSelect {
    label: string, value: boolean
}

const AppRequesterConst = new AppRequesterController()

const AutomaticDistributionController: FC<AutomaticDistributionControllerInterface> = (props) => {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [validated, /*setValidated*/] = useState(false)
    const [ableDisable, setAbleDisable] = useState(false)
    const [limitTicket, setLimitTicket] = useState(0)
    const [isLoading, setIsLoading] = useState(false)
    const [selectedDistribute, setSelectDistribute] = useState<any>([])
    const [currentPage, setCurrentPage] = useState(1);
    const [currentState, setCurrentState] = useState("");
    const [currentParams, setCurrentParams] = useState({});
    const [currentLimit, /*setCurrentLimit*/] = useState(BackendConstants.limit);
    const [sectorActive, setSectorActive] = useState(false)

    const [showFeedbackLimitTicket, setShowFeedbackLimitTicket] = useState(false)

    const { t } = useTranslation()

    const values: TokenInterface = {
        company: {
            id: useSelector(getIdCompany)
        }
    }

    useEffect(() => {
        setAbleDisable(props.ticketAutomaticDistribution)
        setSelectDistribute(props.noSectorDistributeTicket)
        setLimitTicket(props.ticketLimitPerAgent ? props.ticketLimitPerAgent : 0)
    }, [props.ticketLimitPerAgent, props.noSectorDistributeTicket, props.noSectorDistributeTicket]);

    useEffect(() => {
        const controller = new AbortController();

        setCurrentParams((params) => {
            setCurrentState((state) => {
                getAllDepartmentsToPagination(Object.assign(currentParams, { limit: currentLimit, page: currentPage }), getAllDepartments, controller)
                return state
            });
            return params
        })

        return () => {
            controller.abort()
        }
    }, [currentPage, currentState]);

    useEffect(() => {
        const controller = new AbortController();

        setCurrentParams((params) => {
            setCurrentState((state) => {
                getAllDepartmentsToPagination(Object.assign(currentParams, { limit: currentLimit, page: currentPage }), getAllDepartments, controller)
                return state
            });
            return params
        })

        return () => {
            controller.abort()
        }
    }, [currentPage, currentState])

    const getAllDepartmentsToPagination = (params: { limit: number; page: number }, getAllDepartments: Function, controller: AbortController) => {
        return getAllDepartments(params, controller)
    }

    const getAllDepartments = async (params: { limit: number; page: number }, controller: AbortController) => {
        const headers = UserService.getHeaders()
        const signal = controller.signal

        await AppRequesterConst.Get(
            '/sector?limit=1000', { headers, signal, params: params },
            (response: Object) => { },
            (data) => {
                if (data.status === 200) {
                    data.data.sectors.map((sectorActive) => {
                        if (sectorActive.active) {
                            setSectorActive(true)
                        }
                    })
                }
            },
            (error: Object) => {

            }, navigate, dispatch, setIsLoading, { values: values }
        )
    }

    return <AutomaticDistribution
        t={t}
        values={values}
        props={props}
        validated={validated}
        ableDisable={ableDisable}
        setAbleDisable={setAbleDisable}
        limitTicket={limitTicket}
        setLimitTicket={setLimitTicket}
        selectedDistribute={selectedDistribute}
        setSelectDistribute={setSelectDistribute}
        showFeedbackLimitTicket={showFeedbackLimitTicket}
        setShowFeedbackLimitTicket={setShowFeedbackLimitTicket}
        navigate={navigate}
        dispatch={dispatch}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        AppRequesterConst={AppRequesterConst}
        sectorActive={sectorActive}
    />

}

export default AutomaticDistributionController