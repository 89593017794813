import { IconPhoto } from '@tabler/icons-react';
import './styles.scss';
import { FormControl } from 'react-bootstrap';
import { useState } from 'react';

const AddImage = (props: any) => {

  const [url, setUrl] = useState('');

  const handleChange = (evt) => {
    setUrl(evt.target.value);
  }

  const addImage = () => {
    const { editorState, onChange } = props;
    onChange(props.modifier(editorState, url));
    props.setShowAddImage(false);
    setUrl('');
  }

  return (
    <>
    <div className="icon-img-editor">
      {props.showAddImage && 
      <div className="input-url">
        <FormControl
          className="form-control-Default"
          placeholder={props.t('home_tickets.chat_editor.url_image')}
          aria-label={props.t('home_tickets.chat_editor.url_image')}
          aria-describedby={props.t('home_tickets.chat_editor.url_image')}
          size="lg"
          type="text"
          id="text"
          name="text"
          value={url}
          onChange={handleChange}
      ></FormControl>
        <button className="add-img" onClick={addImage}>+</button>
      </div>
      }
      <IconPhoto className='hover' size={20}  onClick={() => props.setShowAddImage(!props.showAddImage)}/>
    </div>
    </>
  )
}

export default AddImage;