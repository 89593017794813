type CSSProperties = {
    [key: string]: React.CSSProperties;
};
  
export class StyleSheet {
    static create<Styles extends CSSProperties>(styles: Styles): Styles {
        return styles;
    };
};

const styles = StyleSheet.create({
    navItemActive: {
        backgroundColor: '#4A69BD',
        width: '100%',
        borderRadius: 3,
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        padding: '0.8vh',
    },
    navItem: {
        backgroundColor: '#F5F5F5',
        width: '100%',
        borderRadius: 3,
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        padding: '0.8vh',
    },
    spanActive: {
        fontSize: '0.8rem',
        fontFamily: 'Montserrat',
        fontWeight: 'bold',
        marginLeft: '0.3vw',
        color: 'white'
    },
    span: {
        fontSize: '0.8rem',
        fontFamily: 'Montserrat',
        marginLeft: '0.3vw',
        color: 'black'
    }
});

export default styles;  