import './styles.scss';
import { Form, Row, Col, Button, InputGroup, OverlayTrigger, Tooltip } from "react-bootstrap"
import allStyles from './styles'
import { Formik } from "formik"
import UserService from "../../../../../services/user-service"
import { setShowAlertFeedback } from "../../../../../store/internal"
import { IconInfoCircle, IconSquareRounded, IconSquareRoundedCheckFilled } from '@tabler/icons-react';

const AutomaticDistribution = ({
    t,
    values,
    props,
    validated,
    ableDisable,
    setAbleDisable,
    limitTicket,
    setLimitTicket,
    selectedDistribute,
    setSelectDistribute,
    showFeedbackLimitTicket,
    setShowFeedbackLimitTicket,
    navigate,
    dispatch,
    isLoading,
    setIsLoading,
    AppRequesterConst,
    sectorActive,
}) => {

    return (
        <Row className='automaticDistributionTab'>
            <Formik
                enableReinitialize={true}
                initialValues={{ limitTicket: limitTicket  }}
                validate={values => {
                    let errors = {}
                    setLimitTicket(values.limitTicket)
                    if (!values.limitTicket && ableDisable) {
                        errors['limitTicket'] = t('configurations_ticket.automatic_distribution.validate.limit_ticket')
                        setShowFeedbackLimitTicket(true)
                    } else if (values.limitTicket >= 100000) {
                        errors['limitTicket'] = t('configurations_ticket.automatic_distribution.validate.limit_ticket_valid')
                        setShowFeedbackLimitTicket(true)
                    } else {
                        setShowFeedbackLimitTicket(false)
                    }

                    return errors;
                }}
                onSubmit={async () => {

                    let Jsonsend = {
                        id: values.company.id,
                        life_time:props.life_time,
                        ticket_agent_automatic_distribution: ableDisable,
                        ticket_limit_per_agent: limitTicket > 0 ? limitTicket : props.ticketLimitPerAgent,
                        distribute_ticket_without_sector: selectedDistribute
                    }

                    const headers = UserService.getHeaders()
                    await AppRequesterConst.Put(
                        `/company`, Jsonsend, { headers },
                        (response: Object) => {
                            return response
                        },
                        (data) => {
                            dispatch(setShowAlertFeedback({ message: t('configurations_ticket.automatic_distribution.submit.message'), visibility: true, signalIcon: true }))
                        },
                        (error: { response: {} }) => {
                            dispatch(setShowAlertFeedback({ message: t('configurations_ticket.automatic_distribution.submit.error'), visibility: true, signalIcon: false }));
                            // if (error.response.status == 422) {
                            //     dispatch(setShowAlertFeedback({ message: "O número máximo de tickets é 100.000", visiliby: true, signalIcon: false }));
                            // } else {
                            // }
                        }, navigate, dispatch, setIsLoading,
                        
                    )


                }}
            >
                {({
                    values,
                    errors,
                    setValues,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                }) => (
                    <Form onSubmit={handleSubmit} noValidate validated={validated}>
                        <div style={{ overflow: 'auto', overflowX: 'hidden', height: '60vh', flexDirection: 'column', display: "flex" }}>
                            <Row style={{ height: '36%', display: "flex", alignItems: "center" }} className="d-flex justify-content-start my-3">
                                <Col style={{ backgroundColor: 'white', borderRadius: '10px' }}>
                                    <Row>
                                        <p style={{ font: 'normal normal bold calc(0.2em + 0.8vw) Montserrat', color: '#0C2461', marginTop: '4vh' }}>{t('configurations_ticket.automatic_distribution.automatic_distribution')}</p>
                                    </Row>
                                    <Row>
                                        <Form.Label htmlFor="html" >{t('configurations_ticket.automatic_distribution.distribute_new_tickets_label')}</Form.Label>
                                        <Col>
                                            <div className="radio-list">
                                                <label className='radio-item'>
                                                    { ableDisable ? <IconSquareRounded className='radio-icon' /> : <IconSquareRoundedCheckFilled className='radio-icon' /> }
                                                    <input type="radio" value="automatic" checked={!ableDisable} onChange={e => setAbleDisable(!ableDisable)} />
                                                    {t('configurations_ticket.automatic_distribution.distribute_manual_label')}
                                                </label>
                                                <label className='radio-item'>
                                                    { !ableDisable ? <IconSquareRounded className='radio-icon' /> : <IconSquareRoundedCheckFilled className='radio-icon' /> }
                                                    <input type="radio" value="automatic" checked={ableDisable} onChange={e => setAbleDisable(!ableDisable)} />
                                                    {t('configurations_ticket.automatic_distribution.distribute_automatic_label')}
                                                </label>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            {
                                (ableDisable && sectorActive == true) ?
                                    <>
                                        <Row>
                                            <Col md={4} style={{ borderRadius: '10px', alignItems: "end", /*paddingTop: "8.5vh"*/ }}>
                                                <Form.Label htmlFor="html" style={{ display: "flex", alignItems: "center", fontWeight: '500' }}>
                                                    {t('configurations_ticket.automatic_distribution.limit_ticket')}
                                                </Form.Label>
                                                <InputGroup className="mb-3" style={{ display: "flex" }}>
                                                    <Form.Control 
                                                        value={limitTicket}
                                                        className="form-control-Default"
                                                        placeholder="0"
                                                        aria-label="0"
                                                        aria-describedby="0"
                                                        type="number"
                                                        size="sm"
                                                        id="limitTicket"
                                                        name="limitTicket"
                                                        disabled={!ableDisable}
                                                        isInvalid={showFeedbackLimitTicket}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    >
                                                    </Form.Control>
                                                    <Form.Control.Feedback
                                                        type="invalid"
                                                        id="feedbackInvalid"
                                                    >
                                                        {errors.limitTicket}
                                                    </Form.Control.Feedback>
                                                </InputGroup>  
                                                
                                            </Col>
                                        </Row>
                                        <Row className='mt-4'>
                                            <Form.Label htmlFor="html" style={{ fontWeight: '500'}}>
                                                {t('configurations_ticket.automatic_distribution.distribute_type')}
                                            </Form.Label>
                                            <Col>
                                                <div className="radio-list">
                                                <label className='radio-item'>
                                                    { !selectedDistribute ? <IconSquareRounded className='radio-icon' /> : <IconSquareRoundedCheckFilled className='radio-icon' /> }
                                                    <input type="radio" value="automatic" checked={selectedDistribute} onChange={e => setSelectDistribute(!selectedDistribute)} />
                                                    {t('configurations_ticket.automatic_distribution.distribute_no_sector')}
                                                    <OverlayTrigger
                                                        key='top'
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip id="tooltip_second" className="tooltip-arrow-Top">
                                                                {t('configurations_ticket.automatic_distribution.distribute_no_sector_tooltip')}
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <IconInfoCircle size={24} style={{ marginLeft: '5px'}} />
                                                    </OverlayTrigger>
                                                </label>
                                                <label className='radio-item'>
                                                    { selectedDistribute ? <IconSquareRounded className='radio-icon' /> : <IconSquareRoundedCheckFilled className='radio-icon' /> }
                                                    <input type="radio" value="automatic" checked={!selectedDistribute} onChange={e => setSelectDistribute(!selectedDistribute)} />
                                                    {t('configurations_ticket.automatic_distribution.distribute_by_sector')}
                                                </label>
                                            </div>
                                            </Col>
                                        </Row>
                                        
                                    </>
                                :
                                    <></>
                            }
                        </div>
                        <Row style={allStyles.textFontGlay} className="d-flex mt-4">
                            <Col md={6} className="d-flex justify-content-start" style={{ width: '40vh' }}>
                                <Button className="buttonWhite" style={{ width: '60%' }} onClick={() => document.location.reload()}>{t('configurations_ticket.automatic_distribution.cancel')}</Button>
                            </Col>
                            <Col md={6} className="d-flex justify-content-end" style={{ width: '40vh' }}>
                                <Button type="submit" className="buttonBlue" style={{ width: '60%' }}>{t('configurations_ticket.automatic_distribution.save')}</Button>
                            </Col>
                        </Row>
                    </Form>
                )}
            </Formik>
        </Row>
    )
}

export default AutomaticDistribution